import { filter, Observable } from "rxjs";
import { cameraKitUserAgent } from "../common/cameraKitUserAgent";
import { stringifyError } from "../common/errorHelpers";
import { CameraKitConfiguration, configurationToken } from "../configuration";
import { Injectable } from "../dependency-injection/Injectable";
import { logEntriesFactory } from "./logEntries";
import { LogEntry, logLevelMap } from "./logger";

/**
 * The factory subscribes to log entry events and, based on the configured log level,
 * forwards matching log entries to the logger specified in the CameraKit configuration object.
 *
 * @internal
 */
export const registerLogEntriesSubscriber = Injectable(
    "registerLogEntriesSubscriber",
    [configurationToken, logEntriesFactory.token] as const,
    (configuration: CameraKitConfiguration, logEntries: Observable<LogEntry>) => {
        logEntries
            .pipe(filter((entry) => logLevelMap[entry.level] >= logLevelMap[configuration.logLevel]))
            .subscribe((logEntry) => {
                switch (configuration.logger) {
                    case "console":
                        // Chrome doesn't print the `cause` Error property, so we need to manually construct a complete
                        // stack trace using our `stringifyError` helper.
                        const messages =
                            cameraKitUserAgent.browser.brand === "Chrome"
                                ? logEntry.messages.map((message) => {
                                      if (!(message instanceof Error)) return message;
                                      message.stack = stringifyError(message);
                                      return message;
                                  })
                                : logEntry.messages;

                        console[logEntry.level](`[CameraKit.${logEntry.module}]`, ...messages);
                        break;
                }
            });
    }
);
