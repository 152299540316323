import { BrowserHeaders } from "browser-headers";
import { cameraKitUserAgent } from "../common/cameraKitUserAgent";
import { ensureError } from "../common/errorHelpers";
import { CircumstancesServiceClientImpl, GrpcWebImpl } from "../generated-proto/pb_schema/cdp/cof/circumstance_service";
import { ConfigTargetingRequest } from "../generated-proto/pb_schema/cdp/cof/config_request";
import { ConfigTargetingResponse } from "../generated-proto/pb_schema/cdp/cof/config_response";
import { ChainableHandler, HandlerChainBuilder, RequestMetadata } from "../handlers/HandlerChainBuilder";
import {
    RequestStateEventTarget,
    dispatchRequestCompleted,
    dispatchRequestErrored,
    dispatchRequestStarted,
    requestStateEventTargetFactory,
} from "../handlers/requestStateEmittingHandler";
import { createResponseCachingHandler, staleWhileRevalidateStrategy } from "../handlers/responseCachingHandler";
import { createRetryingHandler } from "../handlers/retryingHandler";
import { createTimeoutHandler } from "../handlers/timeoutHandler";
import { ExpiringPersistence } from "../persistence/ExpiringPersistence";
import { IndexedDBPersistence } from "../persistence/IndexedDBPersistence";
import {
    OperationalMetricsReporter,
    operationalMetricReporterFactory,
} from "../metrics/operational/operationalMetricsReporter";
import { CameraKitConfiguration, configurationToken } from "../configuration";
import { Injectable } from "../dependency-injection/Injectable";

export interface Metadata {
    [key: string]: string;
}

const id = <Req, Res, Meta extends RequestMetadata>(h: ChainableHandler<Req, Res, Req, Res, Meta>) => h;

export const COF_REQUEST_TYPE = "cof";

export type CofDimensions = { requestType: typeof COF_REQUEST_TYPE };

/**
 * Handler chain used to make COF requests. Uses the COF client to perform the
 * requests, with retries, timeout, and caching.
 *
 * The handler will first attempt to retrieve the COF response from cache. If it is found, the result is returned
 * immediately and the cache is updated in the background. If no response is found, a COF request is made. This request
 * will retry (with exponential backoff + jitter) for 5 seconds before returning an error to the caller.
 */
export const cofHandlerFactory = Injectable(
    "cofHandler",
    [configurationToken, requestStateEventTargetFactory.token, operationalMetricReporterFactory.token] as const,
    (
        config: CameraKitConfiguration,
        requestStateEventTarget: RequestStateEventTarget,
        reporter: OperationalMetricsReporter
    ) => {
        // We need to wrap `targetingQuery` to create a usable Handler – the main issue is that HandlerChainBuilder
        // always adds a `signal` property to the metadata argument (second argument of the Handler), but
        // `targetingQuery` expects the second argument to only contain headers.
        return (
            new HandlerChainBuilder(
                async (
                    request: Partial<ConfigTargetingRequest>,
                    { signal, isSideEffect: _, ...metadata }: Metadata & RequestMetadata
                ) => {
                    const rpc = new GrpcWebImpl(`https://${config.apiHostname}`, {});
                    const client = new CircumstancesServiceClientImpl(rpc);
                    return new Promise<ConfigTargetingResponse>((resolve, reject) => {
                        if (signal) {
                            signal.addEventListener("abort", () =>
                                reject(new Error("COF request aborted by handler chain."))
                            );
                        }
                        client
                            .targetingQuery(
                                request,
                                new BrowserHeaders({
                                    authorization: `Bearer ${config.apiToken}`,
                                    "x-snap-client-user-agent": cameraKitUserAgent.userAgent,
                                    ...metadata,
                                })
                            )
                            .then((response) => {
                                // NOTE: in order for cache persistance to work, we need to make the object cloneable,
                                // i.e. with no methods (it appears targetingQuery() attaches toObject() to response
                                // object). Safety: We have to cast response object to a type that has toObject
                                // defined, because that is indeed what generated code has:
                                // eslint-disable-next-line max-len
                                // https://github.sc-corp.net/Snapchat/camera-kit-web-sdk/blob/8d6b4e8bfa3717b376ab197a49972a1e410851f7/packages/web-sdk/src/generated-proto/pb_schema/cdp/cof/circumstance_service.ts#L1459
                                delete (response as any).toObject;
                                resolve(response);
                            })
                            .catch(reject);
                    });
                }
            )
                .map(
                    id((next) => async (request, metadata) => {
                        const dimensions: CofDimensions = { requestType: COF_REQUEST_TYPE };
                        const { requestId } = dispatchRequestStarted(requestStateEventTarget, { dimensions });
                        try {
                            const response = await next(request, metadata);
                            // TODO: We hardcode status code and sizeByte values because we do not have access to
                            // underlying transport of configs-web.
                            // When this ticket is done https://jira.sc-corp.net/browse/CAMKIT-2840,
                            // we will remove this handler and benefit from existing ones.
                            const status = 200;
                            let sizeByte = 0;
                            try {
                                sizeByte = new TextEncoder().encode(JSON.stringify(response)).byteLength;
                            } finally {
                                dispatchRequestCompleted(requestStateEventTarget, {
                                    requestId,
                                    dimensions,
                                    status,
                                    sizeByte,
                                });
                                return response;
                            }
                        } catch (error) {
                            dispatchRequestErrored(requestStateEventTarget, {
                                requestId,
                                dimensions,
                                error: ensureError(error),
                            });
                            throw error;
                        }
                    })
                )
                // targetingQuery() always converts failed responses into errors (unlike fetch()), so we need a custom
                // retryPredicate that retries all errors. We'll keep retrying (with backoff) for 20 seconds total
                // elapsed time before we return an error back up the chain.
                .map(createRetryingHandler({ retryPredicate: (r) => r instanceof Error }))
                // API gateway has 15 seconds timeout, so we rely on that first
                .map(createTimeoutHandler({ timeout: 20 * 1000 }))
                .map(
                    createResponseCachingHandler(
                        // COF responses will be removed from cache after 1 week. Keep in mind that the
                        // staleWhileRevalidate strategy will update the cache each time COF is requested
                        //  – this expiration comes into play only if e.g. a user doesn't load the page
                        // for more than a week.
                        new ExpiringPersistence<ConfigTargetingResponse>(
                            () => 7 * 24 * 60 * 60,
                            new IndexedDBPersistence({ databaseName: "COFCache" })
                        ),
                        (r: Partial<ConfigTargetingRequest>) => JSON.stringify(r),

                        // If we have a matching response already in cache,
                        // we'll return it immediately and then update the cache in the background.
                        staleWhileRevalidateStrategy({ requestType: "cof", reporter })
                    )
                ).handler
        );
    }
);
