import { LensCoreModule } from "../lens-core-module/generated-types";
import { CameraKitSource, CameraKitSourceOptions, CameraKitSourceSubscriber } from "./CameraKitSource";

const getYUVImageData = async (canvas: HTMLCanvasElement, lensCore: LensCoreModule): Promise<ImageData> => {
    const { width, height } = canvas;
    // A YUV buffer has lower-res UV channels, so the total number of bytes works out like so:
    const outputBuffer = new ArrayBuffer((width * height * 3) / 2);
    await new Promise((onSuccess, onFailure) => {
        lensCore.imageToYuvBuffer({ image: canvas, width, height, outputBuffer, onSuccess, onFailure });
    });
    const pixels = new Uint8ClampedArray(outputBuffer);
    return new ImageData(pixels, width, height);
};

const getRGBImageData = (output: HTMLCanvasElement, imageReader2D: CanvasRenderingContext2D | null): ImageData => {
    if (imageReader2D === null) return new ImageData(0, 0);
    imageReader2D.drawImage(output, 0, 0);
    return imageReader2D.getImageData(0, 0, output.width, output.height);
};

const getImageBitmap = async (imageData: ImageData, format: FrameFormat): Promise<ImageBitmap> => {
    switch (format) {
        case "nv12":
        case "yuv":
            if (!window.VideoFrame)
                return Promise.reject(
                    new Error(`Cannot process frame. ImageData in ${format} is not supported by this browser.`)
                );
            const frame = new VideoFrame(imageData.data.buffer, {
                format: "NV12",
                codedWidth: imageData.width,
                codedHeight: imageData.height,
                timestamp: 0,
            });
            return createImageBitmap(frame);
        case "rgb":
            return createImageBitmap(imageData);
    }
};

/** @internal */
export type FrameFormat = "rgb" | "yuv" | "nv12";

/** @internal */
export interface FrameInput {
    format?: FrameFormat;
    imageData: ImageData;
    timestampMillis: number;
}

/** @internal */
export interface FrameOutput {
    live: ImageData;
    capture: ImageData;
}

/** @internal */
export interface MediaSourceFunction {
    (render: (frame: FrameInput) => Promise<FrameOutput>): Promise<void>;
}

/**
 * Creates a {@link CameraKitSource} from a function which provides per-frame pixel data to CameraKit.
 *
 * @param sourceFunction This function will be called in a requestAnimationFrame loop. Each time it is called, it is
 * passed a `render` function. It may call `render` and CameraKit will process the pixel data passed to `render` and
 * return a Promise of the processed pixels (along with rendering them to the normal output canvases).
 * @param options
 * @param options.cameraType By default we set this to 'user', which is the camera type most Lenses expect.
 *
 * @internal
 */
export const createFunctionSource = (
    sourceFunction: MediaSourceFunction,
    options: Omit<CameraKitSourceOptions, "fpsLimit"> = {}
): CameraKitSource => {
    let width = 0;
    let height = 0;
    let shouldProcessFrame = true;

    // We require an auxiliary canvas that we can use to read back pixel data (unless we're in YUV mode, in which case
    // we use LensCore to convert between formats and we don't need this canvas).
    const imageReaderCanvas = document.createElement("canvas");
    const imageReader2D = imageReaderCanvas.getContext("2d");

    const subscriber: CameraKitSourceSubscriber = {
        onAttach: (source, lensCore, reportError) => {
            const outputs = lensCore.getOutputCanvases();
            const output = {
                live: outputs[lensCore.CanvasType.Preview.value],
                capture: outputs[lensCore.CanvasType.Capture.value],
            };

            const processFrame = (
                source: CameraKitSource,
                lensCore: LensCoreModule,
                reportError: (error: Error) => void
            ) =>
                requestAnimationFrame(async () => {
                    if (!shouldProcessFrame) return;
                    try {
                        await sourceFunction(({ format, imageData, timestampMillis }) => {
                            const frameOutput = new Promise<FrameOutput>(async (resolve, reject) => {
                                const inputFrame = await getImageBitmap(imageData, format ?? "rgb");
                                if (inputFrame.width !== width || inputFrame.height !== height) {
                                    width = imageReaderCanvas.width = inputFrame.width;
                                    height = imageReaderCanvas.height = inputFrame.height;
                                    // We don't await this promise, because we want to continue to process frames and
                                    // let LensCore manage the concurrency between setting resolution and processing a
                                    // frame.
                                    source.setRenderSize(width, height);
                                }
                                lensCore.processFrame({
                                    inputFrame,
                                    timestampMillis,
                                    onSuccess: async () => {
                                        // Closing releases graphics resources associated with the frame, now that is
                                        // has been processed.
                                        inputFrame.close();

                                        switch (format ?? "rgb") {
                                            case "nv12":
                                            case "yuv":
                                                const [live, capture] = await Promise.all([
                                                    getYUVImageData(output.live, lensCore),
                                                    getYUVImageData(output.capture, lensCore),
                                                ]).catch((error) => {
                                                    reject(error);
                                                    return [undefined, undefined] as const;
                                                });
                                                // if either of these is undefined, we'll have already rejected
                                                // the promise, so we can return.
                                                if (!live || !capture) return;
                                                return resolve({ live, capture });
                                            case "rgb":
                                                return resolve({
                                                    live: getRGBImageData(output.live, imageReader2D),
                                                    capture: getRGBImageData(output.capture, imageReader2D),
                                                });
                                        }
                                    },
                                    onFailure: (error) => {
                                        inputFrame.close();
                                        reject(error);
                                    },
                                });
                            });
                            // Even if there's an error processing the frame, we do want to attempt to process the next
                            // frame. We expect `sourceFunction` to handle a rejected `frameOutput` Promise.
                            frameOutput.finally(() => processFrame(source, lensCore, reportError));
                            return frameOutput;
                        });
                    } catch (error) {
                        reportError(
                            new Error(
                                "Failure to process frame, which was not handled by the provided " +
                                    `MediaSourceFunction ${sourceFunction.name ?? "anonymous"}.`,
                                { cause: error }
                            )
                        );
                    }
                });
            processFrame(source, lensCore, reportError);
        },
        onDetach: () => {
            shouldProcessFrame = false;
        },
    };

    return new CameraKitSource({ useManualFrameProcessing: true }, subscriber, options);
};
