import Long from "long";
import _m0 from "protobufjs/minimal";
import { Namespace } from "./namespace";
import { Ruid_Type } from "../../common/ruid";
import { Value } from "../../common/value";
export const protobufPackage = "snapchat.cdp.cof";
export interface ConfigResult {
    configId: string;
    value: Value | undefined;
    targetingExpression: ClientTargetingExpression | undefined;
    ttlSeconds: number;
    configRuleUuidBytes: Uint8Array;
    priority: number;
    namespace: Namespace;
    studyName: string;
    experimentId: number;
    delete: boolean;
    servePlatforms: ConfigResult_ServePlatform[];
    sequenceId: number;
    ruidType: Ruid_Type;
    segmentOrdinal: number;
    generatedFromAbAllowlists: boolean;
    internalFields: ConfigResult_InternalFields | undefined;
}
export enum ConfigResult_ServePlatform {
    UNKNOWN_CONTEXT_PLATFORM = 0,
    IOS_PLATFORM = 1,
    ANDROID_PLATFORM = 2,
    SERVER_PLATFORM = 3,
    UNRECOGNIZED = -1
}
export interface ConfigResult_InternalFields {
    configBitmapIndex: number;
    configResultBitmapIndex: number;
    hasServerPropertiesOnly: boolean;
    globalPriority: number;
    sequenceIds: ConfigResult_InternalFields_SequenceIdCandidate[];
    studySegmentOrdinal: number;
    experimentGuid: number;
    isAbStudyStatusCompleted: boolean;
}
export interface ConfigResult_InternalFields_SequenceIdCandidate {
    targetingExpression: ClientTargetingExpression | undefined;
    sequenceId: number;
}
export interface ConfigResultBundle {
    etag: string;
    configResults: ConfigResult[];
}
export interface SequenceIdCandidate {
    targetingExpression: ClientTargetingExpression | undefined;
    sequenceId: number;
}
export interface ClientTargetingExpression {
    operator: ClientTargetingExpression_Operator;
    children: ClientTargetingExpression[];
    property: ClientTargetingExpression_Property;
    predicateOperator: ClientTargetingExpression_PredicateOperator;
    value: Value | undefined;
    propertyMetadata: ClientTargetingExpression_PropertyMetadata | undefined;
}
export enum ClientTargetingExpression_Operator {
    UNKNOWN_OPERATOR = 0,
    AND = 1,
    OR = 2,
    UNRECOGNIZED = -1
}
export enum ClientTargetingExpression_PredicateOperator {
    UNKNOWN_PREDICATE_OPERATOR = 0,
    EQUAL = 1,
    NOT_EQUAL = 2,
    GREATER_THAN_OR_EQUAL_TO = 3,
    LESS_THAN_OR_EQUAL_TO = 4,
    UNRECOGNIZED = -1
}
export enum ClientTargetingExpression_Property {
    UNKNOWN_PROPERTY = 0,
    BATTERY_LEVEL = 1,
    IS_OFFLINE = 2,
    IS_CHARGING = 3,
    BANDWIDTH = 4,
    DISK_SIZE = 5,
    DISK_AVAILABLE = 6,
    MEDIA_TYPE_DEPRECATED = 7,
    IS_PUBLIC_STORY = 8,
    IS_OFFICIAL_STORY = 9,
    CAMERA_DIRECTION = 10,
    CAMERA_CONTEXT = 11,
    CAMERA_API = 12,
    CAMERA_FLASH_STATE = 13,
    SNAP_SOURCE = 14,
    LEGACY_MUSHROOM_CONTENT_TYPE = 15,
    UPLOAD_BANDWIDTH = 16,
    CAMERA2_LEVEL = 17,
    CAMERA_NIGHT_MODE_STATE = 18,
    USER_LANGUAGE = 19,
    VIDEO_DURATION = 20,
    REALTIME_MOBILE_NETWORK_GENERATION = 21,
    REALTIME_NETWORK_TYPE = 22,
    REALTIME_NETWORK_IS_METERED = 23,
    REALTIME_NETWORK_IS_ROAMING = 24,
    CAMERA_IS_FIRST_CAPTURE = 25,
    PICTURE_MODE = 26,
    BOLT_USE_CASE = 27,
    BOLT_CDN_EXPERIMENTATION_ID = 28,
    MINS_SINCE_LAST_LENS_ACTIVATION = 29,
    MINS_SINCE_LAST_SNAPPABLE_LENS_ACTIVATION = 30,
    DAYS_SINCE_LAST_LOGIN_OR_OPEN = 31,
    SPECTACLES_VERSION = 32,
    STICKY_MAX_CONNECTION_TYPE = 33,
    WITH_ANIMATED_OVERLAY = 34,
    ESTIMATED_DURATION_FOR_EVENT_MS = 35,
    URL = 36,
    MEDIA_SOURCE = 37,
    ASSET_TYPE = 38,
    BOLT_IS_CONTENT_POPULAR = 39,
    CAPTURE_MODE = 40,
    VP9_SOFTWARE_DECODING_SUPPORTED = 41,
    AV1_SOFTWARE_DECODING_SUPPORTED = 42,
    WITH_MUSIC = 43,
    FEATURE_PROVIDED_SIZE = 44,
    MEDIA_TYPE = 45,
    SCANNED_CATEGORY_IDS = 46,
    CONTENT_VIEW_SOURCE = 47,
    RECIPIENTS_SUPPORT_HEVC = 48,
    COGNAC_APP_ID = 49,
    EMAIL_VERIFIED = 50,
    PHONE_VERIFIED = 51,
    NOTIFICATION_PERM_GRANTED = 52,
    CONTACT_PERM_GRANTED = 53,
    MUTUAL_FRIENDS_COUNT = 54,
    GROUPS_COUNT = 55,
    HAS_NON_TEAM_SNAPCHAT_CONVERSATIONS = 56,
    PLAYBACK_ITEM_TYPE = 57,
    SUP_BOOLEAN_PROPERTY = 58,
    SUP_INTEGER_PROPERTY = 59,
    SUP_LONG_PROPERTY = 60,
    SUP_STRING_PROPERTY = 61,
    SUP_DOUBLE_PROPERTY = 62,
    SUP_MILLIS_PROPERTY_TO_NOW = 63,
    HAS_USED_SPECTACLES = 64,
    SUP_SECS_PROPERTY_TO_NOW = 65,
    BOLT_CLIENT_APP_STATE = 66,
    EMAIL_SET = 67,
    BITMOJI_SET = 68,
    QUICK_TAP_CAMERA_SUPPORTED_ENABLED = 69,
    HAS_ADD_FRIENDS_REQUEST = 70,
    APP_LAUNCH_FROM_PUSH = 71,
    APP_LAUNCH_TYPE = 72,
    BILLBOARD_CAMPAIGN_LOCAL_IMPRESSION_COUNT = 73,
    BILLBOARD_CAMPAIGN_LOCAL_LAST_IMPRESSION_TIME_SECS_TO_NOW = 74,
    BILLBOARD_CAMPAIGN_LOCAL_CONTINUOUS_DISMISS_COUNT = 75,
    FST_NUM_IN_APP_WARNINGS = 76,
    FST_SHOULD_CHANGE_PASSWORD = 77,
    FST_IS_BACKGROUND_CHECK = 78,
    AUDIO_RECORD_PERM_GRANTED = 79,
    MUTUAL_FRIENDS_WITH_BIRTHDAY_COUNT = 80,
    BILLBOARD_CAMPAIGN_LOCAL_DISMISS_COUNT = 81,
    USE_VERTICAL_NAVIGATION = 82,
    FREEABLE_DISK_AVAILABLE = 83,
    MEMORIES_COUNT = 84,
    DEVICE_MODEL = 85,
    COUNTRY = 86,
    OS = 87,
    OS_VERSION = 88,
    APP_VERSION = 89,
    BUILD_FLAVOR = 90,
    USER_ID = 91,
    LOCALE = 92,
    DEVICE_CLUSTER = 93,
    DEVICE_BRAND = 94,
    IS_EMPLOYEE = 95,
    USERNAME = 96,
    IS_TEST_USER = 97,
    USER_PROFILE = 98,
    SCREEN_WIDTH = 99,
    SCREEN_HEIGHT = 100,
    HEVC_SUPPORT = 101,
    NETWORK_TYPE = 102,
    MAX_VIDEO_WIDTH = 103,
    MAX_VIDEO_HEIGHT = 104,
    IS_NETWORK_METERED = 105,
    IS_ROAMING = 106,
    APP_ENGAGEMENT_LEVEL = 107,
    COMMUNICATION_ENGAGEMENT_LEVEL = 108,
    FRIEND_STORY_ENGAGEMENT_LEVEL = 109,
    PUBLIC_USER_STORY_ENGAGEMENT_LEVEL = 110,
    PUBLISHER_STORY_ENGAGEMENT_LEVEL = 111,
    LENS_ENGAGEMENT_LEVEL = 112,
    NON_FRIEND_STORY_ENGAGEMENT_LEVEL = 113,
    FOLLOWER_SIZE_LEVEL = 114,
    DAYS_SINCE_CREATION = 115,
    USER_PERSONA = 116,
    USER_CREATION_TIME = 117,
    MOBILE_NETWORK_TYPE = 118,
    AGGREGATED_USER_BANDWIDTH = 119,
    LENS_CLUSTER_BIASED = 120,
    LENS_CLUSTER_LOG = 121,
    LENS_CLUSTER_ORIG = 122,
    LENS_CLUSTER_ORIG_4 = 123,
    LENS_CLUSTER_BIASED_LOG = 124,
    APP_LOCALE = 125,
    DEVICE_COMMON_NAME = 126,
    USER_FRIEND_COUNT = 127,
    L90_COUNTRY = 128,
    STUB = 129,
    IS_TRUE = 130,
    STORY_POST_RATIO = 131,
    GENDER = 132,
    INFERRED_AGE_BUCKET = 133,
    STORIES = 134,
    SENDS = 135,
    SNAP_CREATE_L7 = 136,
    STORY_POST_L7 = 137,
    COMMUNICATION_L7 = 138,
    DF_L7 = 139,
    APP_L7 = 140,
    DAYS_SINCE_CREATION_BUCKET = 141,
    BIDIRECTIONAL_FRIEND_STATUS = 142,
    STORY_POST_PREDICTION = 143,
    APP_OPENS = 144,
    LENS_SWIPES = 145,
    LENS_SNAPS = 146,
    LENS_OPS = 147,
    W1_APP_OPENS = 148,
    W1_LENS_SWIPES = 149,
    W1_LENS_SNAPS = 150,
    W1_LENS_OPS = 151,
    W2_APP_OPENS = 152,
    W2_LENS_SWIPES = 153,
    W2_LENS_SNAPS = 154,
    W2_LENS_OPS = 155,
    W34_APP_OPENS = 156,
    W34_LENS_SWIPES = 157,
    W34_LENS_SNAPS = 158,
    W34_LENS_OPS = 159,
    LENS_SWIPES_PREDICTION = 160,
    REGISTRATION_COUNTRY = 161,
    IP_ASN = 162,
    IP_REGION = 163,
    IP_CITY = 164,
    HAS_USER_ID = 165,
    HAS_BITMOJI = 166,
    NUM_APP_OPENS_LAST_8_DAYS = 167,
    NUM_FEATURE_STORY_VIEW_DAYS_L7 = 168,
    NUM_FEATURE_STORY_SYNC_DAYS_L7 = 169,
    HEXAGON_NN_SUPPORTED_VERSION = 170,
    NETWORK_QUALITY = 171,
    DEVICE_MEMORY_MB = 172,
    DEVICE_GL_VERSION = 173,
    SNAP_PRO_STATUS = 174,
    DEVICE_VP9_DECODING_SUPPORT = 175,
    AVG_FRIEND_STORY_VIEW_COUNT_L7 = 176,
    GAME_JOIN_TIME = 177,
    GAME_LAST_ACTIVE_TIME = 178,
    DAYS_SINCE_FIRST_GAME_ACTIVITY = 179,
    DAYS_SINCE_LAST_GAME_ACTIVITY = 180,
    PF_PAGE_SESSIONS_WITH_LONG_IMP = 181,
    WIRELESS_CARRIER = 182,
    MINIS_JOIN_TIME = 183,
    MINIS_LAST_ACTIVE_TIME = 184,
    DAYS_SINCE_FIRST_MINIS_ACTIVITY = 185,
    DAYS_SINCE_LAST_MINIS_ACTIVITY = 186,
    DEVICE_AV1_DECODING_SUPPORT = 187,
    APP_PACKAGE_INSTALLER = 188,
    STORY_VIEWS_5TH_TAB_ENGAGEMENT_LEVEL = 189,
    REPORTED_AGE = 190,
    ANDROID_MOBILE_SERVICES_PROVIDER = 191,
    IS_ACQUIRED_USER = 192,
    YDPI = 193,
    BIDIRECTIONAL_FRIEND_STATUS_VELLUM = 194,
    ORIGIN = 195,
    LENSCORE_VERSION = 196,
    SNAPKIT_APP_ID = 197,
    GPU = 198,
    CHIPSET_NAME = 199,
    CHIPSET_VERSION = 200,
    HAS_ZERO_IDFA = 201,
    LIMIT_AD_TRACKING = 202,
    ATT_AUTH_STATUS = 203,
    CAMERA2_FRONT_SS_GAIN_OVER_TPA = 204,
    ATTACHMENT_TOOL_V2 = 205,
    USER_PERSONA_V3 = 206,
    SNAPS_SEND_WITH_HEVC = 207,
    SNAPS_SEND_WITHOUT_HEVC = 208,
    CAMERA2_NATIVE_CRASH_OVER_CAMERA1 = 209,
    CAMERA2_G2S_LATENCY_OVER_CAMERA1 = 210,
    IS_INTERNAL = 211,
    IS_WEB = 212,
    APP_OPEN_TO_MAP = 213,
    APP_OPEN_TO_FRIENDSFEED = 214,
    APP_OPEN_TO_LENSES = 215,
    APP_OPEN_TO_MEMORIES = 216,
    APP_OPEN_TO_COMMUNITY = 217,
    APP_OPEN_TO_SPOTLIGHT = 218,
    IS_IMPACTED_BY_PINC_893 = 219,
    DAYS_BEFORE_BIRTHDAY = 220,
    HAS_BIPA = 221,
    SPOTLIGHT_STORY_ENGAGEMENT_STATUS = 222,
    INCLUSION_PANEL_MEMBER = 223,
    HEVC_HW_DECODER = 224,
    HEVC_SW_DECODER = 225,
    HASH_MURMUR3_128_MOD_100 = 226,
    HAS_ACTIVE_SNAPCHAT_PLUS = 227,
    SNAP_KIT_OAUTH_ID = 228,
    ORGANIZATION_TYPE = 229,
    CHUNK_UPLOAD_SUPPORT_REQUIRED = 230,
    CLOUDFRONT_POP = 231,
    BILLBOARD_CAMPAIGN_LOCAL_FIRST_IMPRESSION_TIME_SECS_TO_NOW = 232,
    BILLBOARD_CAMPAIGN_LOCAL_CLICK_COUNT = 233,
    BILLBOARD_CAMPAIGN_LOCAL_INTERACTION_COUNT = 234,
    BILLBOARD_CAMPAIGN_LOCAL_LAST_INTERACTION_TIME_SECS_TO_NOW = 235,
    FST_LOCK_SCREEN_WIDGET_BILLBOARD_LAUNCHED_FROM_PUSH = 236,
    WEB_SCREEN_WIDTH = 237,
    WEB_SCREEN_HEIGHT = 238,
    SUP_IOS_LOCK_SCREEN_WIDGET_ENABLED = 239,
    SNAP_PRIVACY = 240,
    ADS_INTERFACES_IS_NEW_ORGANIZATION = 241,
    SERVER_ONLY = 242,
    GHE_ORGANIZATION = 243,
    GHE_REPOSITORY = 244,
    GHE_USER_EMAIL = 245,
    BUILD_DEFINITION_ID = 246,
    BUILD_DEFINITION_NAME = 247,
    LCA_PRINCIPAL = 248,
    MEDIA_PERFORMANCE_CLASS = 249,
    COMMUNITY_COUNT = 250,
    RECIPIENTS_SUPPORT_FMP4 = 251,
    SCREEN_ASPECT_RATIO = 252,
    USER_GROUP = 253,
    USER_HAS_DENIED_CAMERA_PERM = 254,
    CAMEOS_ENGAGEMENT_LEVEL = 255,
    HAS_CAMEOS = 256,
    IS_ACTIVE_LENS_VIDEO_CHAT_USER = 257,
    NUM_SPOTLIGHT_POSTS_L7 = 258,
    NUM_MAP_POSTS_L7 = 259,
    HAS_CREATED_PUBLIC_PROFILE = 260,
    HAS_PUBLIC_PROFILE_ACCESS_VIA_ROLE = 261,
    DAYS_SINCE_LAST_CAMERA_PERM_DENY = 262,
    DAYS_SINCE_AD_ORG_JOIN = 263,
    DAYS_SINCE_FIRST_AD_CREATE = 264,
    ORGANIZATION_COUNTRY = 265,
    DAYS_SINCE_FIRST_DWEB_VISIT = 266,
    DAYS_SINCE_LAST_DWEB_VISIT = 267,
    WEEKS_SINCE_USING_DWEB = 268,
    HAS_LENS = 269,
    BILLBOARD_SERVER_IMPRESSION_COUNT = 270,
    BILLBOARD_SERVER_CLICK_COUNT = 271,
    BILLBOARD_SERVER_DISMISS_COUNT = 272,
    BILLBOARD_SERVER_INTERACTION_COUNT = 273,
    BILLBOARD_SERVER_FIRST_IMPRESSION_TIME_SECS_TO_NOW = 274,
    BILLBOARD_SERVER_LAST_IMPRESSION_TIME_SECS_TO_NOW = 275,
    BILLBOARD_SERVER_FIRST_CLICK_TIME_SECS_TO_NOW = 276,
    BILLBOARD_SERVER_LAST_CLICK_TIME_SECS_TO_NOW = 277,
    BILLBOARD_SERVER_FIRST_DISMISS_TIME_SECS_TO_NOW = 278,
    BILLBOARD_SERVER_LAST_DISMISS_TIME_SECS_TO_NOW = 279,
    BILLBOARD_SERVER_FIRST_INTERACTION_TIME_SECS_TO_NOW = 280,
    BILLBOARD_SERVER_LAST_INTERACTION_TIME_SECS_TO_NOW = 281,
    BILLBOARD_SERVER_CONTINUOUS_DISMISS_COUNT = 282,
    BILLBOARD_CAMPAIGN_LOCAL_FIRST_CLICK_TIME_SECS_TO_NOW = 283,
    BILLBOARD_CAMPAIGN_LOCAL_LAST_CLICK_TIME_SECS_TO_NOW = 284,
    BILLBOARD_CAMPAIGN_LOCAL_FIRST_DISMISS_TIME_SECS_TO_NOW = 285,
    BILLBOARD_CAMPAIGN_LOCAL_LAST_DISMISS_TIME_SECS_TO_NOW = 286,
    BILLBOARD_CAMPAIGN_LOCAL_FIRST_INTERACTION_TIME_SECS_TO_NOW = 287,
    DAYS_SINCE_FIRST_AD_SPEND = 288,
    DAYS_SINCE_LAST_AD_SPEND = 289,
    SPOTLIGHT_2_PLUS_STORY_SESSION_7D_STATUS_DETAILED = 290,
    SPOTLIGHT_5_PLUS_STORY_SESSION_7D_STATUS_DETAILED = 291,
    SPOTLIGHT_STORY_VIEW_7D_STATUS_DETAILED = 292,
    LAST_USER_ACCEPTED_TOS = 293,
    UPDATED_DEVICE_CLUSTER = 294,
    CREATOR_TIER = 295,
    AV1_SW_DECODER = 296,
    AV1_HW_DECODER = 297,
    HAS_EXPLICIT_PUBLIC_PROFILE = 298,
    LARGER_TEXT_DISPLAY_OPTION_ENABLED = 299,
    IS_PUBLIC_POSTING_PREFERRED_USER = 300,
    RUID = 301,
    MUTABLE_USERNAME = 302,
    BUDGET_GROUP_ID = 303,
    AB_POPULATION_RANGE_HASH_FUNC = 304,
    AB_TREATMENT_RANGE_HASH_FUNC = 305,
    REGISTRATION_IP_REGION = 306,
    RUID_TYPE = 307,
    PLUS_INTERNAL_ONLY = 308,
    COF_ROLLOUT_RANGE_HASH_FUNC = 309,
    HAS_AI_SELFIE = 341,
    HAS_DREAMS = 342,
    DAYS_SINCE_LAST_ACTIVITY = 343,
    CONTACT_PERM_OS_GRANTED = 344,
    CONTACT_PERM_USER_GRANTED = 345,
    LENS_CLUSTER_GPU_V2 = 346,
    CAN_ACCESS_ADS_TAB = 347,
    GOOGLE_CDN_POP = 348,
    NUM_STRONG_RELATIONSHIPS_V3 = 349,
    NUM_CLOSE_PLUS_RELATIONSHIPS_V3 = 350,
    NUM_ACQUAINTANCE_PLUS_RELATIONSHIPS_V3 = 351,
    UNRECOGNIZED = -1
}
export interface ClientTargetingExpression_PropertyMetadata {
    itemId: number;
    signalToHash: ClientTargetingExpression_PropertyMetadata_SignalToHash;
    abNamespaceForHashing: string;
    abSeedForHashing: string;
    ruidType: Ruid_Type | undefined;
    cofRolloutSeedForHashing: string;
}
export enum ClientTargetingExpression_PropertyMetadata_SignalToHash {
    UNKNOWN_SIGNAL_TO_HASH = 0,
    HASH_SEED_AND_USER_ID = 1,
    HASH_USER_ID_AND_NAMESPACE = 2,
    UNRECOGNIZED = -1
}
function createBaseConfigResult(): ConfigResult {
    return {
        configId: "",
        value: undefined,
        targetingExpression: undefined,
        ttlSeconds: 0,
        configRuleUuidBytes: new Uint8Array(),
        priority: 0,
        namespace: 0,
        studyName: "",
        experimentId: 0,
        delete: false,
        servePlatforms: [],
        sequenceId: 0,
        ruidType: 0,
        segmentOrdinal: 0,
        generatedFromAbAllowlists: false,
        internalFields: undefined,
    };
}
export const ConfigResult = {
    encode(message: ConfigResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.configId !== "") {
            writer.uint32(10).string(message.configId);
        }
        if (message.value !== undefined) {
            Value.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        if (message.targetingExpression !== undefined) {
            ClientTargetingExpression.encode(message.targetingExpression, writer.uint32(26).fork()).ldelim();
        }
        if (message.ttlSeconds !== 0) {
            writer.uint32(32).int64(message.ttlSeconds);
        }
        if (message.configRuleUuidBytes.length !== 0) {
            writer.uint32(42).bytes(message.configRuleUuidBytes);
        }
        if (message.priority !== 0) {
            writer.uint32(48).int32(message.priority);
        }
        if (message.namespace !== 0) {
            writer.uint32(56).int32(message.namespace);
        }
        if (message.studyName !== "") {
            writer.uint32(66).string(message.studyName);
        }
        if (message.experimentId !== 0) {
            writer.uint32(72).int32(message.experimentId);
        }
        if (message.delete === true) {
            writer.uint32(80).bool(message.delete);
        }
        writer.uint32(90).fork();
        for (const v of message.servePlatforms) {
            writer.int32(v);
        }
        writer.ldelim();
        if (message.sequenceId !== 0) {
            writer.uint32(96).int32(message.sequenceId);
        }
        if (message.ruidType !== 0) {
            writer.uint32(104).int32(message.ruidType);
        }
        if (message.segmentOrdinal !== 0) {
            writer.uint32(112).int32(message.segmentOrdinal);
        }
        if (message.generatedFromAbAllowlists === true) {
            writer.uint32(120).bool(message.generatedFromAbAllowlists);
        }
        if (message.internalFields !== undefined) {
            ConfigResult_InternalFields.encode(message.internalFields, writer.uint32(130).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ConfigResult {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConfigResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configId = reader.string();
                    break;
                case 2:
                    message.value = Value.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.targetingExpression = ClientTargetingExpression.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.ttlSeconds = longToNumber(reader.int64() as Long);
                    break;
                case 5:
                    message.configRuleUuidBytes = reader.bytes();
                    break;
                case 6:
                    message.priority = reader.int32();
                    break;
                case 7:
                    message.namespace = reader.int32() as any;
                    break;
                case 8:
                    message.studyName = reader.string();
                    break;
                case 9:
                    message.experimentId = reader.int32();
                    break;
                case 10:
                    message.delete = reader.bool();
                    break;
                case 11:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.servePlatforms.push(reader.int32() as any);
                        }
                    }
                    else {
                        message.servePlatforms.push(reader.int32() as any);
                    }
                    break;
                case 12:
                    message.sequenceId = reader.int32();
                    break;
                case 13:
                    message.ruidType = reader.int32() as any;
                    break;
                case 14:
                    message.segmentOrdinal = reader.int32();
                    break;
                case 15:
                    message.generatedFromAbAllowlists = reader.bool();
                    break;
                case 16:
                    message.internalFields = ConfigResult_InternalFields.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ConfigResult>, I>>(object: I): ConfigResult {
        const message = createBaseConfigResult();
        message.configId = object.configId ?? "";
        message.value = object.value !== undefined && object.value !== null ? Value.fromPartial(object.value) : undefined;
        message.targetingExpression =
            object.targetingExpression !== undefined && object.targetingExpression !== null
                ? ClientTargetingExpression.fromPartial(object.targetingExpression)
                : undefined;
        message.ttlSeconds = object.ttlSeconds ?? 0;
        message.configRuleUuidBytes = object.configRuleUuidBytes ?? new Uint8Array();
        message.priority = object.priority ?? 0;
        message.namespace = object.namespace ?? 0;
        message.studyName = object.studyName ?? "";
        message.experimentId = object.experimentId ?? 0;
        message.delete = object.delete ?? false;
        message.servePlatforms = object.servePlatforms?.map((e) => e) || [];
        message.sequenceId = object.sequenceId ?? 0;
        message.ruidType = object.ruidType ?? 0;
        message.segmentOrdinal = object.segmentOrdinal ?? 0;
        message.generatedFromAbAllowlists = object.generatedFromAbAllowlists ?? false;
        message.internalFields =
            object.internalFields !== undefined && object.internalFields !== null
                ? ConfigResult_InternalFields.fromPartial(object.internalFields)
                : undefined;
        return message;
    },
};
function createBaseConfigResult_InternalFields(): ConfigResult_InternalFields {
    return {
        configBitmapIndex: 0,
        configResultBitmapIndex: 0,
        hasServerPropertiesOnly: false,
        globalPriority: 0,
        sequenceIds: [],
        studySegmentOrdinal: 0,
        experimentGuid: 0,
        isAbStudyStatusCompleted: false,
    };
}
export const ConfigResult_InternalFields = {
    encode(message: ConfigResult_InternalFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.configBitmapIndex !== 0) {
            writer.uint32(8).int32(message.configBitmapIndex);
        }
        if (message.configResultBitmapIndex !== 0) {
            writer.uint32(16).int32(message.configResultBitmapIndex);
        }
        if (message.hasServerPropertiesOnly === true) {
            writer.uint32(24).bool(message.hasServerPropertiesOnly);
        }
        if (message.globalPriority !== 0) {
            writer.uint32(32).int32(message.globalPriority);
        }
        for (const v of message.sequenceIds) {
            ConfigResult_InternalFields_SequenceIdCandidate.encode(v!, writer.uint32(42).fork()).ldelim();
        }
        if (message.studySegmentOrdinal !== 0) {
            writer.uint32(48).int32(message.studySegmentOrdinal);
        }
        if (message.experimentGuid !== 0) {
            writer.uint32(56).uint64(message.experimentGuid);
        }
        if (message.isAbStudyStatusCompleted === true) {
            writer.uint32(64).bool(message.isAbStudyStatusCompleted);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ConfigResult_InternalFields {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConfigResult_InternalFields();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configBitmapIndex = reader.int32();
                    break;
                case 2:
                    message.configResultBitmapIndex = reader.int32();
                    break;
                case 3:
                    message.hasServerPropertiesOnly = reader.bool();
                    break;
                case 4:
                    message.globalPriority = reader.int32();
                    break;
                case 5:
                    message.sequenceIds.push(ConfigResult_InternalFields_SequenceIdCandidate.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.studySegmentOrdinal = reader.int32();
                    break;
                case 7:
                    message.experimentGuid = longToNumber(reader.uint64() as Long);
                    break;
                case 8:
                    message.isAbStudyStatusCompleted = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ConfigResult_InternalFields>, I>>(object: I): ConfigResult_InternalFields {
        const message = createBaseConfigResult_InternalFields();
        message.configBitmapIndex = object.configBitmapIndex ?? 0;
        message.configResultBitmapIndex = object.configResultBitmapIndex ?? 0;
        message.hasServerPropertiesOnly = object.hasServerPropertiesOnly ?? false;
        message.globalPriority = object.globalPriority ?? 0;
        message.sequenceIds =
            object.sequenceIds?.map((e) => ConfigResult_InternalFields_SequenceIdCandidate.fromPartial(e)) || [];
        message.studySegmentOrdinal = object.studySegmentOrdinal ?? 0;
        message.experimentGuid = object.experimentGuid ?? 0;
        message.isAbStudyStatusCompleted = object.isAbStudyStatusCompleted ?? false;
        return message;
    },
};
function createBaseConfigResult_InternalFields_SequenceIdCandidate(): ConfigResult_InternalFields_SequenceIdCandidate {
    return { targetingExpression: undefined, sequenceId: 0 };
}
export const ConfigResult_InternalFields_SequenceIdCandidate = {
    encode(message: ConfigResult_InternalFields_SequenceIdCandidate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.targetingExpression !== undefined) {
            ClientTargetingExpression.encode(message.targetingExpression, writer.uint32(10).fork()).ldelim();
        }
        if (message.sequenceId !== 0) {
            writer.uint32(16).int32(message.sequenceId);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ConfigResult_InternalFields_SequenceIdCandidate {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConfigResult_InternalFields_SequenceIdCandidate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.targetingExpression = ClientTargetingExpression.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.sequenceId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ConfigResult_InternalFields_SequenceIdCandidate>, I>>(object: I): ConfigResult_InternalFields_SequenceIdCandidate {
        const message = createBaseConfigResult_InternalFields_SequenceIdCandidate();
        message.targetingExpression =
            object.targetingExpression !== undefined && object.targetingExpression !== null
                ? ClientTargetingExpression.fromPartial(object.targetingExpression)
                : undefined;
        message.sequenceId = object.sequenceId ?? 0;
        return message;
    },
};
function createBaseConfigResultBundle(): ConfigResultBundle {
    return { etag: "", configResults: [] };
}
export const ConfigResultBundle = {
    encode(message: ConfigResultBundle, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.etag !== "") {
            writer.uint32(10).string(message.etag);
        }
        for (const v of message.configResults) {
            ConfigResult.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ConfigResultBundle {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConfigResultBundle();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.etag = reader.string();
                    break;
                case 2:
                    message.configResults.push(ConfigResult.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ConfigResultBundle>, I>>(object: I): ConfigResultBundle {
        const message = createBaseConfigResultBundle();
        message.etag = object.etag ?? "";
        message.configResults = object.configResults?.map((e) => ConfigResult.fromPartial(e)) || [];
        return message;
    },
};
function createBaseSequenceIdCandidate(): SequenceIdCandidate {
    return { targetingExpression: undefined, sequenceId: 0 };
}
export const SequenceIdCandidate = {
    encode(message: SequenceIdCandidate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.targetingExpression !== undefined) {
            ClientTargetingExpression.encode(message.targetingExpression, writer.uint32(10).fork()).ldelim();
        }
        if (message.sequenceId !== 0) {
            writer.uint32(16).int32(message.sequenceId);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): SequenceIdCandidate {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSequenceIdCandidate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.targetingExpression = ClientTargetingExpression.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.sequenceId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<SequenceIdCandidate>, I>>(object: I): SequenceIdCandidate {
        const message = createBaseSequenceIdCandidate();
        message.targetingExpression =
            object.targetingExpression !== undefined && object.targetingExpression !== null
                ? ClientTargetingExpression.fromPartial(object.targetingExpression)
                : undefined;
        message.sequenceId = object.sequenceId ?? 0;
        return message;
    },
};
function createBaseClientTargetingExpression(): ClientTargetingExpression {
    return {
        operator: 0,
        children: [],
        property: 0,
        predicateOperator: 0,
        value: undefined,
        propertyMetadata: undefined,
    };
}
export const ClientTargetingExpression = {
    encode(message: ClientTargetingExpression, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.operator !== 0) {
            writer.uint32(8).int32(message.operator);
        }
        for (const v of message.children) {
            ClientTargetingExpression.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        if (message.property !== 0) {
            writer.uint32(24).int32(message.property);
        }
        if (message.predicateOperator !== 0) {
            writer.uint32(32).int32(message.predicateOperator);
        }
        if (message.value !== undefined) {
            Value.encode(message.value, writer.uint32(42).fork()).ldelim();
        }
        if (message.propertyMetadata !== undefined) {
            ClientTargetingExpression_PropertyMetadata.encode(message.propertyMetadata, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ClientTargetingExpression {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseClientTargetingExpression();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.operator = reader.int32() as any;
                    break;
                case 2:
                    message.children.push(ClientTargetingExpression.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.property = reader.int32() as any;
                    break;
                case 4:
                    message.predicateOperator = reader.int32() as any;
                    break;
                case 5:
                    message.value = Value.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.propertyMetadata = ClientTargetingExpression_PropertyMetadata.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ClientTargetingExpression>, I>>(object: I): ClientTargetingExpression {
        const message = createBaseClientTargetingExpression();
        message.operator = object.operator ?? 0;
        message.children = object.children?.map((e) => ClientTargetingExpression.fromPartial(e)) || [];
        message.property = object.property ?? 0;
        message.predicateOperator = object.predicateOperator ?? 0;
        message.value = object.value !== undefined && object.value !== null ? Value.fromPartial(object.value) : undefined;
        message.propertyMetadata =
            object.propertyMetadata !== undefined && object.propertyMetadata !== null
                ? ClientTargetingExpression_PropertyMetadata.fromPartial(object.propertyMetadata)
                : undefined;
        return message;
    },
};
function createBaseClientTargetingExpression_PropertyMetadata(): ClientTargetingExpression_PropertyMetadata {
    return {
        itemId: 0,
        signalToHash: 0,
        abNamespaceForHashing: "",
        abSeedForHashing: "",
        ruidType: undefined,
        cofRolloutSeedForHashing: "",
    };
}
export const ClientTargetingExpression_PropertyMetadata = {
    encode(message: ClientTargetingExpression_PropertyMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.itemId !== 0) {
            writer.uint32(8).int32(message.itemId);
        }
        if (message.signalToHash !== 0) {
            writer.uint32(16).int32(message.signalToHash);
        }
        if (message.abNamespaceForHashing !== "") {
            writer.uint32(26).string(message.abNamespaceForHashing);
        }
        if (message.abSeedForHashing !== "") {
            writer.uint32(34).string(message.abSeedForHashing);
        }
        if (message.ruidType !== undefined) {
            writer.uint32(40).int32(message.ruidType);
        }
        if (message.cofRolloutSeedForHashing !== "") {
            writer.uint32(50).string(message.cofRolloutSeedForHashing);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ClientTargetingExpression_PropertyMetadata {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseClientTargetingExpression_PropertyMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.itemId = reader.int32();
                    break;
                case 2:
                    message.signalToHash = reader.int32() as any;
                    break;
                case 3:
                    message.abNamespaceForHashing = reader.string();
                    break;
                case 4:
                    message.abSeedForHashing = reader.string();
                    break;
                case 5:
                    message.ruidType = reader.int32() as any;
                    break;
                case 6:
                    message.cofRolloutSeedForHashing = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ClientTargetingExpression_PropertyMetadata>, I>>(object: I): ClientTargetingExpression_PropertyMetadata {
        const message = createBaseClientTargetingExpression_PropertyMetadata();
        message.itemId = object.itemId ?? 0;
        message.signalToHash = object.signalToHash ?? 0;
        message.abNamespaceForHashing = object.abNamespaceForHashing ?? "";
        message.abSeedForHashing = object.abSeedForHashing ?? "";
        message.ruidType = object.ruidType ?? undefined;
        message.cofRolloutSeedForHashing = object.cofRolloutSeedForHashing ?? "";
        return message;
    },
};
declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
function longToNumber(long: Long): number {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
