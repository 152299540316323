import { CameraKitBootstrapConfiguration } from "./configuration";

/**
 * Prefix of override key on window object.
 */
const windowFieldPrefix = "__snap_camkit_override__";

/**
 * Configuration overrides that are stored in session storage.
 */
type StoredOverrides = Pick<CameraKitBootstrapConfiguration, "wasmEndpointOverride" | "logger" | "logLevel">;

const configPropertiesToOverride: Array<keyof StoredOverrides> = ["wasmEndpointOverride", "logger", "logLevel"];

configPropertiesToOverride.forEach((fieldToOverride) => {
    defineWindowField(fieldToOverride);
});

function defineWindowField(propertyToOverride: keyof StoredOverrides) {
    if (typeof window === "undefined") return;

    Object.defineProperty(window, `${windowFieldPrefix}${propertyToOverride}`, {
        get() {
            return getConfigurationOverrides()?.[propertyToOverride];
        },
        set(value: any) {
            const storedOverrdies = getConfigurationOverrides() ?? {};
            storedOverrdies[propertyToOverride] = value;
            if (Object.values(storedOverrdies).every((value) => typeof value === "undefined")) {
                sessionStorage.removeItem(windowFieldPrefix);
            } else {
                sessionStorage.setItem(windowFieldPrefix, JSON.stringify(storedOverrdies));
            }
        },
        enumerable: false,
        configurable: true,
    });
}

/**
 * Checks whether there are configuration overrides stored, and if yes, returns them.
 *
 * @internal
 */
export function getConfigurationOverrides(): StoredOverrides | undefined {
    const overridesString = sessionStorage.getItem(windowFieldPrefix);
    return overridesString && JSON.parse(overridesString);
}
