import { Injectable } from "../../dependency-injection/Injectable";
import { lensAssetError } from "../../namedErrors";
import { lensCoreFactory, LensCoreModule } from "../../lens-core-module";
import { getLogger } from "../../logger/logger";
import { MakeTaggedBusinessEvent } from "../../metrics/businessEventsReporter";
import { toPublicLens } from "../Lens";
import { LensRepository, lensRepositoryFactory } from "../LensRepository";
import { LensAssetRepository, lensAssetRepositoryFactory } from "./LensAssetRepository";

const logger = getLogger("LensAssetProvider");
const maxConsecutiveErrors = 3;

/**
 * The AssetValidationFailed metric reports every time we handle an asset checksum validation failure.
 */
export type AssetValidationFailed = MakeTaggedBusinessEvent<"assetValidationFailed">;

/**
 * Registers a remote asset provider function with a given instance of LensCore.
 *
 * *Note:* LensCoreModule.initialize must be called on the desired LensCoreModule instance **prior** to passing it
 * to the LensAssetsProvider constructor. If this class is instantiated with a LensCoreModule that has not been
 * initialized, the registry of the asset provider function will fail silently and no remote assets will be loaded.
 * @internal
 */
export const registerLensAssetsProvider = Injectable(
    "registerLensAssetsProvider",
    [lensCoreFactory.token, lensRepositoryFactory.token, lensAssetRepositoryFactory.token] as const,
    (lensCore: LensCoreModule, lensRepository: LensRepository, lensAssetRepository: LensAssetRepository) => {
        const consecutiveErrorsPerAsset = new Map<string, number>();

        lensCore.setRemoteAssetsProvider(async (assetDescriptor) => {
            // Fetch an asset and provide it to LensCore. If fetching the asset fails we give LensCore
            // an empty response (which it may handle in a variety of ways, e.g. retry, gracefully
            // degrade lens behavior, throw error) and then reject.
            const { assetId, assetType, effectId } = assetDescriptor;

            try {
                if ((consecutiveErrorsPerAsset.get(assetId) ?? 0) > maxConsecutiveErrors) {
                    throw new Error(`Maximum consecutive asset load errors reached for asset ${assetId}`);
                }

                const lens = effectId ? lensRepository.getLensMetadata(effectId) : undefined;
                await lensAssetRepository.loadAsset(
                    assetDescriptor,
                    lens && toPublicLens(lens),
                    lens?.content?.assetManifest
                );
                consecutiveErrorsPerAsset.set(assetId, 0);
            } catch (error) {
                // if an error occurs, LensCore handles things in different ways
                // depending on the active lens. It might: 1) retry, 2) gracefully degrade lens behavior,
                // 3) lens JS might throw, which will be passed to the exceptionHandler we register with LensCore.
                lensCore.provideRemoteAssetsResponse({
                    assetId,
                    assetType,
                });

                const consecutiveErrors = (consecutiveErrorsPerAsset.get(assetId) ?? 0) + 1;
                consecutiveErrorsPerAsset.set(assetId, consecutiveErrors);

                // We've already reported `maxConsecutiveErrors` number of errors for this asset, so we can skip
                // logging additional errors.
                if (consecutiveErrors <= maxConsecutiveErrors) {
                    logger.error(lensAssetError(`Error occurred while handling lens asset ${assetId} request.`, error));
                } else {
                    logger.warn(`Maximum consecutive asset load errors reached for asset ${assetId}`);
                }
            }
        });
    }
);
