import { Subject, catchError, filter, map, merge, retry, takeUntil, tap } from "rxjs";
import { forActions } from "@snap/state-management";
import { Injectable } from "../dependency-injection/Injectable";
import { RemoteApiInfo } from "../generated-proto/pb_schema/camera_kit/v3/features/remote_api_info";
import { ResponseCode, responseCodeToNumber } from "../generated-proto/pb_schema/lenses/remote_api/remote_api_service";
import { LensRepository } from "../lens";
import { Lens } from "../lens/Lens";
import { LensState } from "../session/lensState";
import { SessionState } from "../session/sessionState";
import { getLogger } from "../logger/logger";
import { knownAnyTypes } from "../common/any";
import { CamelToSnakeCase, SnakeToCamelCase } from "../common/types";
import { OperationalMetricsReporter } from "../metrics/operational/operationalMetricsReporter";
import { UriCancelRequest, UriHandler, UriRequest, UriResponse, extractSchemeAndRoute } from "./UriHandlers";

// NOTE: There's potential for overloads when reporting metrics if reporting is triggered on each frame,
// (i.e., when the lens sends Remote API requests every frame).
// As of now, this isn't a concern because src/metrics/operational/operationalMetricsReporter.ts aggregates
// "count" metrics into a single metric within a batch, and the Remote API service currently
// reports only "count" metrics. For instance, if 30 metrics with the same name are generated per second,
// given the current bundle size of 100 operational metrics, there will be one metrics report approximately
// every 3.3 seconds.
// In the future, if we opt to report "histogram" or other metric types, they must be approached with caution:
// either the operationalMetricsReporter should be enhanced to aggregate such metrics,
// or the Remote API service should manage it directly.
// Mobiles ticket: https://jira.sc-corp.net/browse/CAMKIT-3092

const logger = getLogger("RemoteApiServices");

const uriResponseOkCode = 200;
const apiResponseStatusHeader = ":sc_lens_api_status";
const apiBinaryContentType = "application/octet-stream";

const statusToResponseCodeMap = {
    success: ResponseCode.SUCCESS,
    redirected: ResponseCode.REDIRECTED,
    badRequest: ResponseCode.BAD_REQUEST,
    accessDenied: ResponseCode.ACCESS_DENIED,
    notFound: ResponseCode.NOT_FOUND,
    timeout: ResponseCode.TIMEOUT,
    requestTooLarge: ResponseCode.REQUEST_TOO_LARGE,
    serverError: ResponseCode.SERVER_ERROR,
    cancelled: ResponseCode.CANCELLED,
    proxyError: ResponseCode.PROXY_ERROR,
} satisfies { [Status in RemoteApiStatusInternal]: Uppercase<CamelToSnakeCase<Status>> };

type RemoteApiStatusInternal = SnakeToCamelCase<
    Lowercase<Exclude<keyof typeof ResponseCode, "RESPONSE_CODE_UNSET" | "UNRECOGNIZED">>
>;

type LensId = string;
type RequestId = string;

type UriRequestEvent = {
    request: UriRequest;
    reply: (response: UriResponse) => void;
    lens: Lens;
};

type UriCancelRequestEvent = {
    request: UriCancelRequest;
    lens: Lens;
};

interface LensRequestState {
    cancellationHandlers: Map<RequestId, RemoteApiCancelRequestHandler>;
    supportedSpecIds: Set<string>;
}

/**
 * Invokes the cancellation handler associated with the provided key and removes it from the collection of handlers.
 */
function callCancellationHandler(
    cancellationHandlers: Map<RequestId, RemoteApiCancelRequestHandler>,
    ...keys: RequestId[]
) {
    for (const key of keys) {
        cancellationHandlers.get(key)?.();
        cancellationHandlers.delete(key);
    }
}

/**
 * Removes the specified lenses' metadata from the cache and invokes their cancellation callbacks.
 *
 * @param lensRequestState The state representing the lens cache.
 * @param lensIds An array of lens IDs to be removed from the cache
 * and for which the cancellation callbacks will be invoked.
 */
function handleLensApplicationEnd(lensRequestState: Map<LensId, LensRequestState>, ...lensIds: LensId[]) {
    for (const lensId of lensIds) {
        const state = lensRequestState.get(lensId);
        if (state) {
            callCancellationHandler(state.cancellationHandlers, ...state.cancellationHandlers.keys());
            lensRequestState.delete(lensId);
        }
    }
}

/**
 * Status of a Remote API response.
 */
export type RemoteApiStatus = keyof typeof statusToResponseCodeMap;

/**
 * Remote API request sent by a lens.
 */
export interface RemoteApiRequest {
    /**
     * Unique id of the remote API service specification.
     */
    apiSpecId: string;
    /**
     * Unique id of the remote API service endpoint requested by this request.
     */
    endpointId: string;
    /**
     * A map of named parameters associated with the request.
     */
    parameters: Record<string, string>;
    /**
     * Additional binary request payload.
     */
    body: ArrayBuffer;
}

/**
 * Remote API response to a request sent by a lens.
 */
export interface RemoteApiResponse {
    /**
     * Status of the response
     */
    status: RemoteApiStatus;
    /**
     * A map of named metadata associated with the response.
     */
    metadata: Record<string, string>;
    /**
     * Additional binary request payload.
     */
    body: ArrayBuffer;
}

/**
 * Represents a Remote API request cancellation handler function.
 */
export type RemoteApiCancelRequestHandler = () => void;

/**
 * Represents a Remote API request handler function.
 * It is provided with a reply callback that must be invoked to send a response back to the lens.
 * The reply callback can be invoked multiple times if needed.
 * Additionally, the handler can return a cancellation callback, which is triggered when the lens cancels the request.
 */
export type RemoteApiRequestHandler = (
    reply: (response: RemoteApiResponse) => void
) => RemoteApiCancelRequestHandler | void;

/**
 * Service to handle a lens Remote API request.
 */
export interface RemoteApiService {
    /**
     * Remote API spec ID(s).
     */
    apiSpecId: string;

    /**
     * This method is called by Camera Kit when a lens triggers a Remote API request with a corresponding spec ID.
     * If the service can handle the request, the method returns a request handler; otherwise, it returns nothing.
     * @param request Remote API request object.
     * @param lens Lens that trigges the request.
     * @returns A request handler if applicable.
     */
    getRequestHandler(request: RemoteApiRequest, lens: Lens): RemoteApiRequestHandler | undefined;
}

export type RemoteApiServices = RemoteApiService[];

export const remoteApiServicesFactory = Injectable("remoteApiServices", () => {
    const remoteApiServices: RemoteApiServices = [];
    return remoteApiServices;
});

/**
 * Provides a URI handler that searches for a match within the provided services to handle Remote API requests,
 * i.e., those whose URI starts with 'app://remote-api/performApiRequest'.
 */
export function getRemoteApiUriHandler(
    registeredServices: RemoteApiService[],
    sessionState: SessionState,
    lensState: LensState,
    lensRepository: LensRepository,
    reporter: OperationalMetricsReporter
): UriHandler {
    // Groups services by spec ID for faster lookups.
    const registeredServiceMap = new Map<string, RemoteApiService[]>();
    for (const service of registeredServices) {
        const existingServices = registeredServiceMap.get(service.apiSpecId) || [];
        registeredServiceMap.set(service.apiSpecId, [...existingServices, service]);
    }

    const uriRequests = new Subject<UriRequestEvent>();
    const uriCancelRequests = new Subject<UriCancelRequestEvent>();
    const lensRequestState = new Map<LensId, LensRequestState>();

    const lensTurnOffEvents = lensState.events.pipe(
        forActions("turnedOff"),

        tap(([action]) => handleLensApplicationEnd(lensRequestState, action.data.id))
    );

    const uriRequestEvents = uriRequests.pipe(
        map((uriRequest) => {
            const lensId = uriRequest.lens.id;

            if (!lensRequestState.has(lensId)) {
                lensRequestState.set(lensId, {
                    // Prepares a collection to store cancellation handlers.
                    // A specific handler will be invoked when a cancellation request is issued by the lens.
                    // All handlers will be invoked when the lens is replaced with another one or the session
                    // is destroyed.
                    cancellationHandlers: new Map(),
                    // Parse lens metadata to obtain supported Remote API specs.
                    supportedSpecIds: new Set(
                        (lensRepository.getLensMetadata(lensId)?.featureMetadata ?? [])
                            .filter((feature) => feature.typeUrl === knownAnyTypes.remoteApiInfo)
                            .flatMap((any) => RemoteApiInfo.decode(any.value).apiSpecIds)
                    ),
                });
            }
            const requestState = lensRequestState.get(lensId)!;

            // Extracts the spec ID and endpoint ID from the provided Remote API request URI.
            // The given URI is expected to conform to the following specification:
            // eslint-disable-next-line max-len
            // https://docs.google.com/document/d/18fbGYDhD2N_aMTe4ZLY4QKeCSoMeJuklG28TutDzLZc/edit#bookmark=id.p2y39gwgbm4g
            const { route } = extractSchemeAndRoute(uriRequest.request.uri);
            const [specId, endpointIdWithQuery] = route.split("/").slice(2);
            const [endpointId] = endpointIdWithQuery.split("?");

            return { uriRequest, specId, endpointId, requestState };
        }),

        // only handle requests for API spec ID that current lens supports
        filter(({ specId, requestState }) => requestState.supportedSpecIds.has(specId)),

        // only handle requests if we have a registered service for it
        filter(({ specId }) => registeredServiceMap.has(specId)),

        map(({ uriRequest, specId, endpointId, requestState }) => {
            const dimensions = new Map([["specId", specId]]);
            reporter.count("lens_remote-api_requests", 1, dimensions);

            const remoteApiRequest: RemoteApiRequest = {
                apiSpecId: specId,
                body: uriRequest.request.data,
                endpointId,
                parameters: uriRequest.request.metadata,
            };

            // Looks for the first Remote API request handler.
            for (const service of registeredServiceMap.get(specId) ?? []) {
                let requestHandler: RemoteApiRequestHandler | undefined = undefined;
                try {
                    requestHandler = service.getRequestHandler(remoteApiRequest, uriRequest.lens);
                } catch {
                    logger.warn("Client's Remote API request handler factory threw an error.");
                }

                if (requestHandler) {
                    reporter.count("lens_remote-api_handled-requests", 1, dimensions);

                    let cancellationHandler: RemoteApiCancelRequestHandler | void = undefined;
                    try {
                        // Calls client's Remote API handler to process the request.
                        cancellationHandler = requestHandler((response) => {
                            reporter.count("lens_remote-api_responses", 1, dimensions);

                            const responseCode = statusToResponseCodeMap[response.status] ?? ResponseCode.UNRECOGNIZED;
                            const uriResponse: UriResponse = {
                                code: uriResponseOkCode,
                                description: "",
                                contentType: apiBinaryContentType,
                                data: response.body,
                                metadata: {
                                    ...response.metadata,
                                    [apiResponseStatusHeader]: responseCodeToNumber(responseCode).toString(),
                                },
                            };
                            uriRequest.reply(uriResponse);
                        });
                    } catch (error) {
                        logger.warn("Client's Remote API request handler threw an error.");
                    }

                    if (typeof cancellationHandler === "function") {
                        requestState.cancellationHandlers.set(uriRequest.request.identifier, () => {
                            try {
                                cancellationHandler!();
                            } catch {
                                logger.warn("Client's Remote API request cancellation handler threw an error.");
                            }
                        });
                    }

                    break;
                }
            }
        })
    );

    const uriCancelRequestEvents = uriCancelRequests.pipe(
        tap((uriRequest) => {
            const cancellationHandlers = lensRequestState.get(uriRequest.lens.id)?.cancellationHandlers;
            if (cancellationHandlers) {
                callCancellationHandler(cancellationHandlers, uriRequest.request.requestId);
            }
        })
    );

    merge(lensTurnOffEvents, uriRequestEvents, uriCancelRequestEvents)
        .pipe(
            catchError((error, sourcePipe) => {
                // The expectation is that if an error occurs, it happens in our own implementation,
                // because app callbacks are wrapped with try..catch blocks.
                // Therefore, we would like to report this error.
                logger.error(error);
                reporter.count("lens_remote-api_errors", 1);
                // Return the source pipe so that we can retry the pipe instead of just completing it.
                return sourcePipe;
            }),
            // When the pipe completes due to an error,
            // we want to resubscribe to the original pipe to keep it alive.
            retry(),
            takeUntil(sessionState.events.pipe(forActions("destroy")))
        )
        .subscribe({
            complete: () => handleLensApplicationEnd(lensRequestState, ...lensRequestState.keys()),
        });

    return {
        uri: "app://remote-api/performApiRequest",

        handleRequest(request, reply, lens) {
            uriRequests.next({ request, reply, lens });
        },

        cancelRequest(request, lens) {
            uriCancelRequests.next({ request, lens });
        },
    };
}
