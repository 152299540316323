import { isUndefined } from "../common/typeguards";
import { Injectable } from "../dependency-injection/Injectable";

/**
 * A source of a lens group.
 */
export interface LensSource {
    /**
     * Whether the given source is able to load lenses of the supplied group.
     * @param groupId Group ID to check.
     */
    isGroupOwner(groupId: string): boolean;

    /**
     * Returns an encoded lens object.
     * @param lensId Lens ID to get.
     * @param groupId Group ID the lens belongs to.
     */
    getLens?(lensId: string, groupId: string): Promise<ArrayBuffer>;

    /**
     * Returns encoded lens objects.
     * @param groupId Group ID to get lenses of.
     */
    getLensGroup?(groupId: string): Promise<ArrayBuffer[]>;
}

/**
 * A chain of {@link LensSource} objects to be registered in Camera Kit on bootstrap. Camera Kit evaluates all
 * registered {@link LensSource} objects for a group ownership during Lens retrieval ({@link CameraKit.lenses}).
 * And if a source claims the ownership, its {@link LensSource.getLens} or {@link LensSource.getLensGroup}
 * methods are called.
 */
export class LensSources {
    /**
     * Returns empty LensSources instance.
     * @internal
     */
    static empty() {
        // NOTE: we want to keep LensSources constructor to require arguments
        // but internally we don't need them for the base case
        // @ts-expect-error
        return new LensSources();
    }

    private readonly fallbackSources: LensSources | undefined;
    private readonly source: LensSource | undefined;

    /**
     * Creates an instance of Lens sources.
     * @param fallbackSources A fallback sources if given {@link LensSource} doesn't claim a group ownership.
     * @param source Lens source.
     */
    constructor(fallbackSources: LensSources, source: LensSource) {
        this.fallbackSources = fallbackSources;
        this.source = source;
    }

    /**
     * Returns envelopes of lens/groups taking into account group ownership.
     * @internal
     * @param groupId A group to test ownership and get lens envelopes of.
     * @param lensId An optional lens ID to narrow envelopes down to a single lens.
     * @returns Envelopes or undefined if not applicable.
     */
    async retrieveLenses({ groupId, lensId }: { groupId: string; lensId?: string }): Promise<ArrayBuffer[] | void> {
        if (this.source?.isGroupOwner(groupId)) {
            if (isUndefined(lensId)) {
                if (this.source.getLensGroup) return this.source.getLensGroup(groupId);
            } else if (this.source.getLens) {
                return this.source.getLens(lensId, groupId).then((envelope) => [envelope]);
            }
        }
        return this.fallbackSources?.retrieveLenses({ groupId, lensId });
    }
}

export const lensSourcesFactory = Injectable("lensSources", () => LensSources.empty());
