import { take } from "rxjs";
import { Injectable } from "../dependency-injection/Injectable";
import { Namespace } from "../generated-proto/pb_schema/cdp/cof/namespace";
import { LensCoreConfig, LensCoreModule, SetPreloadedConfigurationInput, lensCoreFactory } from "../lens-core-module";
import { RemoteConfiguration, remoteConfigurationFactory } from "./remoteConfiguration";

export const setPreloadedConfiguration = Injectable(
    "setPreloadedConfiguration",
    [lensCoreFactory.token, remoteConfigurationFactory.token] as const,
    (lensCore: LensCoreModule, remoteConfiguration: RemoteConfiguration) => {
        remoteConfiguration
            .getNamespace(Namespace.LENS_CORE_CONFIG)
            .pipe(take(1))
            .subscribe((configs) => {
                const inputs: SetPreloadedConfigurationInput = configs.map(
                    ({ configId, value }) => ({ configId, value } as LensCoreConfig)
                );

                lensCore.setPreloadedConfiguration(inputs);
            });
    }
);
