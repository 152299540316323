import { forActions } from "@snap/state-management";
import { map, mergeMap, take } from "rxjs";
import { getTimeMs } from "../../common/time";
import { Injectable } from "../../dependency-injection/Injectable";
import { TypedCustomEvent } from "../../events/TypedCustomEvent";
import { lensStateFactory, LensState } from "../../session/lensState";
import { MakeTaggedBusinessEvent } from "../businessEventsReporter";
import { MetricsEventTarget, metricsEventTargetFactory } from "../metricsEventTarget";
import {
    operationalMetricReporterFactory,
    OperationalMetricsReporter,
} from "../operational/operationalMetricsReporter";

// We ignore short-duration lens waits.
//
// The value is documented here:
// https://docs.google.com/document/d/1-kSzFWCWw9Qo3D08FR1_cqeHTsUtk9p3p3uOptzWDTY/edit#heading=h.q5liip76r9lt
const viewTimeThresholdSec = 0.1;

/**
 * The LensWait metric measures the time spent downloading the lens content and required assets. It gives an indication
 * of the real UX impact of download latency. If lens content and assets are pre-loaded, the latency measured here
 * should decrease – we measure between the request to apply a lens and when the lens is ready to render.
 *
 * @category Lenses
 * @category Metrics
 */
// This type corresponds to the internal CameraKitLensSpin event, described here:
// https://docs.google.com/document/d/1-kSzFWCWw9Qo3D08FR1_cqeHTsUtk9p3p3uOptzWDTY#heading=h.q5liip76r9lt
export type LensWait = MakeTaggedBusinessEvent<"lensWait">;

/**
 * Each time a lens is applied, we measure the duration until the lens is fully loaded by LensCore. This
 * includes any time spent downloading the lens content and required assets from the lens manifest.
 *
 * The intention of this event is to measure the experienced UX latency between a user requesting a lens and
 * the lens rendering. Of course, the application may call `applyLens` at any time, and may hide/show the
 * rendered result at any time – but this should give us a good baseline for how much UX latency could be seen.
 *
 * @internal
 */
export const reportLensWait = Injectable(
    "reportLensWait",
    [lensStateFactory.token, metricsEventTargetFactory.token, operationalMetricReporterFactory.token] as const,
    (lensState: LensState, metricsEventTarget: MetricsEventTarget, reporter: OperationalMetricsReporter) => {
        lensState.events
            .pipe(
                forActions("applyLens"),
                mergeMap(([a]) => {
                    const lensId = a.data.lens.id;
                    const applyLensStartTime = getTimeMs();
                    return lensState.events.pipe(
                        // We'll measure the time until either the requested lens was rendered, or a new applyLens
                        // request was made (in both cases, we're done waiting for this lens).
                        //
                        // This does have the side-effect that if a user rapidly switches between lenses, we'll record
                        // many low-duration lensWait events that are measuring user behavior instead of system latency.
                        // But this is a good trade-off so that we can capture those long-duration lensWaits that are
                        // terminated by the user trying a different lens.
                        //
                        // (This effect can be mitigated by increasing the viewtimeThresholdSec to ignore low-duration
                        // waits that are likely caused by user behavior).
                        forActions("firstFrameProcessed", "applyLens"),
                        take(1),
                        map((): [number, string] => [(getTimeMs() - applyLensStartTime) / 1000, lensId])
                    );
                })
            )
            .subscribe({
                next: ([viewTimeSec, lensId]) => {
                    if (viewTimeSec < viewTimeThresholdSec) return;

                    const lensWait: LensWait = {
                        name: "lensWait",
                        lensId,
                        viewTimeSec,
                    };
                    metricsEventTarget.dispatchEvent(new TypedCustomEvent("lensWait", lensWait));
                    reporter.timer("lens.apply_lens_latency", viewTimeSec * 1000);
                },
            });
    }
);
