import environment from "./environment.json";
import lensCoreWasm from "./lensCoreWasmVersions.json";
console.info(`SDK: ${environment.PACKAGE_VERSION} \
(${lensCoreWasm.version}/${lensCoreWasm.buildNumber})`);

export { Keyboard, KeyboardEvents } from "./session/LensKeyboard";
export { CameraKitConfiguration, CameraKitBootstrapConfiguration, configurationToken } from "./configuration";
export { getRequiredBootstrapURLs } from "./lens-core-module/loader/lensCoreFactory";
export {
    estimateLensPerformance,
    EstimatedLensPerformance,
    LensPerformanceCluster,
} from "./benchmark/estimateLensPerformanceCluster";
export * from "./bootstrapCameraKit";
export { extensionRequestContext } from "./extensions/extensionRequestContext";
export { LensSource, LensSources, lensSourcesFactory } from "./extensions/LensSources";
export {
    Uri,
    UriRequest,
    UriResponse,
    UriCancelRequest,
    UriHandler,
    UriHandlers,
    uriHandlersFactory,
} from "./extensions/UriHandlers";
export {
    RemoteApiStatus,
    RemoteApiRequest,
    RemoteApiRequestHandler,
    RemoteApiResponse,
    RemoteApiService,
    RemoteApiServices,
    remoteApiServicesFactory,
} from "./extensions/RemoteApiServices";
export { CameraKit, CreateSessionOptions, LensMetricsEvents } from "./CameraKit";
export { LensView } from "./metrics/reporters/reportLensView";
export { LensWait } from "./metrics/reporters/reportLensWait";
export { cameraKitUserAgent, CameraKitUserAgent } from "./common/cameraKitUserAgent";
export { Lens, toPublicLens, Snapcode, Preview } from "./lens/Lens";
export { AssetTiming, LensRepository } from "./lens/LensRepository";
export { LensLaunchParams } from "./lens/LensLaunchParams";
export { AssetLoader } from "./lens/assets/LensAssetRepository";
export { remoteMediaAssetLoaderFactory } from "./lens/assets/remoteMediaAssetLoaderFactory";
export * from "./media-sources/CameraKitSource";
export * from "./media-sources/MediaStreamSource";
export * from "./media-sources/FunctionSource";
export * from "./media-sources/VideoSource";
export * from "./media-sources/ImageSource";
export * from "./session/CameraKitSession";
export { CameraKitSessionEvents, CameraKitSessionEventListener } from "./session/CameraKitSessionEvents";
export { Injectable } from "./dependency-injection/Injectable";
export { PartialContainer } from "./dependency-injection/PartialContainer";
export * from "./transforms";
export * from "./namedErrors";
export { LensPerformanceMeasurement, ComputedFrameMetrics } from "./session/LensPerformanceMeasurement";
export { LensPerformanceMetrics } from "./session/LensPerformanceMetrics";
export { TypedCustomEvent } from "./events/TypedCustomEvent";
export { TypedEventTarget } from "./events/TypedEventTarget";
export { namedError } from "./namedErrors";
export { Any } from "./generated-proto/pb_schema/google/protobuf/any";
