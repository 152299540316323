import Long from "long";
import _m0 from "protobufjs/minimal";
import { RankingData } from "./ranking";
import { Lens } from "./lens";
import { LegalPrompt } from "./legal_prompt";
import { OperationalMetricsBundle } from "./operational_metrics";
import { Any } from "../../google/protobuf/any";
import { ExtensionEventBase } from "./business_events";
export const protobufPackage = "com.snap.camerakit.v3";
export interface GetGroupRequest {
    id: string;
    rankingData: RankingData | undefined;
}
export interface GetGroupResponse {
    id: string;
    lenses: Lens[];
}
export interface GetGroupLensRequest {
    lensId: string;
    groupId: string;
}
export interface GetGroupLensResponse {
    lens: Lens | undefined;
    groupId: string;
}
export interface BatchGetGroupLensRequest {
    getRequests: GetGroupLensRequest[];
}
export interface BatchGetGroupLensResponse {
    getResponses: GetGroupLensResponse[];
}
export interface GetPlaceholderConfigRequest {
}
export interface GetPlaceholderConfigResponse {
    configs: {
        [key: string]: string;
    };
}
export interface GetPlaceholderConfigResponse_ConfigsEntry {
    key: string;
    value: string;
}
export interface GetInitializationConfigRequest {
}
export interface GetInitializationConfigResponse {
    appVendorUuidOptIn: boolean;
    watermarkEnabled: boolean;
    childrenProtectionActRestricted: boolean;
    legalPrompt: LegalPrompt | undefined;
}
export interface SetOperationalMetricsRequest {
    metrics: OperationalMetricsBundle | undefined;
}
export interface SetOperationalMetricsResponse {
}
export interface SetBusinessEventsRequest {
    batchEvents: Any | undefined;
}
export interface SetBusinessEventsResponse {
}
export interface SetExtensionBusinessEventsRequest {
    events: Any[];
    extensionEventBase: ExtensionEventBase | undefined;
}
export interface SetExtensionBusinessEventsResponse {
}
function createBaseGetGroupRequest(): GetGroupRequest {
    return { id: "", rankingData: undefined };
}
export const GetGroupRequest = {
    decode(input: _m0.Reader | Uint8Array, length?: number): GetGroupRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetGroupRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.rankingData = RankingData.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): GetGroupRequest {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            rankingData: isSet(object.rankingData) ? RankingData.fromJSON(object.rankingData) : undefined,
        };
    },
    toJSON(message: GetGroupRequest): unknown {
        const obj: any = {};
        message.id !== undefined && (obj.id = message.id);
        message.rankingData !== undefined &&
            (obj.rankingData = message.rankingData ? RankingData.toJSON(message.rankingData) : undefined);
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<GetGroupRequest>, I>>(object: I): GetGroupRequest {
        const message = createBaseGetGroupRequest();
        message.id = object.id ?? "";
        message.rankingData =
            object.rankingData !== undefined && object.rankingData !== null
                ? RankingData.fromPartial(object.rankingData)
                : undefined;
        return message;
    },
};
function createBaseGetGroupResponse(): GetGroupResponse {
    return { id: "", lenses: [] };
}
export const GetGroupResponse = {
    decode(input: _m0.Reader | Uint8Array, length?: number): GetGroupResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetGroupResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.lenses.push(Lens.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): GetGroupResponse {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            lenses: Array.isArray(object?.lenses) ? object.lenses.map((e: any) => Lens.fromJSON(e)) : [],
        };
    },
    toJSON(message: GetGroupResponse): unknown {
        const obj: any = {};
        message.id !== undefined && (obj.id = message.id);
        if (message.lenses) {
            obj.lenses = message.lenses.map((e) => (e ? Lens.toJSON(e) : undefined));
        }
        else {
            obj.lenses = [];
        }
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<GetGroupResponse>, I>>(object: I): GetGroupResponse {
        const message = createBaseGetGroupResponse();
        message.id = object.id ?? "";
        message.lenses = object.lenses?.map((e) => Lens.fromPartial(e)) || [];
        return message;
    },
};
function createBaseGetGroupLensRequest(): GetGroupLensRequest {
    return { lensId: "", groupId: "" };
}
export const GetGroupLensRequest = {
    decode(input: _m0.Reader | Uint8Array, length?: number): GetGroupLensRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetGroupLensRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.lensId = reader.string();
                    break;
                case 2:
                    message.groupId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): GetGroupLensRequest {
        return {
            lensId: isSet(object.lensId) ? String(object.lensId) : "",
            groupId: isSet(object.groupId) ? String(object.groupId) : "",
        };
    },
    toJSON(message: GetGroupLensRequest): unknown {
        const obj: any = {};
        message.lensId !== undefined && (obj.lensId = message.lensId);
        message.groupId !== undefined && (obj.groupId = message.groupId);
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<GetGroupLensRequest>, I>>(object: I): GetGroupLensRequest {
        const message = createBaseGetGroupLensRequest();
        message.lensId = object.lensId ?? "";
        message.groupId = object.groupId ?? "";
        return message;
    },
};
function createBaseGetGroupLensResponse(): GetGroupLensResponse {
    return { lens: undefined, groupId: "" };
}
export const GetGroupLensResponse = {
    decode(input: _m0.Reader | Uint8Array, length?: number): GetGroupLensResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetGroupLensResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.lens = Lens.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.groupId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): GetGroupLensResponse {
        return {
            lens: isSet(object.lens) ? Lens.fromJSON(object.lens) : undefined,
            groupId: isSet(object.groupId) ? String(object.groupId) : "",
        };
    },
    toJSON(message: GetGroupLensResponse): unknown {
        const obj: any = {};
        message.lens !== undefined && (obj.lens = message.lens ? Lens.toJSON(message.lens) : undefined);
        message.groupId !== undefined && (obj.groupId = message.groupId);
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<GetGroupLensResponse>, I>>(object: I): GetGroupLensResponse {
        const message = createBaseGetGroupLensResponse();
        message.lens = object.lens !== undefined && object.lens !== null ? Lens.fromPartial(object.lens) : undefined;
        message.groupId = object.groupId ?? "";
        return message;
    },
};
function createBaseBatchGetGroupLensRequest(): BatchGetGroupLensRequest {
    return { getRequests: [] };
}
export const BatchGetGroupLensRequest = {
    decode(input: _m0.Reader | Uint8Array, length?: number): BatchGetGroupLensRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBatchGetGroupLensRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.getRequests.push(GetGroupLensRequest.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): BatchGetGroupLensRequest {
        return {
            getRequests: Array.isArray(object?.getRequests)
                ? object.getRequests.map((e: any) => GetGroupLensRequest.fromJSON(e))
                : [],
        };
    },
    toJSON(message: BatchGetGroupLensRequest): unknown {
        const obj: any = {};
        if (message.getRequests) {
            obj.getRequests = message.getRequests.map((e) => (e ? GetGroupLensRequest.toJSON(e) : undefined));
        }
        else {
            obj.getRequests = [];
        }
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<BatchGetGroupLensRequest>, I>>(object: I): BatchGetGroupLensRequest {
        const message = createBaseBatchGetGroupLensRequest();
        message.getRequests = object.getRequests?.map((e) => GetGroupLensRequest.fromPartial(e)) || [];
        return message;
    },
};
function createBaseBatchGetGroupLensResponse(): BatchGetGroupLensResponse {
    return { getResponses: [] };
}
export const BatchGetGroupLensResponse = {
    decode(input: _m0.Reader | Uint8Array, length?: number): BatchGetGroupLensResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBatchGetGroupLensResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.getResponses.push(GetGroupLensResponse.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): BatchGetGroupLensResponse {
        return {
            getResponses: Array.isArray(object?.getResponses)
                ? object.getResponses.map((e: any) => GetGroupLensResponse.fromJSON(e))
                : [],
        };
    },
    toJSON(message: BatchGetGroupLensResponse): unknown {
        const obj: any = {};
        if (message.getResponses) {
            obj.getResponses = message.getResponses.map((e) => (e ? GetGroupLensResponse.toJSON(e) : undefined));
        }
        else {
            obj.getResponses = [];
        }
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<BatchGetGroupLensResponse>, I>>(object: I): BatchGetGroupLensResponse {
        const message = createBaseBatchGetGroupLensResponse();
        message.getResponses = object.getResponses?.map((e) => GetGroupLensResponse.fromPartial(e)) || [];
        return message;
    },
};
function createBaseGetPlaceholderConfigRequest(): GetPlaceholderConfigRequest {
    return {};
}
export const GetPlaceholderConfigRequest = {
    decode(input: _m0.Reader | Uint8Array, length?: number): GetPlaceholderConfigRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetPlaceholderConfigRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_: any): GetPlaceholderConfigRequest {
        return {};
    },
    toJSON(_: GetPlaceholderConfigRequest): unknown {
        const obj: any = {};
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<GetPlaceholderConfigRequest>, I>>(_: I): GetPlaceholderConfigRequest {
        const message = createBaseGetPlaceholderConfigRequest();
        return message;
    },
};
function createBaseGetPlaceholderConfigResponse(): GetPlaceholderConfigResponse {
    return { configs: {} };
}
export const GetPlaceholderConfigResponse = {
    decode(input: _m0.Reader | Uint8Array, length?: number): GetPlaceholderConfigResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetPlaceholderConfigResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    const entry1 = GetPlaceholderConfigResponse_ConfigsEntry.decode(reader, reader.uint32());
                    if (entry1.value !== undefined) {
                        message.configs[entry1.key] = entry1.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): GetPlaceholderConfigResponse {
        return {
            configs: isObject(object.configs)
                ? Object.entries(object.configs).reduce<{
                    [key: string]: string;
                }>((acc, [key, value]) => {
                    acc[key] = String(value);
                    return acc;
                }, {})
                : {},
        };
    },
    toJSON(message: GetPlaceholderConfigResponse): unknown {
        const obj: any = {};
        obj.configs = {};
        if (message.configs) {
            Object.entries(message.configs).forEach(([k, v]) => {
                obj.configs[k] = v;
            });
        }
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<GetPlaceholderConfigResponse>, I>>(object: I): GetPlaceholderConfigResponse {
        const message = createBaseGetPlaceholderConfigResponse();
        message.configs = Object.entries(object.configs ?? {}).reduce<{
            [key: string]: string;
        }>((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = String(value);
            }
            return acc;
        }, {});
        return message;
    },
};
function createBaseGetPlaceholderConfigResponse_ConfigsEntry(): GetPlaceholderConfigResponse_ConfigsEntry {
    return { key: "", value: "" };
}
export const GetPlaceholderConfigResponse_ConfigsEntry = {
    decode(input: _m0.Reader | Uint8Array, length?: number): GetPlaceholderConfigResponse_ConfigsEntry {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetPlaceholderConfigResponse_ConfigsEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): GetPlaceholderConfigResponse_ConfigsEntry {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value) ? String(object.value) : "",
        };
    },
    toJSON(message: GetPlaceholderConfigResponse_ConfigsEntry): unknown {
        const obj: any = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<GetPlaceholderConfigResponse_ConfigsEntry>, I>>(object: I): GetPlaceholderConfigResponse_ConfigsEntry {
        const message = createBaseGetPlaceholderConfigResponse_ConfigsEntry();
        message.key = object.key ?? "";
        message.value = object.value ?? "";
        return message;
    },
};
function createBaseGetInitializationConfigRequest(): GetInitializationConfigRequest {
    return {};
}
export const GetInitializationConfigRequest = {
    decode(input: _m0.Reader | Uint8Array, length?: number): GetInitializationConfigRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetInitializationConfigRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_: any): GetInitializationConfigRequest {
        return {};
    },
    toJSON(_: GetInitializationConfigRequest): unknown {
        const obj: any = {};
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<GetInitializationConfigRequest>, I>>(_: I): GetInitializationConfigRequest {
        const message = createBaseGetInitializationConfigRequest();
        return message;
    },
};
function createBaseGetInitializationConfigResponse(): GetInitializationConfigResponse {
    return {
        appVendorUuidOptIn: false,
        watermarkEnabled: false,
        childrenProtectionActRestricted: false,
        legalPrompt: undefined,
    };
}
export const GetInitializationConfigResponse = {
    decode(input: _m0.Reader | Uint8Array, length?: number): GetInitializationConfigResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetInitializationConfigResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.appVendorUuidOptIn = reader.bool();
                    break;
                case 2:
                    message.watermarkEnabled = reader.bool();
                    break;
                case 3:
                    message.childrenProtectionActRestricted = reader.bool();
                    break;
                case 4:
                    message.legalPrompt = LegalPrompt.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): GetInitializationConfigResponse {
        return {
            appVendorUuidOptIn: isSet(object.appVendorUuidOptIn) ? Boolean(object.appVendorUuidOptIn) : false,
            watermarkEnabled: isSet(object.watermarkEnabled) ? Boolean(object.watermarkEnabled) : false,
            childrenProtectionActRestricted: isSet(object.childrenProtectionActRestricted)
                ? Boolean(object.childrenProtectionActRestricted)
                : false,
            legalPrompt: isSet(object.legalPrompt) ? LegalPrompt.fromJSON(object.legalPrompt) : undefined,
        };
    },
    toJSON(message: GetInitializationConfigResponse): unknown {
        const obj: any = {};
        message.appVendorUuidOptIn !== undefined && (obj.appVendorUuidOptIn = message.appVendorUuidOptIn);
        message.watermarkEnabled !== undefined && (obj.watermarkEnabled = message.watermarkEnabled);
        message.childrenProtectionActRestricted !== undefined &&
            (obj.childrenProtectionActRestricted = message.childrenProtectionActRestricted);
        message.legalPrompt !== undefined &&
            (obj.legalPrompt = message.legalPrompt ? LegalPrompt.toJSON(message.legalPrompt) : undefined);
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<GetInitializationConfigResponse>, I>>(object: I): GetInitializationConfigResponse {
        const message = createBaseGetInitializationConfigResponse();
        message.appVendorUuidOptIn = object.appVendorUuidOptIn ?? false;
        message.watermarkEnabled = object.watermarkEnabled ?? false;
        message.childrenProtectionActRestricted = object.childrenProtectionActRestricted ?? false;
        message.legalPrompt =
            object.legalPrompt !== undefined && object.legalPrompt !== null
                ? LegalPrompt.fromPartial(object.legalPrompt)
                : undefined;
        return message;
    },
};
function createBaseSetOperationalMetricsRequest(): SetOperationalMetricsRequest {
    return { metrics: undefined };
}
export const SetOperationalMetricsRequest = {
    decode(input: _m0.Reader | Uint8Array, length?: number): SetOperationalMetricsRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetOperationalMetricsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.metrics = OperationalMetricsBundle.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): SetOperationalMetricsRequest {
        return {
            metrics: isSet(object.metrics) ? OperationalMetricsBundle.fromJSON(object.metrics) : undefined,
        };
    },
    toJSON(message: SetOperationalMetricsRequest): unknown {
        const obj: any = {};
        message.metrics !== undefined &&
            (obj.metrics = message.metrics ? OperationalMetricsBundle.toJSON(message.metrics) : undefined);
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<SetOperationalMetricsRequest>, I>>(object: I): SetOperationalMetricsRequest {
        const message = createBaseSetOperationalMetricsRequest();
        message.metrics =
            object.metrics !== undefined && object.metrics !== null
                ? OperationalMetricsBundle.fromPartial(object.metrics)
                : undefined;
        return message;
    },
};
function createBaseSetOperationalMetricsResponse(): SetOperationalMetricsResponse {
    return {};
}
export const SetOperationalMetricsResponse = {
    decode(input: _m0.Reader | Uint8Array, length?: number): SetOperationalMetricsResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetOperationalMetricsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_: any): SetOperationalMetricsResponse {
        return {};
    },
    toJSON(_: SetOperationalMetricsResponse): unknown {
        const obj: any = {};
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<SetOperationalMetricsResponse>, I>>(_: I): SetOperationalMetricsResponse {
        const message = createBaseSetOperationalMetricsResponse();
        return message;
    },
};
function createBaseSetBusinessEventsRequest(): SetBusinessEventsRequest {
    return { batchEvents: undefined };
}
export const SetBusinessEventsRequest = {
    decode(input: _m0.Reader | Uint8Array, length?: number): SetBusinessEventsRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetBusinessEventsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.batchEvents = Any.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): SetBusinessEventsRequest {
        return {
            batchEvents: isSet(object.batchEvents) ? Any.fromJSON(object.batchEvents) : undefined,
        };
    },
    toJSON(message: SetBusinessEventsRequest): unknown {
        const obj: any = {};
        message.batchEvents !== undefined &&
            (obj.batchEvents = message.batchEvents ? Any.toJSON(message.batchEvents) : undefined);
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<SetBusinessEventsRequest>, I>>(object: I): SetBusinessEventsRequest {
        const message = createBaseSetBusinessEventsRequest();
        message.batchEvents =
            object.batchEvents !== undefined && object.batchEvents !== null ? Any.fromPartial(object.batchEvents) : undefined;
        return message;
    },
};
function createBaseSetBusinessEventsResponse(): SetBusinessEventsResponse {
    return {};
}
export const SetBusinessEventsResponse = {
    decode(input: _m0.Reader | Uint8Array, length?: number): SetBusinessEventsResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetBusinessEventsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_: any): SetBusinessEventsResponse {
        return {};
    },
    toJSON(_: SetBusinessEventsResponse): unknown {
        const obj: any = {};
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<SetBusinessEventsResponse>, I>>(_: I): SetBusinessEventsResponse {
        const message = createBaseSetBusinessEventsResponse();
        return message;
    },
};
function createBaseSetExtensionBusinessEventsRequest(): SetExtensionBusinessEventsRequest {
    return { events: [], extensionEventBase: undefined };
}
export const SetExtensionBusinessEventsRequest = {
    decode(input: _m0.Reader | Uint8Array, length?: number): SetExtensionBusinessEventsRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetExtensionBusinessEventsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.events.push(Any.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.extensionEventBase = ExtensionEventBase.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): SetExtensionBusinessEventsRequest {
        return {
            events: Array.isArray(object?.events) ? object.events.map((e: any) => Any.fromJSON(e)) : [],
            extensionEventBase: isSet(object.extensionEventBase)
                ? ExtensionEventBase.fromJSON(object.extensionEventBase)
                : undefined,
        };
    },
    toJSON(message: SetExtensionBusinessEventsRequest): unknown {
        const obj: any = {};
        if (message.events) {
            obj.events = message.events.map((e) => (e ? Any.toJSON(e) : undefined));
        }
        else {
            obj.events = [];
        }
        message.extensionEventBase !== undefined &&
            (obj.extensionEventBase = message.extensionEventBase
                ? ExtensionEventBase.toJSON(message.extensionEventBase)
                : undefined);
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<SetExtensionBusinessEventsRequest>, I>>(object: I): SetExtensionBusinessEventsRequest {
        const message = createBaseSetExtensionBusinessEventsRequest();
        message.events = object.events?.map((e) => Any.fromPartial(e)) || [];
        message.extensionEventBase =
            object.extensionEventBase !== undefined && object.extensionEventBase !== null
                ? ExtensionEventBase.fromPartial(object.extensionEventBase)
                : undefined;
        return message;
    },
};
function createBaseSetExtensionBusinessEventsResponse(): SetExtensionBusinessEventsResponse {
    return {};
}
export const SetExtensionBusinessEventsResponse = {
    decode(input: _m0.Reader | Uint8Array, length?: number): SetExtensionBusinessEventsResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetExtensionBusinessEventsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(_: any): SetExtensionBusinessEventsResponse {
        return {};
    },
    toJSON(_: SetExtensionBusinessEventsResponse): unknown {
        const obj: any = {};
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<SetExtensionBusinessEventsResponse>, I>>(_: I): SetExtensionBusinessEventsResponse {
        const message = createBaseSetExtensionBusinessEventsResponse();
        return message;
    },
};
export interface Lenses {
    GetGroup(request: GetGroupRequest): Promise<GetGroupResponse>;
    GetGroupLens(request: GetGroupLensRequest): Promise<GetGroupLensResponse>;
    BatchGetGroupLens(request: BatchGetGroupLensRequest): Promise<BatchGetGroupLensResponse>;
    GetPlaceholderConfig(request: GetPlaceholderConfigRequest): Promise<GetPlaceholderConfigResponse>;
}
export interface Metrics {
    SetOperationalMetrics(request: SetOperationalMetricsRequest): Promise<SetOperationalMetricsResponse>;
    SetBusinessEvents(request: SetBusinessEventsRequest): Promise<SetBusinessEventsResponse>;
    SetExtensionBusinessEvents(request: SetExtensionBusinessEventsRequest): Promise<SetExtensionBusinessEventsResponse>;
    GetInitializationConfig(request: GetInitializationConfigRequest): Promise<GetInitializationConfigResponse>;
}
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
function isObject(value: any): boolean {
    return typeof value === "object" && value !== null;
}
function isSet(value: any): boolean {
    return value !== null && value !== undefined;
}
