/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "com.snap.camerakit.v3.features";

/**
 * Ranking feature metadata.
 * https://docs.google.com/document/d/1vKDLVpnblnUZHhNbFGGzUf64nmdkO6NGyarq8q9nPE8/edit#heading=h.2yfm0j3kldwv
 */
export interface RankingInfo {
  /** Ranking request id can be reported by clients along with lens usage to build ranking models. */
  rankingRequestId: string;
}

function createBaseRankingInfo(): RankingInfo {
  return { rankingRequestId: "" };
}

export const RankingInfo = {
  encode(message: RankingInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rankingRequestId !== "") {
      writer.uint32(10).string(message.rankingRequestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RankingInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRankingInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.rankingRequestId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial<I extends Exact<DeepPartial<RankingInfo>, I>>(object: I): RankingInfo {
    const message = createBaseRankingInfo();
    message.rankingRequestId = object.rankingRequestId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string }
  ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
