export const locale = navigator.language;

// Below adds qvalue to languages as per a backend issue:
// https://github.sc-corp.net/Snapchat/phantom/pull/196781
// More on qvalues: https://developer.mozilla.org/en-US/docs/Glossary/Quality_values
export const fullLocale = navigator.languages
    .map((lang, index) => {
        const qvalue = Math.max(0, (10 - index) / 10);
        return `${lang};q=${qvalue.toFixed(1)}`;
    })
    .join(", ");
