import { cameraKitUserAgent } from "../common/cameraKitUserAgent";
import { CameraKitConfiguration, configurationToken } from "../configuration";
import { Injectable } from "../dependency-injection/Injectable";
import { defaultFetchHandlerFactory, FetchHandler } from "./defaultFetchHandler";
import { HandlerChainBuilder } from "./HandlerChainBuilder";
import { createHeadersModifyingFetchHandler } from "./headersModifyingFetchHandler";

/**
 * A Fetch implementation which adds headers required to make authenticated calls to the CameraKit backend service.
 *
 * @internal
 */
export const cameraKitServiceFetchHandlerFactory = Injectable(
    "cameraKitServiceFetchHandler",
    [configurationToken, defaultFetchHandlerFactory.token] as const,
    ({ apiToken }: CameraKitConfiguration, defaultFetchHandler: FetchHandler) => {
        return new HandlerChainBuilder(defaultFetchHandler).map(
            createHeadersModifyingFetchHandler((headers) => {
                headers.append("x-snap-client-user-agent", cameraKitUserAgent.userAgent);
                headers.append("authorization", `Bearer ${apiToken}`);
                return headers;
            })
        ).handler;
    }
);
