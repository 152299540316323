import { isState } from "@snap/state-management";
import { filter, map, Observable, scan, Subject, takeUntil } from "rxjs";
import { entries } from "../../common/entries";
import { stringifyError } from "../../common/errorHelpers";
import { Injectable } from "../../dependency-injection/Injectable";
import { TypedCustomEvent } from "../../events/TypedCustomEvent";
import { logEntriesFactory } from "../../logger/logEntries";
import { LogEntry, logLevelMap } from "../../logger/logger";
import { LensState } from "../../session/lensState";
import { MetricsEventTarget, metricsEventTargetFactory } from "../metricsEventTarget";
import {
    operationalMetricReporterFactory,
    OperationalMetricsReporter,
} from "../operational/operationalMetricsReporter";

const logMethods = entries(logLevelMap).map(([level]) => level);

// How many log entries to include as the error context
const maxBufferedEntries = 15;
const contextSeparator = "\n\n----------------- Context -----------------\n\n";
const methodLength = logMethods.reduce((max, method) => Math.max(max, method.length), 0);

interface EntriesBuffer {
    entries: LogEntry[];
    recent: LogEntry;
}

function getContextString(logEntry: LogEntry[]) {
    const result = [];
    for (const entry of logEntry) {
        const time = entry.time.toISOString();
        const method = entry.level.padStart(methodLength);
        // TODO: improve pretty printing
        const messages = entry.messages.map((m) => m + "").join(" ");
        result.push(`${time} [${entry.module}] ${method}: ${messages}`);
    }
    return result.join("\n");
}

export function reportExceptionToBlizzard(
    logEntries: Observable<LogEntry>,
    metricsEventTarget: MetricsEventTarget,
    reporter: OperationalMetricsReporter,
    lensState?: LensState
) {
    logEntries
        .pipe(
            scan<LogEntry, EntriesBuffer>(
                (acc, recent) => ({
                    entries: [...acc.entries, recent].slice(-maxBufferedEntries),
                    recent,
                }),
                // Start with a dummy recent entry -- it gets overridden each time we handle a log entry.
                { entries: [], recent: { time: new Date(), module: "any", level: "debug", messages: [] } }
            ),
            filter(({ recent }) => recent.level === "error"),
            map(({ entries, recent }) => ({
                context: entries,
                error: recent.messages.find((e) => e instanceof Error) as Error,
            })),
            filter(({ error }) => !!error)
        )
        .subscribe(({ error, context }) => {
            const currentLensState = lensState?.getState();
            const lensId =
                currentLensState && !isState(currentLensState, "noLensApplied") ? currentLensState.data.id : "none";
            metricsEventTarget.dispatchEvent(
                new TypedCustomEvent("exception", {
                    name: "exception",
                    lensId,
                    type: error.name,
                    reason: `${stringifyError(error)}${contextSeparator}${getContextString(context)}`,
                })
            );
            reporter.count("handled_exception", 1, new Map([["type", error.name]]));
        });
}

export interface GlobalExceptionReporter {
    attachLensContext: (lensState: LensState) => void;
}

/**
 * Reports log entries to Blizzard when there is no CameraKit session yet.
 *
 * @internal
 */
export const reportGlobalException = Injectable(
    "reportGlobalException",
    [logEntriesFactory.token, metricsEventTargetFactory.token, operationalMetricReporterFactory.token] as const,
    (
        logEntries: Observable<LogEntry>,
        metricsEventTarget: MetricsEventTarget,
        reporter: OperationalMetricsReporter
    ): GlobalExceptionReporter => {
        // Initially we log exceptions without any lens context
        const cancellationSubject = new Subject<void>();
        reportExceptionToBlizzard(logEntries.pipe(takeUntil(cancellationSubject)), metricsEventTarget, reporter);

        // Later session scope reporter triggers cancellation of the global one
        // and initiates exception reporting with a lens context
        return {
            attachLensContext: (lensState: LensState) => {
                cancellationSubject.next();
                reportExceptionToBlizzard(logEntries, metricsEventTarget, reporter, lensState);
            },
        };
    }
);
