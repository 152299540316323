import { v4 } from "uuid";
import { catchError, from, of, switchMap, take } from "rxjs";
import { ConnectionType, cameraKitUserAgent as userAgent } from "../common/cameraKitUserAgent";
import { entries } from "../common/entries";
import { Injectable } from "../dependency-injection/Injectable";
import { EventOfType } from "../events/TypedCustomEvent";
import { EventsFromTarget } from "../events/TypedEventTarget";
import { FetchHandler } from "../handlers/defaultFetchHandler";
import { createBatchingHandler } from "../handlers/batchingHandler";
import { HandlerChainBuilder } from "../handlers/HandlerChainBuilder";
import { createMappingHandler } from "../handlers/mappingHandler";
import * as blizzard from "../generated-proto/blizzard/cameraKitEvents";
import { getLogger } from "../logger/logger";
import { PageVisibility, pageVisibilityFactory } from "../common/pageVisibility";
import { CameraKitApiHostname, CameraKitConfiguration, configurationToken } from "../configuration";
import { remoteConfigurationFactory, RemoteConfiguration } from "../remote-configuration/remoteConfiguration";
import { IndexedDBPersistence } from "../persistence/IndexedDBPersistence";
import { ExpiringPersistence } from "../persistence/ExpiringPersistence";
import { convertDaysToSeconds } from "../common/time";
import { metricsHandlerFactory } from "./metricsHandler";
import { MetricsEventTarget, metricsEventTargetFactory } from "./metricsEventTarget";

const logger = getLogger("BusinessEventsReporter");

type Nullables<T> = { [K in keyof T]-?: undefined extends T[K] ? K : never }[keyof T];
type UndefinedToOptional<T> = Partial<Pick<T, Nullables<T>>> & Omit<T, Nullables<T>>;

type CameraKitBusinessEvents = EventsFromTarget<MetricsEventTarget>["detail"];

type MakeBlizzardEvent<E> = Omit<E, "name"> & { cameraKitEventBase: blizzard.CameraKitEventBase };

type CreateEventData<EventType extends EventsFromTarget<MetricsEventTarget>["type"]> = (
    event: MakeBlizzardEvent<EventOfType<EventType, EventsFromTarget<MetricsEventTarget>>["detail"]>
) => [string, UndefinedToOptional<blizzard.ServerEventData>];

type EventHandlers = {
    [EventType in EventsFromTarget<MetricsEventTarget>["type"]]: CreateEventData<EventType>;
};

/**
 * Translate between an external metric name, which is exposed to SDK users, and an internal Blizzard event name,
 * property name, and constructor.
 *
 * It is very important that we do this, since the naming of these internal business events are unintuitive and will
 * not make sense to SDK users.
 *
 * For a full list of business events (using their internal names), see:
 * https://docs.google.com/document/d/1-kSzFWCWw9Qo3D08FR1_cqeHTsUtk9p3p3uOptzWDTY/
 */
type CameraKitBusinessEventMap = {
    assetDownload: MakeBusinessEvent<blizzard.CameraKitAssetDownload>;
    assetValidationFailed: MakeBusinessEvent<blizzard.CameraKitAssetValidationFailed>;
    benchmarkComplete: MakeBusinessEvent<blizzard.CameraKitWebBenchmarkComplete>;
    exception: MakeBusinessEvent<blizzard.CameraKitException>;
    legalPrompt: MakeBusinessEvent<blizzard.CameraKitLegalPrompt>;
    lensDownload: MakeBusinessEvent<blizzard.CameraKitLensDownload>;
    lensView: MakeBusinessEvent<blizzard.CameraKitWebLensSwipe>;
    lensWait: MakeBusinessEvent<blizzard.CameraKitLensSpin>;
    lensContentValidationFailed: MakeBusinessEvent<blizzard.CameraKitLensContentValidationFailed>;
    session: MakeBusinessEvent<blizzard.CameraKitSession>;
};

/**
 * This interface is defined in the CameraKit pb_schema proto definition – but the generated TypeScript does not handle
 * the `Any` type properly. It does not conform to the Proto3 canonical JSON mapping scheme, as defined here:
 * https://developers.google.com/protocol-buffers/docs/proto3#json
 *
 * To solve this (since we only need one message from the CameraKit schema), we'll just manually define the correct
 * interface here.
 */
interface SetBusinessEventsRequest {
    batchEvents: {
        "@type": "com.snapchat.analytics.blizzard.ServerEventBatch";
        serverEvents: blizzard.ServerEvent[];
    };
}

// CameraKit's prod metrics endpoint.
// See: https://github.sc-corp.net/Snapchat/pb_schema/blob/2a966db/proto/camera_kit/v3/service.proto#L133
const relativePath = "/com.snap.camerakit.v3.Metrics/metrics/business_events";

// It is rather cumbersome to check the actual final size of a batch, but we can easily limit the number of events we
// include in each batch -- looking at historical data, typical events average ~1.3kb per event. But there are some
// events (like CAMERA_KIT_EXCEPTION, which includes a stack trace) that can be much larger.
//
// To prevent us running over the 64kibibyte limit imposed by browsers on `keep-alive` requests, we'll set quite a low
// limit to ensure we don't lose events which are larger in size than we expect.
const BUSINESS_EVENT_BATCH_MAX_SIZE = 10;
const BUSINESS_EVENT_BATCH_MAX_AGE_MS = 5000;

const connectivityTypeMapping: Partial<Record<ConnectionType, blizzard.CameraKitConnectivityType>> = {
    cellular: blizzard.CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_MOBILE,
    bluetooth: blizzard.CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_BLUETOOTH,
    wifi: blizzard.CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_WIFI,
    unknown: blizzard.CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_UNKNOWN,
    none: blizzard.CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_UNREACHABLE,
};

const vendorUuidKey = "vendorUuid";
const vendorUuidExpiry = convertDaysToSeconds(60);

/**
 * Retrieves or generates a vendor UUID (Universally Unique Identifier).
 *
 * @param persistence - The persistence storage interface where UUID is stored.
 * @returns {Promise<string | undefined>} - A Promise that resolves to the vendor UUID or undefined,
 * if any failure occurs or opt-in is not enabled.
 */
const getOrGenerateVendorUuid = async (persistence: ExpiringPersistence<string>): Promise<string | undefined> => {
    try {
        const storedUuid = await persistence.retrieve(vendorUuidKey);
        if (storedUuid) {
            return storedUuid;
        }

        const newUuid = v4();
        await persistence.store(vendorUuidKey, newUuid);

        return newUuid;
    } catch (error) {
        throw new Error("Failed to generate vendor UUID");
    }
};

function listenAndReport(
    metricsEventTarget: MetricsEventTarget,
    metricsHandler: FetchHandler,
    pageVisibility: PageVisibility,
    eventHandlers: EventHandlers,
    apiHostname: CameraKitApiHostname,
    appVendorUuid: string | undefined
): void {
    const sessionId = v4();
    logger.log(`Session ID: ${sessionId}`);

    // Blizzard convention is to start the sequenceId at 1.
    let sequenceId = 1;

    const handler = new HandlerChainBuilder(metricsHandler)
        .map(
            createMappingHandler((events: blizzard.ServerEvent[]) => {
                const body: SetBusinessEventsRequest = {
                    batchEvents: {
                        "@type": "com.snapchat.analytics.blizzard.ServerEventBatch",
                        serverEvents: events,
                    },
                };

                return new Request(`https://${apiHostname}${relativePath}`, {
                    method: "POST",
                    body: JSON.stringify(body),
                    credentials: "include",

                    // When this is true it makes fetch behave like `Navigator.sendBeacon` – that is, the request
                    // will still be made even if the page terminates.
                    // https://developer.mozilla.org/en-US/docs/Web/API/fetch
                    keepalive: pageVisibility.isDuringVisibilityTransition("hidden"),
                });
            }, pageVisibility)
        )
        .map(
            createBatchingHandler({
                batchReduce: (
                    previousBundle: blizzard.ServerEvent[] | undefined,
                    businessEvent: blizzard.ServerEvent
                ) => {
                    const bundle = previousBundle ?? [];
                    bundle.push(businessEvent);
                    return bundle;
                },
                isBatchComplete: (bundle) => bundle.length >= BUSINESS_EVENT_BATCH_MAX_SIZE,
                maxBatchAge: BUSINESS_EVENT_BATCH_MAX_AGE_MS,
                pageVisibility,
            })
        ).handler;

    const makeBlizzardEvent = <E extends CameraKitBusinessEvents>(event: E): MakeBlizzardEvent<E> => {
        const deviceConnectivity =
            connectivityTypeMapping[userAgent.connectionType!] ??
            blizzard.CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_UNKNOWN;

        return {
            ...event,
            cameraKitEventBase: blizzard.CameraKitEventBase.fromPartial({
                kitEventBase: blizzard.KitEventBase.fromPartial({
                    locale: userAgent.locale,
                    kitVariant: blizzard.KitType.CAMERA_KIT_WEB,
                    kitVariantVersion: userAgent.sdkShortVersion,
                    kitClientTimestampMillis: Date.now(),
                }),
                deviceCluster: 0,
                cameraKitVersion: userAgent.sdkLongVersion,
                lensCoreVersion: userAgent.lensCoreVersion,
                deviceModel: userAgent.deviceModel,
                cameraKitVariant: blizzard.CameraKitVariant.CAMERA_KIT_VARIANT_PARTNER,
                cameraKitFlavor: blizzard.CameraKitFlavor.CAMERA_KIT_FLAVOR_DEBUG,
                // We overload appId, using the origin instead because it's nice and human-readable (our backed adds
                // the true appId as oauth_client_id before forwarding events to Blizzard).
                appId: userAgent.origin,
                deviceConnectivity,
                sessionId: sessionId,
                appVendorUuid,
            }),
        };
    };

    const sendServerEvent = (
        eventName: string,
        eventData: UndefinedToOptional<blizzard.ServerEventData>
    ): Promise<void> => {
        return handler(
            blizzard.ServerEvent.fromPartial({
                eventName,
                osType: userAgent.osType,
                osVersion: userAgent.osVersion,
                maxSequenceIdOnInstance: 0,
                sequenceId: sequenceId++,
                eventData,
            })
        );
    };

    entries(eventHandlers).forEach(([eventType, createEventData]) => {
        metricsEventTarget.addEventListener(eventType, ({ detail }) => {
            // Safety: When iterating over object keys in a mapped type, we lose the association between the key type
            // and the value type – at each iteration, the key type is a union of all possible keys and the value type
            // is a union of all possible values. When the value is a function with an argument, and that argument
            // depends on the key type (which is a union), the contravariance of the argument type means that the union
            // becomes an intersection. In our case here, this means the compiler expects each argument to contain all
            // properties from all event types. The cast is safe because the mapped `EventHandlers` type ensures that
            // `createEventData` takes an argument of the type corresponding its key's `eventType`'s event detail.
            const [eventName, eventData] = createEventData(makeBlizzardEvent(detail) as any);
            sendServerEvent(eventName, eventData);
        });
    });
}

export type MakeBusinessEvent<E> = Omit<
    {
        [K in keyof E]: Exclude<E[K], undefined> extends Record<keyof any, any>
            ? MakeBusinessEvent<Exclude<E[K], undefined>>
            : E[K];
    },
    "cameraKitEventBase"
>;

export type MakeTaggedBusinessEvent<K extends keyof CameraKitBusinessEventMap> = {
    name: K;
} & CameraKitBusinessEventMap[K];

export const businessEventsReporterFactory = Injectable(
    "businessEventsReporter",
    [
        metricsEventTargetFactory.token,
        metricsHandlerFactory.token,
        pageVisibilityFactory.token,
        configurationToken,
        remoteConfigurationFactory.token,
    ] as const,
    (
        metricsEventTarget: MetricsEventTarget,
        metricsHandler: FetchHandler,
        pageVisibility: PageVisibility,
        configuration: CameraKitConfiguration,
        remoteConfiguration: RemoteConfiguration
    ) => {
        const vendorAnalyticsPersistence = new ExpiringPersistence<string>(
            () => vendorUuidExpiry,
            new IndexedDBPersistence({ databaseName: "SessionHistory" })
        );

        remoteConfiguration
            .getInitializationConfig()
            .pipe(
                take(1),
                switchMap(({ appVendorUuidOptIn }) => {
                    if (appVendorUuidOptIn) {
                        return from(getOrGenerateVendorUuid(vendorAnalyticsPersistence));
                    }
                    return of(undefined);
                }),
                catchError((error) => {
                    logger.warn(`Failed to retrieve or generate vendor UUID.`, error);
                    return of(undefined);
                })
            )
            .subscribe({
                next: (appVendorUuid) => {
                    /**
                     * This defines a mapping from a business event's external name (the name we document in public
                     * API docs), to its internal representation as a Blizzard ServerEvent.
                     *
                     * It is important that we do this, since the naming of these internal business events are
                     * unintuitive and will not make sense to SDK users.
                     *
                     * To specify the internal event, we must give the ServerEvent's eventName, the specific property
                     *  name which contains the event data (this is a "oneof" property on ServerEvent), and use the
                     * correct event type's `fromPartial` method (this is generated from the ServerEvent protobuf).
                     *
                     * These events are documented here:
                     * https://docs.google.com/document/d/1-kSzFWCWw9Qo3D08FR1_cqeHTsUtk9p3p3uOptzWDTY/
                     *
                     * They are defined in code here:
                     * https://github.sc-corp.net/Snapchat/snapchat/tree/master/blizzard/schema/blizzard-schema/
                     *  codeGen/src/main/java/com/snapchat/analytics/schema/events/cameraKit
                     */
                    listenAndReport(
                        metricsEventTarget,
                        metricsHandler,
                        pageVisibility,
                        {
                            assetDownload: (event) => [
                                "CAMERA_KIT_ASSET_DOWNLOAD",
                                { cameraKitAssetDownload: blizzard.CameraKitAssetDownload.fromPartial(event) },
                            ],
                            assetValidationFailed: (event) => [
                                "CAMERA_KIT_ASSET_VALIDATION_FAILED",
                                {
                                    cameraKitAssetValidationFailed:
                                        blizzard.CameraKitAssetValidationFailed.fromPartial(event),
                                },
                            ],
                            benchmarkComplete: (event) => [
                                "CAMERA_KIT_WEB_BENCHMARK_COMPLETE",
                                {
                                    cameraKitWebBenchmarkComplete:
                                        blizzard.CameraKitWebBenchmarkComplete.fromPartial(event),
                                },
                            ],
                            exception: (event) => [
                                "CAMERA_KIT_EXCEPTION",
                                { cameraKitException: blizzard.CameraKitException.fromPartial(event) },
                            ],
                            legalPrompt: (event) => [
                                "CAMERA_KIT_LEGAL_PROMPT",
                                { cameraKitLegalPrompt: blizzard.CameraKitLegalPrompt.fromPartial(event) },
                            ],
                            lensDownload: (event) => [
                                "CAMERA_KIT_LENS_DOWNLOAD",
                                { cameraKitLensDownload: blizzard.CameraKitLensDownload.fromPartial(event) },
                            ],
                            lensView: (event) => [
                                "CAMERA_KIT_WEB_LENS_SWIPE",
                                { cameraKitWebLensSwipe: blizzard.CameraKitWebLensSwipe.fromPartial(event) },
                            ],
                            lensWait: (event) => [
                                "CAMERA_KIT_LENS_SPIN",
                                { cameraKitLensSpin: blizzard.CameraKitLensSpin.fromPartial(event) },
                            ],
                            lensContentValidationFailed: (event) => [
                                "CAMERA_KIT_LENS_CONTENT_VALIDATION_FAILED",
                                {
                                    cameraKitLensContentValidationFailed:
                                        blizzard.CameraKitLensContentValidationFailed.fromPartial(event),
                                },
                            ],
                            session: (event) => [
                                "CAMERA_KIT_SESSION",
                                { cameraKitSession: blizzard.CameraKitSession.fromPartial(event) },
                            ],
                        },
                        configuration.apiHostname,
                        appVendorUuid
                    );
                },
            });
    }
);
