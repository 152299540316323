/* eslint-disable @typescript-eslint/member-ordering */

import { validate } from "../common/validate";
import { LensCoreModule, UseMediaElementInput } from "../lens-core-module/generated-types";
import { Transform2D } from "../transforms";

const defaultDeviceInfo: CameraKitDeviceOptions = {
    cameraType: "user",
    fpsLimit: Number.POSITIVE_INFINITY,
};

const createNotAttachedError = (message: string) =>
    new Error(`${message}. This CameraKitSource is not attached to a CameraKitSession.`);

/**
 * When creating a {@link CameraKitSource}, passing a CameraKitSourceSubscriber allows logic to implemented which will
 * run whenever that source is attached/detached from a CameraKitSession.
 *
 * @category Rendering
 */
export interface CameraKitSourceSubscriber {
    readonly onAttach?: (
        source: CameraKitSource,
        lensCore: LensCoreModule,
        reportError: (error: Error) => void
    ) => void | Promise<void>;
    readonly onDetach?: (reportError: (error: Error) => void) => void | Promise<void>;
}

/** @category Rendering
 * @deprecated use {@link CameraKitDeviceOptions}
 */
export type CameraKitDeviceInfo = {
    /** @deprecated "front" and "back" are deprecated please use "user" or "enviroment" for cameraType instead */
    cameraType: "front" | "back";
    fpsLimit: number;
};

/** @category Rendering */
export type CameraKitDeviceOptions = {
    cameraType: "user" | "environment";
    fpsLimit: number;
};

export type CameraKitSourceInfo = Pick<
    UseMediaElementInput,
    "media" | "replayTrackingData" | "useManualFrameProcessing"
>;

/** @category Rendering */
export type CameraKitSourceOptions<T = {}> = Partial<T> & Partial<CameraKitDeviceInfo | CameraKitDeviceOptions>;

/**
 * This general-purpose class represents a source of media for a {@link CameraKitSession}.
 *
 * When an instance is passed to {@link CameraKitSession.setSource | CameraKitSession.setSource}, it will be "attached"
 * to the session. Later it may be "detached" from the session.
 *
 * Passing a {@link CameraKitSourceSubscriber} to the constructor allows callers to specify behavior
 * that will occur when the source is attached and detached. This can be used to e.g. update the render size.
 *
 * @category Rendering
 */
export class CameraKitSource {
    private lensCore?: LensCoreModule;
    private readonly deviceInfo: CameraKitDeviceInfo | CameraKitDeviceOptions;

    constructor(
        private readonly sourceInfo: CameraKitSourceInfo,
        private readonly subscriber: CameraKitSourceSubscriber = {},
        deviceInfo: Partial<CameraKitDeviceInfo | CameraKitDeviceOptions> = {}
    ) {
        this.deviceInfo = { ...defaultDeviceInfo, ...deviceInfo };
    }

    /**
     * Called by {@link CameraKitSession} when this source is set as that session's source.
     *
     * @param lensCore
     * @param reportError Calling this function will report an error back to the session.
     * @returns Rejects if any calls to LensCore or CameraKitSource.subscriber.onAttach fail.
     * @internal
     */
    async attach(lensCore: LensCoreModule, reportError: (error: Error) => void): Promise<void> {
        if (this.lensCore) {
            throw new Error(
                "Cannot attach. This CameraKitCustomSource has already been attached to " +
                    "a CameraKitSession. To re-attach, create a copy of this CameraKitCustomSource."
            );
        }

        this.lensCore = lensCore;

        await new Promise((onSuccess, onFailure) => {
            lensCore.useMediaElement({
                autoplayNewMedia: false,
                autoplayPreviewCanvas: false,
                media: this.sourceInfo.media,
                pauseExistingMedia: false,
                replayTrackingData: this.sourceInfo.replayTrackingData,
                requestWebcam: false,
                startOnFrontCamera: ["user", "front"].includes(this.deviceInfo.cameraType),
                useManualFrameProcessing: this.sourceInfo.useManualFrameProcessing,
                onSuccess,
                onFailure,
            });
        });

        await new Promise<void>((onSuccess, onFailure) => {
            // LensCore uses 0 to remove the limit.
            const fps = this.deviceInfo.fpsLimit < Number.POSITIVE_INFINITY ? this.deviceInfo.fpsLimit : 0;
            lensCore.setFPSLimit({ fps, onSuccess, onFailure });
        });

        if (this.subscriber.onAttach) await this.subscriber.onAttach(this, lensCore, reportError);
    }

    /**
     * Make a copy of the source, sharing the same {@link CameraKitSourceSubscriber}.
     *
     * @param deviceInfo Optionally provide new device info for the copy (e.g. to change the camera type).
     * @returns The new {@link CameraKitSource}
     */
    /** @deprecated Use {@link CameraKitDeviceOptions} where cameraType is either "environment" or "user" */
    copy(deviceInfo?: Partial<CameraKitDeviceInfo>): CameraKitSource;
    copy(deviceInfo?: Partial<CameraKitDeviceOptions>): CameraKitSource;
    copy(deviceInfo: Partial<CameraKitDeviceOptions | CameraKitDeviceInfo> = {}): CameraKitSource {
        return new CameraKitSource(this.sourceInfo, this.subscriber, { ...this.deviceInfo, ...deviceInfo });
    }

    /**
     * Called by {@link CameraKitSession} when it must remove this source.
     *
     * @param reportError Calling this function will report an error back to the session.
     * @returns
     * @internal
     */
    detach(reportError: (error: Error) => void): void | Promise<void> {
        if (!this.lensCore) return Promise.reject(createNotAttachedError("Cannot detach"));
        if (this.subscriber.onDetach) return this.subscriber.onDetach(reportError);
    }

    /**
     * Set the resolution used to render this source.
     *
     * It’s important to distinguish render size from display size. The size at which the output canvases are displayed
     * on a web page is determined by the CSS of the page. It is distinct from the size at which LensCore renders
     * Lenses. Performance is dominated by render size, while any display scaling can most often be thought of as free.
     *
     * If greater performance is required, a smaller render size may boost frame-rate. It does come at a cost, including
     * loss of accuracy in various tracking and computer-vision algorithms (since they'll be operating on fewer pixels).
     *
     * The size of the Live and Capture {@link RenderTarget} is always the same.
     *
     * @todo Currently it's only valid to call `setRenderSize` after `CameraKitSession.play` has been called. This
     * constraint should be removed, so callers don't have to understand the underlying LensCore state machine.
     *
     * @param width pixels
     * @param height pixels
     * @returns Promise resolves when the render size has been successfully updated.
     */
    @validate
    setRenderSize(width: number, height: number): Promise<void> {
        return new Promise((onSuccess, onFailure) => {
            if (!this.lensCore) return onFailure(createNotAttachedError("Cannot setRenderSize"));
            const target = { width, height };
            this.lensCore.setRenderSize({ mode: "explicit", target, onSuccess, onFailure });
        });
    }

    /**
     * Apply a 2D transformation to the source (e.g. translation, rotation, scale).
     *
     * @param transform Specifies the 3x3 matrix describing the transformation.
     */
    @validate
    setTransform(transform: Transform2D): Promise<void> {
        return new Promise((onSuccess, onFailure) => {
            if (!this.lensCore) return onFailure(createNotAttachedError("Cannot setTransform"));
            const matrix = new Float32Array(transform.matrix);
            this.lensCore.setInputTransform({ matrix, onSuccess, onFailure });
        });
    }
}
