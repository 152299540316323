import { PartialContainer } from "../../dependency-injection/PartialContainer";
import { reportSessionException } from "./reportSessionException";
import { reportBenchmarks } from "./reportBenchmarks";
import { reportHttpMetrics } from "./reportHttpMetrics";
import { reportLegalState } from "./reportLegalState";
import { reportLensAndAssetDownload } from "./reportLensAndAssetDownload";
import { reportLensValidationFailed } from "./reportLensValidationFailed";
import { reportLensView } from "./reportLensView";
import { reportLensWait } from "./reportLensWait";
import { reportUserSession } from "./reportUserSession";

/**
 * These metrics reporters must be run once in the top-level DI container. They only depend on globally-available
 * services.
 *
 * The businessEventsReporter is special, it doesn't create any of its own metrics, it simply listens to the global
 * metricsEventTarget and reports metrics emitted there to our backend.
 */
export const reportGloballyScopedMetrics = new PartialContainer({})
    .provides(reportHttpMetrics)
    .provides(reportBenchmarks)
    .provides(reportLensAndAssetDownload)
    .provides(reportLegalState);

/**
 * These metrics reporters must be run once for each CameraKitSession DI container created. They may depend on services
 * which are only available at the session scope (e.g. the CameraKitSession itself).
 */
export const reportSessionScopedMetrics = new PartialContainer({})
    .provides(reportUserSession)
    .provides(reportLensView)
    .provides(reportLensWait)
    .provides(reportSessionException)
    .provides(reportLensValidationFailed);
