import { AnyJson, encodeKnownAnyJson } from "../common/any";
import { Any } from "../generated-proto/pb_schema/google/protobuf/any";
import { CameraKitApiHostname } from "../configuration";
import { FetchHandler } from "../handlers/defaultFetchHandler";
import { isGetGroupResponse, isLensProto, LensProto } from "./Lens";

const relativePath = "/com.snap.camerakit.v3.Lenses";

function fixAny(lens: LensProto): LensProto {
    // The Lens is serialized into JSON by the CameraKit backend, which is vulnerable
    // to serialization discrepancies between the backend and ts-proto generated serializers.
    // See packages/web-sdk/src/common/any.ts
    const featureMetadata = lens.featureMetadata.reduce((fixedAnys, anyToFix) => {
        // Safety: anyToFix is actually AnyJson, due to how our backend serializes it
        const fixedAny = encodeKnownAnyJson(anyToFix as unknown as AnyJson);
        return fixedAny ? [...fixedAnys, fixedAny] : fixedAnys;
    }, [] as Any[]);

    return {
        ...lens,
        featureMetadata,
    };
}

function getRequestId(res: Response) {
    return res.headers.get("x-request-id");
}

export async function retrieveCameraKitLens(
    httpClient: FetchHandler,
    lensId: string,
    groupId: string,
    apiHostname: CameraKitApiHostname
): Promise<LensProto> {
    const url = `https://${apiHostname}${relativePath}/groups/${groupId}/lenses/${lensId}`;
    const response = await httpClient(url, { credentials: "include" });
    const body = await response.json();
    const lens = body.lens;

    if (!response.ok) {
        throw new Error(
            `Cannot load lens ${lensId} in group ${groupId}. GetGroupLens responded with status ` +
                `${response.status} and body:\n\t${JSON.stringify(body)} for requestId ${getRequestId(response)}`
        );
    }

    if (!isLensProto(lens)) {
        throw new Error(
            `Cannot load lens ${lensId} in group ${groupId}. The response was not a Lens:` +
                `\n\t${JSON.stringify(body)} for requestId ${getRequestId(response)}`
        );
    }
    return fixAny(lens);
}

export async function retrieveCameraKitLensGroup(
    httpClient: FetchHandler,
    groupId: string,
    apiHostname: CameraKitApiHostname
): Promise<LensProto[]> {
    const url = `https://${apiHostname}${relativePath}/groups/${groupId}`;
    const response = await httpClient(url, { credentials: "include" });
    const body = await response.json();

    if (!response.ok) {
        throw new Error(
            `Cannot load lens group ${groupId}. GetGroup responded with status ` +
                `${response.status} and body:\n\t${JSON.stringify(body)} for requestId ${getRequestId(response)}`
        );
    }

    if (!isGetGroupResponse(body)) {
        throw new Error(
            `Cannot load lens group ${groupId}. The response was not a LensGroup:` +
                `\n\t${JSON.stringify(body)} for requestId ${getRequestId(response)}`
        );
    }
    return body.lenses.map(fixAny);
}
