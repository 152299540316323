import { CameraKitSource, CameraKitSourceOptions } from "./CameraKitSource";

/**
 * Create a {@link CameraKitSource} from an
 * [HTMLImageElement](https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement).
 *
 * @param image Image element.
 * @param options
 * @param options.cameraType By default we set this to 'user', which is the camera type most Lenses expect.
 * @param options.fpsLimit By default we set no limit on FPS.
 *
 * @category Rendering
 */
export function createImageSource(image: HTMLImageElement, options: CameraKitSourceOptions = {}): CameraKitSource {
    return new CameraKitSource(
        {
            media: image,
        },
        {
            onAttach: (source) => {
                // If the image element has no explicit width/height (which may be the case for elements that have not
                // been added to the DOM or had their size set explicitly), we'll render at the image's native
                // resolution.
                const [width, height] =
                    image.width === 0 || image.height === 0
                        ? [image.naturalWidth, image.naturalHeight]
                        : [image.width, image.height];

                return source.setRenderSize(width, height);
            },
        },
        options
    );
}
