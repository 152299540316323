import { assert } from "../common/assertions";
import {
    predicateRecordValues,
    isRecord,
    isString,
    isUndefined,
    isEmptyOrSafeUrl,
    isArrayOfType,
    isTypedArray,
} from "../common/typeguards";
import {
    Content,
    Lens_CameraFacing,
    Lens as LensGeneratedProto,
    LensCreator,
} from "../generated-proto/pb_schema/camera_kit/v3/lens";
import { GetGroupResponse } from "../generated-proto/pb_schema/camera_kit/v3/service";
import { Any } from "../generated-proto/pb_schema/google/protobuf/any";

/**
 * @internal
 */
export type ContentProto = Omit<Content, "lnsUrl" | "iconUrl">;

/**
 * @internal
 */
export interface LensProto extends Omit<LensGeneratedProto, "content"> {
    content: ContentProto | undefined;
}

/**
 * @category Lenses
 */
export interface Lens {
    /**
     * Non-empty identifier of a unique lens available to be applied in current session.
     *
     * NOTE: This value may contain unsafe characters
     * and therefore may require additional processing depending on usages.
     */
    id: string;

    /**
     * Human readable name of this lens.
     *
     * NOTE: This value may contain unsafe characters
     * and therefore may require additional processing depending on usages.
     */
    name: string;

    /**
     * Icon resource URI that represents this lens if available.
     */
    iconUrl: string | undefined;

    /**
     * Vendor specific metadata associated with this lens, empty by default.
     *
     * NOTE: Metadata values may contain unsafe characters
     * and therefore may require additional processing depending on usages.
     */
    vendorData: { [key: string]: string };

    /**
     * Specifies a [Lens_CameraFacing] that this lens is designed for.
     */
    cameraFacingPreference: Lens_CameraFacing;

    /**
     * Preview representing this lens, if available.
     */
    preview: Preview | undefined;

    /**
     * Information about the lens creator.
     */
    lensCreator: LensCreator | undefined;

    /**
     * A [snapcode](https://scan.snapchat.com/snapcodes) that represents the lens if available.
     *
     * The snapcode points to the lens in the Snapchat app, when used on mobile, or a dedicated web page.
     */
    snapcode: Snapcode | undefined;

    /**
     * Metadata pertaining to a specific set of lens features
     */
    featureMetadata: Any[];
}

/**
 * Lens preview.
 */
// That corresponds to Preview interface in packages/web-sdk/src/generated-proto/pb_schema/camera_kit/v3/lens.ts
export interface Preview {
    /**
     * Link to a lens preview image.
     */
    imageUrl: string;
}

/**
 * [Snapcode](https://scan.snapchat.com/snapcodes) representing a lens.
 */
// That corresponds to Scannable interface in packages/web-sdk/src/generated-proto/pb_schema/camera_kit/v3/lens.ts
export interface Snapcode {
    /**
     * Snapcode image URL scannable with Snapchat app.
     */
    imageUrl: string;

    /**
     * Deeplink URL that directs to a lens web page.
     */
    deepLink: string;
}

export function isLensArray(value: unknown): value is Lens[] {
    return isArrayOfType(isLens, value);
}

export function isLens(value: unknown): value is Lens {
    return (
        isRecord(value) &&
        isString(value.id) &&
        isString(value.name) &&
        (isUndefined(value.iconUrl) || isString(value.iconUrl)) &&
        isRecord(value.vendorData) &&
        predicateRecordValues(isString)(value.vendorData) &&
        isString(value.cameraFacingPreference) &&
        (isUndefined(value.preview) || isPreview(value.preview)) &&
        (isUndefined(value.lensCreator) || isLensCreator(value.lensCreator)) &&
        (isUndefined(value.snapcode) || isSnapcode(value.snapcode)) &&
        isAnyArray(value.featureMetadata)
    );
}

export function isLensProto(value: unknown): value is LensProto {
    return (
        isRecord(value) &&
        isString(value.id) &&
        isString(value.name) &&
        isRecord(value.vendorData) &&
        predicateRecordValues(isString)(value.vendorData) &&
        (typeof value.content === "undefined" || isLensContent(value.content))
    );
}

export function isPreview(value: unknown): value is Preview {
    return isRecord(value) && isString(value.imageUrl);
}

export function isLensCreator(value: unknown): value is LensCreator {
    return isRecord(value) && isString(value.displayName);
}

export function isSnapcode(value: unknown): value is Snapcode {
    return isRecord(value) && isString(value.imageUrl) && isString(value.deepLink);
}

export function isLensContent(value: unknown): value is ContentProto {
    return (
        isRecord(value) &&
        isString(value.iconUrlBolt) &&
        isString(value.lnsSha256) &&
        isString(value.lnsUrlBolt) &&
        isRecord(value.preview) &&
        isString(value.preview.imageUrl)
    );
}

export function isGetGroupResponse(value: unknown): value is GetGroupResponse {
    return isRecord(value) && isString(value.id) && Array.isArray(value.lenses) && value.lenses.every(isLensProto);
}

export function isAny(value: unknown): value is Any {
    return isRecord(value) && isString(value.typeUrl) && isTypedArray(value.value);
}

export function isAnyArray(value: unknown): value is Any[] {
    return isArrayOfType(isAny, value);
}

/**
 * Converts lens proto to a public lens object.
 * @param lens Lens proto
 * @returns Public lens object.
 *
 * @internal
 */
export function toPublicLens({
    id,
    name,
    content,
    vendorData,
    cameraFacingPreference,
    lensCreator,
    scannable,
    featureMetadata,
}: LensProto): Lens {
    assert(isEmptyOrSafeUrl(content?.iconUrlBolt), "Unsafe icon URL");
    assert(isEmptyOrSafeUrl(content?.preview?.imageUrl), "Unsafe preview URL");
    return {
        id,
        name,
        iconUrl: content?.iconUrlBolt,
        preview: content?.preview ? { imageUrl: content.preview.imageUrl } : undefined,
        vendorData,
        cameraFacingPreference,
        lensCreator,
        snapcode: scannable
            ? { imageUrl: scannable.snapcodeImageUrl, deepLink: scannable.snapcodeDeeplink }
            : undefined,
        featureMetadata,
    };
}
