import { simd, exceptions } from "wasm-feature-detect";
import lensCoreWasm from "../../lensCoreWasmVersions.json";
import { loadScript } from "../../common";
import { CameraKitConfiguration, configurationToken } from "../../configuration";
import { Injectable } from "../../dependency-injection/Injectable";
import { defaultFetchHandlerFactory, FetchHandler } from "../../handlers/defaultFetchHandler";
import { InitialEmscriptenModule, LensCoreModule } from "../generated-types";
import { getLogger } from "../../logger/logger";
import { cameraKitUserAgent } from "../../common/cameraKitUserAgent";

const logger = getLogger("lensCoreFactory");

const wasmAssets = ["LensCoreWebAssembly.js", "LensCoreWebAssembly.wasm"];

const findMatch = (regex: RegExp, strings: string[]) => strings.find((s) => regex.test(s));

const PlatformFeatures = {
    Default: 0,
    Simd: 0b01,
    Exceptions: 0b10,
};

const platformFeaturesToFlavour = {
    [PlatformFeatures.Exceptions | PlatformFeatures.Simd]: "rel-simd-neh",
    [PlatformFeatures.Simd]: "release-simd",
    [PlatformFeatures.Exceptions]: "rel-neh",
    [PlatformFeatures.Default]: "release",
};

/**
 * Returns a list of URLs for resources which will be fetched during {@link bootstrapCameraKit}.
 *
 * When CameraKit is used on a website, these URLs much be reachable in order for CameraKit to be successfully
 * bootstrapped.
 *
 * @param endpointOverride Optional endpoint override to load the assets from.
 * @returns An array of asset URLs.
 *
 * @category Bootstrapping and Configuration
 */
export async function getRequiredBootstrapURLs(endpointOverride?: string): Promise<string[]> {
    // If we have an endpoint override, remove trailing `/` so we can construct a valid URL.
    const endpoint = endpointOverride?.replace(/[\/]+$/, "");

    let [simdFeature, exceptionsFeature] = await Promise.all([
        simd().then((supported) => (supported ? PlatformFeatures.Simd : PlatformFeatures.Default)),
        exceptions().then((supported) => (supported ? PlatformFeatures.Exceptions : PlatformFeatures.Default)),
    ]);

    // Although Safari 16.4 reports SIMD support, LensCore encounters rendering bugs when using SIMD in Safari 16.4.
    // Therefore, we have made the decision to disable SIMD for now until Safari stabilizes the feature.
    const { brand } = cameraKitUserAgent.browser;
    if (brand === "Safari") simdFeature = PlatformFeatures.Default;

    const flavor = platformFeaturesToFlavour[simdFeature | exceptionsFeature];

    const version = lensCoreWasm.version;
    const buildNumber = lensCoreWasm.buildNumber;
    return wasmAssets.map((asset) => {
        if (endpoint) return `${endpoint}/${asset}`;
        const { origin, pathname, search } = new URL(lensCoreWasm.baseUrl);
        return `${origin}${pathname}/${version}/${buildNumber}/${flavor}/${asset}${search}`;
    });
}

/**
 * This component is responsible for:
 *   1) Loading LensCore WebAssembly (WASM) assets
 *   2) Using the WASM assets to initialize the LensCore WASM module
 *
 * By default, WASM assets will be loaded from the Bolt CDN – but if `endpoint` is provided, assets will be loaded
 * using it as a base URL.
 *
 * @internal
 */
export const lensCoreFactory = Injectable(
    "lensCore",
    [defaultFetchHandlerFactory.token, configurationToken] as const,
    async (handler: FetchHandler, { lensCoreOverrideUrls, wasmEndpointOverride }: CameraKitConfiguration) => {
        let lensCoreJS: string;
        let lensCoreWASM: string;

        if (lensCoreOverrideUrls) {
            lensCoreJS = lensCoreOverrideUrls.js;
            lensCoreWASM = lensCoreOverrideUrls.wasm;
        } else {
            const endpointOverride = wasmEndpointOverride ?? undefined;
            const assetURLs = await getRequiredBootstrapURLs(endpointOverride);

            lensCoreJS = findMatch(/\.js/, assetURLs) ?? "";
            lensCoreWASM = findMatch(/\.wasm/, assetURLs) ?? "";

            if (!lensCoreJS || !lensCoreWASM) {
                throw new Error(
                    `Cannot fetch required LensCore assets. Either the JS or WASM filename is missing from ` +
                        `this list: ${assetURLs}.`
                );
            }

            // Fetching here and creating an Object URL lets LensCore optimized loading itself in a WebWorker,
            // otherwise the glue script would need to be downloaded again.
            const glueScript = await handler(lensCoreJS).then((r) => r.blob());
            lensCoreJS = URL.createObjectURL(glueScript);
        }

        const scriptElement = await loadScript(lensCoreJS);

        const lensCore = await new Promise<InitialEmscriptenModule & LensCoreModule>((resolve, reject) => {
            let initialModule: Partial<InitialEmscriptenModule>;
            // will trigger WASM initialization and data loading,
            // after completion it will be safe to call imported WASM functions
            // More about emscripten initialization:
            // eslint-disable-next-line max-len
            // https://emscripten.org/docs/getting_started/FAQ.html?highlight=modularize#how-can-i-tell-when-the-page-is-fully-loaded-and-it-is-safe-to-call-compiled-functions
            const moduleInit = globalThis.createLensesModule(
                (initialModule = {
                    // url will be used for loading glue JS during Worker inialization
                    mainScriptUrlOrBlob: lensCoreJS,
                    // will be triggered by Emscripten during the initialization
                    instantiateWasm: (importObject, receiveInstance) => {
                        WebAssembly.instantiateStreaming(handler(lensCoreWASM), importObject)
                            .then(function ({ instance, module }) {
                                receiveInstance(instance, module);
                                // compiled module will be reused in Worker
                                initialModule.compiledModule = module;
                                resolve(moduleInit);
                            })
                            .catch(reject);
                    },
                })
            );
        });

        // now when we have LensCore WASM in memory we can release the script element
        scriptElement.remove();

        // print warning if loaded version differs from hardcoded one
        if (lensCoreWasm.version != `${lensCore.getCoreVersion()}`) {
            logger.warn(
                `Loaded LensCore version (${lensCore.getCoreVersion()}) differs from expected one (${
                    lensCoreWasm.version
                })`
            );
        }

        return lensCore;
    }
);
