import { Envelope } from "../generated-proto/pb_schema/camera_kit/v3/export";
import { LensProto } from "./Lens";

/**
 * @internal
 */
function decodeEnvelope(envelope: ArrayBuffer) {
    try {
        return Envelope.decode(new Uint8Array(envelope)).lenses;
    } catch {
        throw new Error("Invalid lens envelope.");
    }
}

/**
 * @internal
 */
export function decodeEnvelopes(envelopes: ArrayBuffer[]) {
    return envelopes.reduce<LensProto[]>((lenses, envelope) => [...lenses, ...decodeEnvelope(envelope)], []);
}
