import { CameraKitSourceError, LensExecutionError, LensImagePickerError } from "../namedErrors";
import { TypedCustomEvent } from "../events/TypedCustomEvent";
import { TypedEventListener } from "../events/TypedEventTarget";
import { Lens } from "../lens";

/**
 * A type guard helper to detect unreachable code.
 */
const isReachable = (_: never) => false;

/**
 * Returns true if given value is of {@link SessionErrors} type and false otherwise.
 */
export function isSessionError(value: unknown): value is SessionErrors {
    if (!(value instanceof Error)) return false;

    const maybeSessionErrorName = (value as SessionErrors).name;
    switch (maybeSessionErrorName) {
        case "CameraKitSourceError":
        case "LensExecutionError":
        case "LensImagePickerError":
            return true;
        default:
            return isReachable(maybeSessionErrorName);
    }
}

/**
 * Misc errors that occur during CameraKit session.
 * @internal
 */
export type SessionErrors = LensExecutionError | LensImagePickerError | CameraKitSourceError;

/**
 * Events emitted by {@link CameraKitSession.events | CameraKitSession.events}.
 *
 * The following events are emitted:
 *   - `error`: An error has been encountered during lens rendering. May contain an error of type:
 *     - {@link LensExecutionError} If an error of this type occurs, the rendering lens will be automatically removed
 * from the associated CameraKitSession.
 *     - {@link LensImagePickerError}
 *
 * @category Rendering
 * @category Lenses
 */
export type CameraKitSessionEvents = TypedCustomEvent<
    "error",
    { error: LensExecutionError | LensImagePickerError; lens: Lens }
>;

/**
 * Listener of {@link CameraKitSessionEvents} events.
 */
export type CameraKitSessionEventListener = TypedEventListener<CameraKitSessionEvents>;
