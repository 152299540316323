import Long from "long";
import _m0 from "protobufjs/minimal";
export const protobufPackage = "com.snap.camerakit.v3";
export enum CameraKitFlavor {
    CAMERA_KIT_FLAVOR_UNSET = "CAMERA_KIT_FLAVOR_UNSET",
    CAMERA_KIT_FLAVOR_DEBUG = "CAMERA_KIT_FLAVOR_DEBUG",
    CAMERA_KIT_FLAVOR_RELEASE = "CAMERA_KIT_FLAVOR_RELEASE",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function cameraKitFlavorFromJSON(object: any): CameraKitFlavor {
    switch (object) {
        case 0:
        case "CAMERA_KIT_FLAVOR_UNSET":
            return CameraKitFlavor.CAMERA_KIT_FLAVOR_UNSET;
        case 1:
        case "CAMERA_KIT_FLAVOR_DEBUG":
            return CameraKitFlavor.CAMERA_KIT_FLAVOR_DEBUG;
        case 2:
        case "CAMERA_KIT_FLAVOR_RELEASE":
            return CameraKitFlavor.CAMERA_KIT_FLAVOR_RELEASE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return CameraKitFlavor.UNRECOGNIZED;
    }
}
export function cameraKitFlavorToJSON(object: CameraKitFlavor): string {
    switch (object) {
        case CameraKitFlavor.CAMERA_KIT_FLAVOR_UNSET:
            return "CAMERA_KIT_FLAVOR_UNSET";
        case CameraKitFlavor.CAMERA_KIT_FLAVOR_DEBUG:
            return "CAMERA_KIT_FLAVOR_DEBUG";
        case CameraKitFlavor.CAMERA_KIT_FLAVOR_RELEASE:
            return "CAMERA_KIT_FLAVOR_RELEASE";
        default:
            return "UNKNOWN";
    }
}
export function cameraKitFlavorToNumber(object: CameraKitFlavor): number {
    switch (object) {
        case CameraKitFlavor.CAMERA_KIT_FLAVOR_UNSET:
            return 0;
        case CameraKitFlavor.CAMERA_KIT_FLAVOR_DEBUG:
            return 1;
        case CameraKitFlavor.CAMERA_KIT_FLAVOR_RELEASE:
            return 2;
        default:
            return 0;
    }
}
export enum CameraKitConnectivityType {
    CAMERA_KIT_CONNECTIVITY_TYPE_UNSET = "CAMERA_KIT_CONNECTIVITY_TYPE_UNSET",
    CAMERA_KIT_CONNECTIVITY_TYPE_WIFI = "CAMERA_KIT_CONNECTIVITY_TYPE_WIFI",
    CAMERA_KIT_CONNECTIVITY_TYPE_MOBILE = "CAMERA_KIT_CONNECTIVITY_TYPE_MOBILE",
    CAMERA_KIT_CONNECTIVITY_TYPE_UNREACHABLE = "CAMERA_KIT_CONNECTIVITY_TYPE_UNREACHABLE",
    CAMERA_KIT_CONNECTIVITY_TYPE_BLUETOOTH = "CAMERA_KIT_CONNECTIVITY_TYPE_BLUETOOTH",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function cameraKitConnectivityTypeFromJSON(object: any): CameraKitConnectivityType {
    switch (object) {
        case 0:
        case "CAMERA_KIT_CONNECTIVITY_TYPE_UNSET":
            return CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_UNSET;
        case 1:
        case "CAMERA_KIT_CONNECTIVITY_TYPE_WIFI":
            return CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_WIFI;
        case 2:
        case "CAMERA_KIT_CONNECTIVITY_TYPE_MOBILE":
            return CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_MOBILE;
        case 3:
        case "CAMERA_KIT_CONNECTIVITY_TYPE_UNREACHABLE":
            return CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_UNREACHABLE;
        case 4:
        case "CAMERA_KIT_CONNECTIVITY_TYPE_BLUETOOTH":
            return CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_BLUETOOTH;
        case -1:
        case "UNRECOGNIZED":
        default:
            return CameraKitConnectivityType.UNRECOGNIZED;
    }
}
export function cameraKitConnectivityTypeToJSON(object: CameraKitConnectivityType): string {
    switch (object) {
        case CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_UNSET:
            return "CAMERA_KIT_CONNECTIVITY_TYPE_UNSET";
        case CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_WIFI:
            return "CAMERA_KIT_CONNECTIVITY_TYPE_WIFI";
        case CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_MOBILE:
            return "CAMERA_KIT_CONNECTIVITY_TYPE_MOBILE";
        case CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_UNREACHABLE:
            return "CAMERA_KIT_CONNECTIVITY_TYPE_UNREACHABLE";
        case CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_BLUETOOTH:
            return "CAMERA_KIT_CONNECTIVITY_TYPE_BLUETOOTH";
        default:
            return "UNKNOWN";
    }
}
export function cameraKitConnectivityTypeToNumber(object: CameraKitConnectivityType): number {
    switch (object) {
        case CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_UNSET:
            return 0;
        case CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_WIFI:
            return 1;
        case CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_MOBILE:
            return 2;
        case CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_UNREACHABLE:
            return 3;
        case CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_BLUETOOTH:
            return 4;
        default:
            return 0;
    }
}
export enum CameraKitEnvironment {
    CAMERA_KIT_ENVIRONMENT_UNSET = "CAMERA_KIT_ENVIRONMENT_UNSET",
    CAMERA_KIT_ENVIRONMENT_STAGING = "CAMERA_KIT_ENVIRONMENT_STAGING",
    CAMERA_KIT_ENVIRONMENT_PRODUCTION = "CAMERA_KIT_ENVIRONMENT_PRODUCTION",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function cameraKitEnvironmentFromJSON(object: any): CameraKitEnvironment {
    switch (object) {
        case 0:
        case "CAMERA_KIT_ENVIRONMENT_UNSET":
            return CameraKitEnvironment.CAMERA_KIT_ENVIRONMENT_UNSET;
        case 1:
        case "CAMERA_KIT_ENVIRONMENT_STAGING":
            return CameraKitEnvironment.CAMERA_KIT_ENVIRONMENT_STAGING;
        case 2:
        case "CAMERA_KIT_ENVIRONMENT_PRODUCTION":
            return CameraKitEnvironment.CAMERA_KIT_ENVIRONMENT_PRODUCTION;
        case -1:
        case "UNRECOGNIZED":
        default:
            return CameraKitEnvironment.UNRECOGNIZED;
    }
}
export function cameraKitEnvironmentToJSON(object: CameraKitEnvironment): string {
    switch (object) {
        case CameraKitEnvironment.CAMERA_KIT_ENVIRONMENT_UNSET:
            return "CAMERA_KIT_ENVIRONMENT_UNSET";
        case CameraKitEnvironment.CAMERA_KIT_ENVIRONMENT_STAGING:
            return "CAMERA_KIT_ENVIRONMENT_STAGING";
        case CameraKitEnvironment.CAMERA_KIT_ENVIRONMENT_PRODUCTION:
            return "CAMERA_KIT_ENVIRONMENT_PRODUCTION";
        default:
            return "UNKNOWN";
    }
}
export function cameraKitEnvironmentToNumber(object: CameraKitEnvironment): number {
    switch (object) {
        case CameraKitEnvironment.CAMERA_KIT_ENVIRONMENT_UNSET:
            return 0;
        case CameraKitEnvironment.CAMERA_KIT_ENVIRONMENT_STAGING:
            return 1;
        case CameraKitEnvironment.CAMERA_KIT_ENVIRONMENT_PRODUCTION:
            return 2;
        default:
            return 0;
    }
}
export interface ExtensionEventBase {
    extensionName: string;
    extensionVersion: string;
    deviceCluster: number;
    cameraKitVersion: string;
    lensCoreVersion: string;
    deviceModel: string;
    cameraKitFlavor: CameraKitFlavor;
    appId: string;
    deviceConnectivity: CameraKitConnectivityType;
    sessionId: string;
    cameraKitEnvironment: CameraKitEnvironment;
}
function createBaseExtensionEventBase(): ExtensionEventBase {
    return {
        extensionName: "",
        extensionVersion: "",
        deviceCluster: 0,
        cameraKitVersion: "",
        lensCoreVersion: "",
        deviceModel: "",
        cameraKitFlavor: CameraKitFlavor.CAMERA_KIT_FLAVOR_UNSET,
        appId: "",
        deviceConnectivity: CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_UNSET,
        sessionId: "",
        cameraKitEnvironment: CameraKitEnvironment.CAMERA_KIT_ENVIRONMENT_UNSET,
    };
}
export const ExtensionEventBase = {
    decode(input: _m0.Reader | Uint8Array, length?: number): ExtensionEventBase {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExtensionEventBase();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.extensionName = reader.string();
                    break;
                case 2:
                    message.extensionVersion = reader.string();
                    break;
                case 3:
                    message.deviceCluster = longToNumber(reader.int64() as Long);
                    break;
                case 4:
                    message.cameraKitVersion = reader.string();
                    break;
                case 5:
                    message.lensCoreVersion = reader.string();
                    break;
                case 6:
                    message.deviceModel = reader.string();
                    break;
                case 7:
                    message.cameraKitFlavor = cameraKitFlavorFromJSON(reader.int32());
                    break;
                case 8:
                    message.appId = reader.string();
                    break;
                case 9:
                    message.deviceConnectivity = cameraKitConnectivityTypeFromJSON(reader.int32());
                    break;
                case 10:
                    message.sessionId = reader.string();
                    break;
                case 11:
                    message.cameraKitEnvironment = cameraKitEnvironmentFromJSON(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): ExtensionEventBase {
        return {
            extensionName: isSet(object.extensionName) ? String(object.extensionName) : "",
            extensionVersion: isSet(object.extensionVersion) ? String(object.extensionVersion) : "",
            deviceCluster: isSet(object.deviceCluster) ? Number(object.deviceCluster) : 0,
            cameraKitVersion: isSet(object.cameraKitVersion) ? String(object.cameraKitVersion) : "",
            lensCoreVersion: isSet(object.lensCoreVersion) ? String(object.lensCoreVersion) : "",
            deviceModel: isSet(object.deviceModel) ? String(object.deviceModel) : "",
            cameraKitFlavor: isSet(object.cameraKitFlavor)
                ? cameraKitFlavorFromJSON(object.cameraKitFlavor)
                : CameraKitFlavor.CAMERA_KIT_FLAVOR_UNSET,
            appId: isSet(object.appId) ? String(object.appId) : "",
            deviceConnectivity: isSet(object.deviceConnectivity)
                ? cameraKitConnectivityTypeFromJSON(object.deviceConnectivity)
                : CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_UNSET,
            sessionId: isSet(object.sessionId) ? String(object.sessionId) : "",
            cameraKitEnvironment: isSet(object.cameraKitEnvironment)
                ? cameraKitEnvironmentFromJSON(object.cameraKitEnvironment)
                : CameraKitEnvironment.CAMERA_KIT_ENVIRONMENT_UNSET,
        };
    },
    toJSON(message: ExtensionEventBase): unknown {
        const obj: any = {};
        message.extensionName !== undefined && (obj.extensionName = message.extensionName);
        message.extensionVersion !== undefined && (obj.extensionVersion = message.extensionVersion);
        message.deviceCluster !== undefined && (obj.deviceCluster = Math.round(message.deviceCluster));
        message.cameraKitVersion !== undefined && (obj.cameraKitVersion = message.cameraKitVersion);
        message.lensCoreVersion !== undefined && (obj.lensCoreVersion = message.lensCoreVersion);
        message.deviceModel !== undefined && (obj.deviceModel = message.deviceModel);
        message.cameraKitFlavor !== undefined && (obj.cameraKitFlavor = cameraKitFlavorToJSON(message.cameraKitFlavor));
        message.appId !== undefined && (obj.appId = message.appId);
        message.deviceConnectivity !== undefined &&
            (obj.deviceConnectivity = cameraKitConnectivityTypeToJSON(message.deviceConnectivity));
        message.sessionId !== undefined && (obj.sessionId = message.sessionId);
        message.cameraKitEnvironment !== undefined &&
            (obj.cameraKitEnvironment = cameraKitEnvironmentToJSON(message.cameraKitEnvironment));
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<ExtensionEventBase>, I>>(object: I): ExtensionEventBase {
        const message = createBaseExtensionEventBase();
        message.extensionName = object.extensionName ?? "";
        message.extensionVersion = object.extensionVersion ?? "";
        message.deviceCluster = object.deviceCluster ?? 0;
        message.cameraKitVersion = object.cameraKitVersion ?? "";
        message.lensCoreVersion = object.lensCoreVersion ?? "";
        message.deviceModel = object.deviceModel ?? "";
        message.cameraKitFlavor = object.cameraKitFlavor ?? CameraKitFlavor.CAMERA_KIT_FLAVOR_UNSET;
        message.appId = object.appId ?? "";
        message.deviceConnectivity =
            object.deviceConnectivity ?? CameraKitConnectivityType.CAMERA_KIT_CONNECTIVITY_TYPE_UNSET;
        message.sessionId = object.sessionId ?? "";
        message.cameraKitEnvironment = object.cameraKitEnvironment ?? CameraKitEnvironment.CAMERA_KIT_ENVIRONMENT_UNSET;
        return message;
    },
};
declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
    if (typeof globalThis !== "undefined")
        return globalThis;
    if (typeof self !== "undefined")
        return self;
    if (typeof window !== "undefined")
        return window;
    if (typeof global !== "undefined")
        return global;
    throw "Unable to locate global object";
})();
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
function longToNumber(long: Long): number {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
function isSet(value: any): boolean {
    return value !== null && value !== undefined;
}
