import { assert } from "./common/assertions";
import { platformNotSupportedError } from "./namedErrors";

const minTextureSize = 1024;

/**
 * Assert that the current platform supports the necessary WebGL features.
 * Specifically, it checks for WebGL or WebGL2 support and ensures that
 * the maximum texture size is at least 1024.
 */
export function assertPlatformSupported() {
    const canvas = document.createElement("canvas");
    const webglContext = canvas.getContext("webgl2") || canvas.getContext("webgl");
    const maxTextureSize = webglContext?.getParameter(webglContext.MAX_TEXTURE_SIZE);

    assert(
        !!webglContext,
        platformNotSupportedError(
            "Camera Kit cannot be bootstrapped because the browser does not support WebGL canvas rendering context."
        )
    );

    // Assert that the maximum texture size supported by WebGL is at least 1024.
    // This is based on the information available at https://web3dsurvey.com/webgl/parameters/MAX_TEXTURE_SIZE
    // and ensures that the application avoids future errors due to incompatibility with smaller texture sizes.
    assert(
        maxTextureSize >= minTextureSize,
        platformNotSupportedError(
            // eslint-disable-next-line max-len
            `Camera Kit cannot be bootstrapped because this browser's WebGL MAX_TEXTURE_SIZE is ${maxTextureSize}, which is below the minimum requirement of ${minTextureSize}.`
        )
    );
}
