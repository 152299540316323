import environment from "../environment.json";
import lensCoreWasm from "../lensCoreWasmVersions.json";
import { locale } from "./locale";
import { isRecord } from "./typeguards";

type BrandArray = Array<{ brand: string; version: string }>;

interface NavigatorUAData {
    brands: BrandArray;
    mobile: boolean;
    platform: string;
}

export type ConnectionType = "bluetooth" | "cellular" | "ethernet" | "none" | "wifi" | "wimax" | "other" | "unknown";

declare global {
    interface Navigator {
        userAgentData?: NavigatorUAData;
        connection?: {
            // This currently has extremely limited support in browsers.
            // https://wicg.github.io/netinfo/#dom-networkinformation-type
            type?: ConnectionType;
        };
    }
}

/**
 * Some user agents may not properly implement the NavigatorUAData interface, so we have to do our own validation here
 * to make sure we're dealing with a well-formed value.
 */
function isNavigatorUAData(value: unknown): value is NavigatorUAData {
    return (
        isRecord(value) &&
        Array.isArray(value["brands"]) &&
        value["brands"].every((brand) => {
            return isRecord(brand) && typeof brand["brand"] === "string" && typeof brand["version"] === "string";
        }) &&
        typeof value["mobile"] === "boolean" &&
        typeof value["platform"] === "string"
    );
}

/**
 * Parse the platform (i.e. OS) version.
 *
 * From limited testing, this seems to often produce incorrect results – the userAgent string does not typically include
 * the actual OS version.
 *
 * Better results could be obtained from [NavigatorUAData.getHighEntropyValues]
 * (https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData/getHighEntropyValues), but this presents two
 * problems: 1) it's currently only supported on Chrome and 2) browsers may prompt the user for permission to share
 * this information.
 *
 * So, at least for now, we'll be satisfied with the incorrect version number.
 */
function parsePlatformVersion(userAgent: string) {
    // possible platform version values inside of user agent string
    // " 11;"
    // " 10_15_7)"
    // " 13_5_1 "
    // " 10.0;"
    // " 15_1 "
    const versionMatch = userAgent.match(/\s([\d][\d_.]*[\d])(;|\)|\s)/);

    if (versionMatch != null) {
        return versionMatch[1].replace(/_/g, ".");
    }

    return "";
}

/**
 * In the future, we may invest in more robust device-detection (e.g. a UA string database), but for now this will give
 * us some sense of device usage.
 */
function parseDeviceModel(userAgent: string) {
    // from user agent like "(Linux; Android 11; Pixel 2)" extact "Pixel 2"
    const userAgentWithModel = userAgent.match(/;[^;]+?;([^\)]+?)\)/);

    if (userAgentWithModel) {
        return userAgentWithModel[1].trim();
    }

    // from user agent like "... (iPad; CPU OS 15_1 like Mac OS X) ..." extract "IPad"
    const userAgentWithModel2 = userAgent.match(/\(([^;]+);/);

    if (userAgentWithModel2) {
        return userAgentWithModel2[1].trim();
    }

    return "unknown";
}

/**
 * Some browsers (e.g. Safari) do not support the `Navigator.userAgentData` API. We'll attempt a sort of polyfill by
 * parsing the data found in [NavigatorUAData](https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData) from
 * the raw user agent string.
 */
function parseUserAgentData(userAgent: string): NavigatorUAData {
    let brand: BrandArray[number];

    // Parse UA string for Chromium-based browsers (e.g. Chrome, Edge)
    if (/Chrome/.test(userAgent)) {
        const versionMatch = userAgent.match(/Chrome\/([\d.]+)/);
        brand = {
            brand: "Chrome",
            version: versionMatch !== null ? versionMatch[1] : "unknown",
        };
    }

    // Parse UA string for Safari (very important for this to only be done if Chrome is not found – Chrome userAgent
    // strings will contain "Safari")
    else if (/Safari/.test(userAgent)) {
        let versionMatch = userAgent.match(/Version\/([\d.]+)/);
        if (versionMatch === null) versionMatch = userAgent.match(/Safari\/([\d.]+)/);
        brand = {
            brand: "Safari",
            version: versionMatch !== null ? versionMatch[1] : "unknown",
        };
    }

    // Parse UA for unknown browser.
    // TODO: will be changed, default value support should be added on a COF server side.
    else {
        brand = {
            brand: "Firefox",
            version: "0",
        };
    }

    // We're not using `mobile` for anything, and we have no consistent way to determine this from the UA string.
    // We'll set it to false, but this should not be used – instead, we'll need to rely on more sophisticated methods
    // (e.g. a userAgent database) to determine actual device.
    const mobile = false;
    const platform = parsePlaftformName(userAgent);

    return {
        brands: [brand],
        mobile,
        platform,
    };
}

/* eslint-disable max-len */
/**
 * The `brands` array found in [NavigatorUAData](https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData) is
 * intentionally designed to discourage standardized processing. This method of extracting brand information will be
 * inherently brittle, and it relies on us matching some well-known brands.
 *
 * For more detail from the spec:
 * See https://wicg.github.io/ua-client-hints/#monkeypatch-html-windoworworkerglobalscope
 * And https://wicg.github.io/ua-client-hints/#grease
 *
 * We also must match the list of known brands allowed by the backend, defined here:
 * https://github.sc-corp.net/Snapchat/useragent/blob/9333afe7cc6ac00503ad46cb234bcf94006dff98/java/useragent/src/main/java/snapchat/client/UserAgent.java#L124
 */
/* eslint-enable */
type KnownBrand = "Chrome" | "Safari" | "Firefox";
function normalizeBrands(brands: BrandArray): BrandArray {
    const knownBrands = new Map<string, KnownBrand>([
        ["Chrome", "Chrome"],
        ["Chromium", "Chrome"],
        ["Firefox", "Firefox"],
        ["Microsoft Edge", "Chrome"],
        ["Safari", "Safari"],
    ]);

    const normalizedBrands = brands
        .filter(({ brand }) => knownBrands.has(brand))
        .map((brand) => {
            return {
                // Safety: we've filtered out brands which do not appear as keys in `knownBrands`, so this cannot return
                // undefined.
                brand: knownBrands.get(brand.brand)!,
                version: brand.version,
            };
        });

    // TODO: default "unknown" value should be added on COF server side. For now we'll use Firefox.
    if (normalizedBrands.length === 0) return [{ brand: "Firefox", version: "0" }];
    return normalizedBrands;
}

/* eslint-disable max-len */
/**
 * We must ensure the data we get from `navigator.userAgentData` is normalized to match what our backend expects to
 * see in our custom CameraKitWeb userAgent string.
 *
 * This string is defined here:
 * https://github.sc-corp.net/Snapchat/useragent/blob/9333afe7cc6ac00503ad46cb234bcf94006dff98/java/useragent/src/main/java/snapchat/client/UserAgent.java#L124
 */
/* eslint-enable */
function normalizeUserAgentData(userAgentData: NavigatorUAData): NavigatorUAData {
    return {
        brands: normalizeBrands(userAgentData.brands),
        mobile: userAgentData.mobile,
        platform: parsePlaftformName(userAgentData.platform),
    };
}

/* eslint-disable max-len */
/**
 * The backend defines the allowed list of known platforms which will pass their RegEx test when found in our custom
 * CameraKitWeb userAgent string.
 *
 * See https://github.sc-corp.net/Snapchat/useragent/blob/9333afe7cc6ac00503ad46cb234bcf94006dff98/java/useragent/src/main/java/snapchat/client/UserAgent.java#L124
 */
/* eslint-enable */
type KnownPlatform = "macos" | "windows" | "linux" | "android" | "ios" | "ipados" | "unknown";
function parsePlaftformName(userAgent: string): KnownPlatform {
    const knownPlatforms = new Map<string, KnownPlatform>([
        ["android", "android"],
        ["linux", "linux"],
        ["iphone os", "ios"],
        ["ipad", "ipados"],
        ["mac os", "macos"],
        ["macos", "macos"],
        ["windows", "windows"],
    ]);

    const normalizedUserAgent = userAgent.toLowerCase();
    for (const [match, platform] of knownPlatforms.entries()) {
        if (normalizedUserAgent.includes(match)) return platform;
    }
    return "unknown";
}

/**
 * We'll use the application's origin as an identifier – this isn't used for any kind of authentication, but it may be
 * useful metadata to have in the future.
 *
 * We also need to handle cases in which the SDK is used in a child browsing context (e.g. an iframe), which may not
 * have a hostname – in this case we'll check each ancestor context until we find a valid hostname.
 */
function parseApplicationOrigin(): string {
    let origin = location.hostname;
    // Firefox does not implement ancestorOrigins, so we need a fallback.
    // Context here: https://github.com/whatwg/html/issues/1918
    const ancestorOrigins =
        location.ancestorOrigins === undefined
            ? typeof window !== "undefined"
                ? [window.parent.origin, window.top?.origin ?? ""]
                : []
            : Array.from(location.ancestorOrigins ?? []);

    while (origin === "" && ancestorOrigins.length > 0) {
        // Safety: ancestorOrigins must contain at least one element, so shift() will always be defined.
        origin = new URL(ancestorOrigins.shift()!).hostname;
    }
    return origin;
}

function getCameraKitUserAgent(): CameraKitUserAgent {
    const userAgent = navigator.userAgent ?? "";
    // [NavigatorUAData](https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData) is currently only
    // available on Chromium-based browsers – it's nice because it gives us clear, well-documented information. But
    // we'll have to fallback to parsing the userAgent string when it's not available.
    const userAgentData = isNavigatorUAData(navigator.userAgentData)
        ? normalizeUserAgentData(navigator.userAgentData)
        : parseUserAgentData(userAgent);

    const platformVersion = parsePlatformVersion(userAgent);
    const deviceModel = parseDeviceModel(userAgent);

    // In cases where we've parsed the userAgent string to find the brand, there will only ever be a single brand –
    // in browsers which support NavigatorUAData there could be more than one (e.g. Chrome and Chromium), but they
    // should be equivalent for our purposes.
    const browser = userAgentData.brands[0];
    const origin = parseApplicationOrigin();

    const sdkLongVersion = environment.PACKAGE_VERSION;
    // Remove any `-prerelease` or `+buildmetadata` portions from the semver string.
    const sdkShortVersion = sdkLongVersion.replace(/[-+]\S+$/, "");

    // Set this to `debug` manually while testing / root-causing.
    const flavor: "release" | "debug" = "release";

    // This full string is defined here:
    // eslint-disable-next-line max-len
    // https://github.sc-corp.net/Snapchat/useragent/blob/9333afe7cc6ac00503ad46cb234bcf94006dff98/java/useragent/src/main/java/snapchat/client/UserAgent.java#L124
    const cameraKitUserAgent =
        `CameraKitWeb/${sdkShortVersion} ` +
        `${flavor === "release" ? "" : "DEBUG"}` +
        `(${deviceModel}; ${userAgentData.platform} ${platformVersion}) ` +
        `${browser.brand}/${browser.version} ` +
        `Core/${lensCoreWasm.version} ` +
        // We overload appId, using the origin instead of the true appId parsed from the apiToken -- we do this because
        // origin is human-readable, and this is used to populate the appId dimension in operational metrics.
        `AppId/${origin}`;

    return {
        osType: userAgentData.platform,
        osVersion: platformVersion,
        locale,
        sdkShortVersion,
        sdkLongVersion,
        flavor,
        lensCoreVersion: `${lensCoreWasm.version}`,
        deviceModel,
        browser,
        origin,
        userAgent: cameraKitUserAgent,
        connectionType: navigator.connection?.type,
    };
}

/** @internal */
export interface CameraKitUserAgent {
    osType: string;
    osVersion: string;
    locale: string;
    sdkShortVersion: string;
    sdkLongVersion: string;
    flavor: "release" | "debug";
    lensCoreVersion: string;
    deviceModel: string;
    browser: { brand: string; version: string };
    origin: string;
    userAgent: string;
    connectionType: ConnectionType | undefined;
}

/** @internal */
export const cameraKitUserAgent = getCameraKitUserAgent();
