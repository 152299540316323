import Long from "long";
import _m0 from "protobufjs/minimal";
import { Any } from "../../google/protobuf/any";
export const protobufPackage = "com.snap.camerakit.v3";
export interface Lens {
    id: string;
    name: string;
    vendorData: {
        [key: string]: string;
    };
    content: Content | undefined;
    isThirdParty: boolean;
    cameraFacingPreference: Lens_CameraFacing;
    featureMetadata: Any[];
    lensCreator: LensCreator | undefined;
    scannable: Scannable | undefined;
}
export enum Lens_CameraFacing {
    CAMERA_FACING_UNSET = "CAMERA_FACING_UNSET",
    CAMERA_FACING_FRONT = "CAMERA_FACING_FRONT",
    CAMERA_FACING_BACK = "CAMERA_FACING_BACK",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function lens_CameraFacingFromJSON(object: any): Lens_CameraFacing {
    switch (object) {
        case 0:
        case "CAMERA_FACING_UNSET":
            return Lens_CameraFacing.CAMERA_FACING_UNSET;
        case 1:
        case "CAMERA_FACING_FRONT":
            return Lens_CameraFacing.CAMERA_FACING_FRONT;
        case 2:
        case "CAMERA_FACING_BACK":
            return Lens_CameraFacing.CAMERA_FACING_BACK;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Lens_CameraFacing.UNRECOGNIZED;
    }
}
export function lens_CameraFacingToJSON(object: Lens_CameraFacing): string {
    switch (object) {
        case Lens_CameraFacing.CAMERA_FACING_UNSET:
            return "CAMERA_FACING_UNSET";
        case Lens_CameraFacing.CAMERA_FACING_FRONT:
            return "CAMERA_FACING_FRONT";
        case Lens_CameraFacing.CAMERA_FACING_BACK:
            return "CAMERA_FACING_BACK";
        default:
            return "UNKNOWN";
    }
}
export function lens_CameraFacingToNumber(object: Lens_CameraFacing): number {
    switch (object) {
        case Lens_CameraFacing.CAMERA_FACING_UNSET:
            return 0;
        case Lens_CameraFacing.CAMERA_FACING_FRONT:
            return 1;
        case Lens_CameraFacing.CAMERA_FACING_BACK:
            return 2;
        default:
            return 0;
    }
}
export interface Lens_VendorDataEntry {
    key: string;
    value: string;
}
export interface Content {
    lnsUrl: string;
    lnsSha256: string;
    iconUrl: string;
    preview: Preview | undefined;
    assetManifest: LensAssetManifestItem[];
    defaultHintId: string;
    hintTranslations: {
        [key: string]: string;
    };
    lnsUrlBolt: string;
    iconUrlBolt: string;
}
export interface Content_HintTranslationsEntry {
    key: string;
    value: string;
}
export interface LensAssetManifestItem {
    type: LensAssetManifestItem_Type;
    id: string;
    requestTiming: LensAssetManifestItem_RequestTiming;
    assetUrl: string;
    assetChecksum: string;
}
export enum LensAssetManifestItem_Type {
    DEVICE_DEPENDENT_ASSET_UNSET = "DEVICE_DEPENDENT_ASSET_UNSET",
    ASSET = "ASSET",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function lensAssetManifestItem_TypeFromJSON(object: any): LensAssetManifestItem_Type {
    switch (object) {
        case 0:
        case "DEVICE_DEPENDENT_ASSET_UNSET":
            return LensAssetManifestItem_Type.DEVICE_DEPENDENT_ASSET_UNSET;
        case 1:
        case "ASSET":
            return LensAssetManifestItem_Type.ASSET;
        case -1:
        case "UNRECOGNIZED":
        default:
            return LensAssetManifestItem_Type.UNRECOGNIZED;
    }
}
export function lensAssetManifestItem_TypeToJSON(object: LensAssetManifestItem_Type): string {
    switch (object) {
        case LensAssetManifestItem_Type.DEVICE_DEPENDENT_ASSET_UNSET:
            return "DEVICE_DEPENDENT_ASSET_UNSET";
        case LensAssetManifestItem_Type.ASSET:
            return "ASSET";
        default:
            return "UNKNOWN";
    }
}
export function lensAssetManifestItem_TypeToNumber(object: LensAssetManifestItem_Type): number {
    switch (object) {
        case LensAssetManifestItem_Type.DEVICE_DEPENDENT_ASSET_UNSET:
            return 0;
        case LensAssetManifestItem_Type.ASSET:
            return 1;
        default:
            return 0;
    }
}
export enum LensAssetManifestItem_RequestTiming {
    PRELOAD_UNSET = "PRELOAD_UNSET",
    ON_DEMAND = "ON_DEMAND",
    REQUIRED = "REQUIRED",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function lensAssetManifestItem_RequestTimingFromJSON(object: any): LensAssetManifestItem_RequestTiming {
    switch (object) {
        case 0:
        case "PRELOAD_UNSET":
            return LensAssetManifestItem_RequestTiming.PRELOAD_UNSET;
        case 1:
        case "ON_DEMAND":
            return LensAssetManifestItem_RequestTiming.ON_DEMAND;
        case 2:
        case "REQUIRED":
            return LensAssetManifestItem_RequestTiming.REQUIRED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return LensAssetManifestItem_RequestTiming.UNRECOGNIZED;
    }
}
export function lensAssetManifestItem_RequestTimingToJSON(object: LensAssetManifestItem_RequestTiming): string {
    switch (object) {
        case LensAssetManifestItem_RequestTiming.PRELOAD_UNSET:
            return "PRELOAD_UNSET";
        case LensAssetManifestItem_RequestTiming.ON_DEMAND:
            return "ON_DEMAND";
        case LensAssetManifestItem_RequestTiming.REQUIRED:
            return "REQUIRED";
        default:
            return "UNKNOWN";
    }
}
export function lensAssetManifestItem_RequestTimingToNumber(object: LensAssetManifestItem_RequestTiming): number {
    switch (object) {
        case LensAssetManifestItem_RequestTiming.PRELOAD_UNSET:
            return 0;
        case LensAssetManifestItem_RequestTiming.ON_DEMAND:
            return 1;
        case LensAssetManifestItem_RequestTiming.REQUIRED:
            return 2;
        default:
            return 0;
    }
}
export interface Preview {
    imageUrl: string;
    imageSequenceSize: number;
    imageSequenceWebpUrlPattern: string;
}
export interface LensCreator {
    displayName: string;
}
export interface Scannable {
    snapcodeImageUrl: string;
    snapcodeDeeplink: string;
}
function createBaseLens(): Lens {
    return {
        id: "",
        name: "",
        vendorData: {},
        content: undefined,
        isThirdParty: false,
        cameraFacingPreference: Lens_CameraFacing.CAMERA_FACING_UNSET,
        featureMetadata: [],
        lensCreator: undefined,
        scannable: undefined,
    };
}
export const Lens = {
    decode(input: _m0.Reader | Uint8Array, length?: number): Lens {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLens();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    const entry3 = Lens_VendorDataEntry.decode(reader, reader.uint32());
                    if (entry3.value !== undefined) {
                        message.vendorData[entry3.key] = entry3.value;
                    }
                    break;
                case 4:
                    message.content = Content.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.isThirdParty = reader.bool();
                    break;
                case 6:
                    message.cameraFacingPreference = lens_CameraFacingFromJSON(reader.int32());
                    break;
                case 7:
                    message.featureMetadata.push(Any.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.lensCreator = LensCreator.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.scannable = Scannable.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): Lens {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            name: isSet(object.name) ? String(object.name) : "",
            vendorData: isObject(object.vendorData)
                ? Object.entries(object.vendorData).reduce<{
                    [key: string]: string;
                }>((acc, [key, value]) => {
                    acc[key] = String(value);
                    return acc;
                }, {})
                : {},
            content: isSet(object.content) ? Content.fromJSON(object.content) : undefined,
            isThirdParty: isSet(object.isThirdParty) ? Boolean(object.isThirdParty) : false,
            cameraFacingPreference: isSet(object.cameraFacingPreference)
                ? lens_CameraFacingFromJSON(object.cameraFacingPreference)
                : Lens_CameraFacing.CAMERA_FACING_UNSET,
            featureMetadata: Array.isArray(object?.featureMetadata)
                ? object.featureMetadata.map((e: any) => Any.fromJSON(e))
                : [],
            lensCreator: isSet(object.lensCreator) ? LensCreator.fromJSON(object.lensCreator) : undefined,
            scannable: isSet(object.scannable) ? Scannable.fromJSON(object.scannable) : undefined,
        };
    },
    toJSON(message: Lens): unknown {
        const obj: any = {};
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        obj.vendorData = {};
        if (message.vendorData) {
            Object.entries(message.vendorData).forEach(([k, v]) => {
                obj.vendorData[k] = v;
            });
        }
        message.content !== undefined && (obj.content = message.content ? Content.toJSON(message.content) : undefined);
        message.isThirdParty !== undefined && (obj.isThirdParty = message.isThirdParty);
        message.cameraFacingPreference !== undefined &&
            (obj.cameraFacingPreference = lens_CameraFacingToJSON(message.cameraFacingPreference));
        if (message.featureMetadata) {
            obj.featureMetadata = message.featureMetadata.map((e) => (e ? Any.toJSON(e) : undefined));
        }
        else {
            obj.featureMetadata = [];
        }
        message.lensCreator !== undefined &&
            (obj.lensCreator = message.lensCreator ? LensCreator.toJSON(message.lensCreator) : undefined);
        message.scannable !== undefined &&
            (obj.scannable = message.scannable ? Scannable.toJSON(message.scannable) : undefined);
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<Lens>, I>>(object: I): Lens {
        const message = createBaseLens();
        message.id = object.id ?? "";
        message.name = object.name ?? "";
        message.vendorData = Object.entries(object.vendorData ?? {}).reduce<{
            [key: string]: string;
        }>((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = String(value);
            }
            return acc;
        }, {});
        message.content =
            object.content !== undefined && object.content !== null ? Content.fromPartial(object.content) : undefined;
        message.isThirdParty = object.isThirdParty ?? false;
        message.cameraFacingPreference = object.cameraFacingPreference ?? Lens_CameraFacing.CAMERA_FACING_UNSET;
        message.featureMetadata = object.featureMetadata?.map((e) => Any.fromPartial(e)) || [];
        message.lensCreator =
            object.lensCreator !== undefined && object.lensCreator !== null
                ? LensCreator.fromPartial(object.lensCreator)
                : undefined;
        message.scannable =
            object.scannable !== undefined && object.scannable !== null ? Scannable.fromPartial(object.scannable) : undefined;
        return message;
    },
};
function createBaseLens_VendorDataEntry(): Lens_VendorDataEntry {
    return { key: "", value: "" };
}
export const Lens_VendorDataEntry = {
    decode(input: _m0.Reader | Uint8Array, length?: number): Lens_VendorDataEntry {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLens_VendorDataEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): Lens_VendorDataEntry {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value) ? String(object.value) : "",
        };
    },
    toJSON(message: Lens_VendorDataEntry): unknown {
        const obj: any = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<Lens_VendorDataEntry>, I>>(object: I): Lens_VendorDataEntry {
        const message = createBaseLens_VendorDataEntry();
        message.key = object.key ?? "";
        message.value = object.value ?? "";
        return message;
    },
};
function createBaseContent(): Content {
    return {
        lnsUrl: "",
        lnsSha256: "",
        iconUrl: "",
        preview: undefined,
        assetManifest: [],
        defaultHintId: "",
        hintTranslations: {},
        lnsUrlBolt: "",
        iconUrlBolt: "",
    };
}
export const Content = {
    decode(input: _m0.Reader | Uint8Array, length?: number): Content {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseContent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.lnsUrl = reader.string();
                    break;
                case 2:
                    message.lnsSha256 = reader.string();
                    break;
                case 3:
                    message.iconUrl = reader.string();
                    break;
                case 4:
                    message.preview = Preview.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.assetManifest.push(LensAssetManifestItem.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.defaultHintId = reader.string();
                    break;
                case 7:
                    const entry7 = Content_HintTranslationsEntry.decode(reader, reader.uint32());
                    if (entry7.value !== undefined) {
                        message.hintTranslations[entry7.key] = entry7.value;
                    }
                    break;
                case 8:
                    message.lnsUrlBolt = reader.string();
                    break;
                case 9:
                    message.iconUrlBolt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): Content {
        return {
            lnsUrl: isSet(object.lnsUrl) ? String(object.lnsUrl) : "",
            lnsSha256: isSet(object.lnsSha256) ? String(object.lnsSha256) : "",
            iconUrl: isSet(object.iconUrl) ? String(object.iconUrl) : "",
            preview: isSet(object.preview) ? Preview.fromJSON(object.preview) : undefined,
            assetManifest: Array.isArray(object?.assetManifest)
                ? object.assetManifest.map((e: any) => LensAssetManifestItem.fromJSON(e))
                : [],
            defaultHintId: isSet(object.defaultHintId) ? String(object.defaultHintId) : "",
            hintTranslations: isObject(object.hintTranslations)
                ? Object.entries(object.hintTranslations).reduce<{
                    [key: string]: string;
                }>((acc, [key, value]) => {
                    acc[key] = String(value);
                    return acc;
                }, {})
                : {},
            lnsUrlBolt: isSet(object.lnsUrlBolt) ? String(object.lnsUrlBolt) : "",
            iconUrlBolt: isSet(object.iconUrlBolt) ? String(object.iconUrlBolt) : "",
        };
    },
    toJSON(message: Content): unknown {
        const obj: any = {};
        message.lnsUrl !== undefined && (obj.lnsUrl = message.lnsUrl);
        message.lnsSha256 !== undefined && (obj.lnsSha256 = message.lnsSha256);
        message.iconUrl !== undefined && (obj.iconUrl = message.iconUrl);
        message.preview !== undefined && (obj.preview = message.preview ? Preview.toJSON(message.preview) : undefined);
        if (message.assetManifest) {
            obj.assetManifest = message.assetManifest.map((e) => (e ? LensAssetManifestItem.toJSON(e) : undefined));
        }
        else {
            obj.assetManifest = [];
        }
        message.defaultHintId !== undefined && (obj.defaultHintId = message.defaultHintId);
        obj.hintTranslations = {};
        if (message.hintTranslations) {
            Object.entries(message.hintTranslations).forEach(([k, v]) => {
                obj.hintTranslations[k] = v;
            });
        }
        message.lnsUrlBolt !== undefined && (obj.lnsUrlBolt = message.lnsUrlBolt);
        message.iconUrlBolt !== undefined && (obj.iconUrlBolt = message.iconUrlBolt);
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<Content>, I>>(object: I): Content {
        const message = createBaseContent();
        message.lnsUrl = object.lnsUrl ?? "";
        message.lnsSha256 = object.lnsSha256 ?? "";
        message.iconUrl = object.iconUrl ?? "";
        message.preview =
            object.preview !== undefined && object.preview !== null ? Preview.fromPartial(object.preview) : undefined;
        message.assetManifest = object.assetManifest?.map((e) => LensAssetManifestItem.fromPartial(e)) || [];
        message.defaultHintId = object.defaultHintId ?? "";
        message.hintTranslations = Object.entries(object.hintTranslations ?? {}).reduce<{
            [key: string]: string;
        }>((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = String(value);
            }
            return acc;
        }, {});
        message.lnsUrlBolt = object.lnsUrlBolt ?? "";
        message.iconUrlBolt = object.iconUrlBolt ?? "";
        return message;
    },
};
function createBaseContent_HintTranslationsEntry(): Content_HintTranslationsEntry {
    return { key: "", value: "" };
}
export const Content_HintTranslationsEntry = {
    decode(input: _m0.Reader | Uint8Array, length?: number): Content_HintTranslationsEntry {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseContent_HintTranslationsEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): Content_HintTranslationsEntry {
        return {
            key: isSet(object.key) ? String(object.key) : "",
            value: isSet(object.value) ? String(object.value) : "",
        };
    },
    toJSON(message: Content_HintTranslationsEntry): unknown {
        const obj: any = {};
        message.key !== undefined && (obj.key = message.key);
        message.value !== undefined && (obj.value = message.value);
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<Content_HintTranslationsEntry>, I>>(object: I): Content_HintTranslationsEntry {
        const message = createBaseContent_HintTranslationsEntry();
        message.key = object.key ?? "";
        message.value = object.value ?? "";
        return message;
    },
};
function createBaseLensAssetManifestItem(): LensAssetManifestItem {
    return {
        type: LensAssetManifestItem_Type.DEVICE_DEPENDENT_ASSET_UNSET,
        id: "",
        requestTiming: LensAssetManifestItem_RequestTiming.PRELOAD_UNSET,
        assetUrl: "",
        assetChecksum: "",
    };
}
export const LensAssetManifestItem = {
    decode(input: _m0.Reader | Uint8Array, length?: number): LensAssetManifestItem {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLensAssetManifestItem();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = lensAssetManifestItem_TypeFromJSON(reader.int32());
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    message.requestTiming = lensAssetManifestItem_RequestTimingFromJSON(reader.int32());
                    break;
                case 4:
                    message.assetUrl = reader.string();
                    break;
                case 5:
                    message.assetChecksum = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): LensAssetManifestItem {
        return {
            type: isSet(object.type)
                ? lensAssetManifestItem_TypeFromJSON(object.type)
                : LensAssetManifestItem_Type.DEVICE_DEPENDENT_ASSET_UNSET,
            id: isSet(object.id) ? String(object.id) : "",
            requestTiming: isSet(object.requestTiming)
                ? lensAssetManifestItem_RequestTimingFromJSON(object.requestTiming)
                : LensAssetManifestItem_RequestTiming.PRELOAD_UNSET,
            assetUrl: isSet(object.assetUrl) ? String(object.assetUrl) : "",
            assetChecksum: isSet(object.assetChecksum) ? String(object.assetChecksum) : "",
        };
    },
    toJSON(message: LensAssetManifestItem): unknown {
        const obj: any = {};
        message.type !== undefined && (obj.type = lensAssetManifestItem_TypeToJSON(message.type));
        message.id !== undefined && (obj.id = message.id);
        message.requestTiming !== undefined &&
            (obj.requestTiming = lensAssetManifestItem_RequestTimingToJSON(message.requestTiming));
        message.assetUrl !== undefined && (obj.assetUrl = message.assetUrl);
        message.assetChecksum !== undefined && (obj.assetChecksum = message.assetChecksum);
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<LensAssetManifestItem>, I>>(object: I): LensAssetManifestItem {
        const message = createBaseLensAssetManifestItem();
        message.type = object.type ?? LensAssetManifestItem_Type.DEVICE_DEPENDENT_ASSET_UNSET;
        message.id = object.id ?? "";
        message.requestTiming = object.requestTiming ?? LensAssetManifestItem_RequestTiming.PRELOAD_UNSET;
        message.assetUrl = object.assetUrl ?? "";
        message.assetChecksum = object.assetChecksum ?? "";
        return message;
    },
};
function createBasePreview(): Preview {
    return { imageUrl: "", imageSequenceSize: 0, imageSequenceWebpUrlPattern: "" };
}
export const Preview = {
    decode(input: _m0.Reader | Uint8Array, length?: number): Preview {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePreview();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.imageUrl = reader.string();
                    break;
                case 2:
                    message.imageSequenceSize = reader.int32();
                    break;
                case 3:
                    message.imageSequenceWebpUrlPattern = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): Preview {
        return {
            imageUrl: isSet(object.imageUrl) ? String(object.imageUrl) : "",
            imageSequenceSize: isSet(object.imageSequenceSize) ? Number(object.imageSequenceSize) : 0,
            imageSequenceWebpUrlPattern: isSet(object.imageSequenceWebpUrlPattern)
                ? String(object.imageSequenceWebpUrlPattern)
                : "",
        };
    },
    toJSON(message: Preview): unknown {
        const obj: any = {};
        message.imageUrl !== undefined && (obj.imageUrl = message.imageUrl);
        message.imageSequenceSize !== undefined && (obj.imageSequenceSize = Math.round(message.imageSequenceSize));
        message.imageSequenceWebpUrlPattern !== undefined &&
            (obj.imageSequenceWebpUrlPattern = message.imageSequenceWebpUrlPattern);
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<Preview>, I>>(object: I): Preview {
        const message = createBasePreview();
        message.imageUrl = object.imageUrl ?? "";
        message.imageSequenceSize = object.imageSequenceSize ?? 0;
        message.imageSequenceWebpUrlPattern = object.imageSequenceWebpUrlPattern ?? "";
        return message;
    },
};
function createBaseLensCreator(): LensCreator {
    return { displayName: "" };
}
export const LensCreator = {
    decode(input: _m0.Reader | Uint8Array, length?: number): LensCreator {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLensCreator();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.displayName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): LensCreator {
        return {
            displayName: isSet(object.displayName) ? String(object.displayName) : "",
        };
    },
    toJSON(message: LensCreator): unknown {
        const obj: any = {};
        message.displayName !== undefined && (obj.displayName = message.displayName);
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<LensCreator>, I>>(object: I): LensCreator {
        const message = createBaseLensCreator();
        message.displayName = object.displayName ?? "";
        return message;
    },
};
function createBaseScannable(): Scannable {
    return { snapcodeImageUrl: "", snapcodeDeeplink: "" };
}
export const Scannable = {
    decode(input: _m0.Reader | Uint8Array, length?: number): Scannable {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseScannable();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.snapcodeImageUrl = reader.string();
                    break;
                case 2:
                    message.snapcodeDeeplink = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object: any): Scannable {
        return {
            snapcodeImageUrl: isSet(object.snapcodeImageUrl) ? String(object.snapcodeImageUrl) : "",
            snapcodeDeeplink: isSet(object.snapcodeDeeplink) ? String(object.snapcodeDeeplink) : "",
        };
    },
    toJSON(message: Scannable): unknown {
        const obj: any = {};
        message.snapcodeImageUrl !== undefined && (obj.snapcodeImageUrl = message.snapcodeImageUrl);
        message.snapcodeDeeplink !== undefined && (obj.snapcodeDeeplink = message.snapcodeDeeplink);
        return obj;
    },
    fromPartial<I extends Exact<DeepPartial<Scannable>, I>>(object: I): Scannable {
        const message = createBaseScannable();
        message.snapcodeImageUrl = object.snapcodeImageUrl ?? "";
        message.snapcodeDeeplink = object.snapcodeDeeplink ?? "";
        return message;
    },
};
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
function isObject(value: any): boolean {
    return typeof value === "object" && value !== null;
}
function isSet(value: any): boolean {
    return value !== null && value !== undefined;
}
