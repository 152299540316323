import { Injectable } from "../dependency-injection/Injectable";
import { createDebugHandler } from "./debugHandler";
import { HandlerChainBuilder } from "./HandlerChainBuilder";
import { createNoCorsRetryingFetchHandler } from "./noCorsRetryingFetchHandler";
import { createRetryingHandler } from "./retryingHandler";
import { createTimeoutHandler } from "./timeoutHandler";

export type FetchHandler<R = Response> = (input: RequestInfo, init?: RequestInit) => Promise<R>;

/**
 * The default Fetch implementation, used to make a simple HTTP requests without any custom headers. This can be passed
 * to `HandlerChainBuilder` to form the basis for other Fetch implementations (e.g. with custom headers, which extract
 * the Response body, etc.)
 *
 * Has support for retries, client-side timeout, and navigating federated auth flows that may not support CORs requests.
 *
 * @internal
 */

export const defaultFetchHandlerFactory = Injectable("defaultFetchHandler", () => {
    return (
        // Safety: We're re-typing fetch's second argument from `init?: RequestInit | undefined` to
        // `init: RequestInit | void` – this is semantically equivalent, but the void makes for nicer ergonomics
        // elsewhere (e.g. so that callers can omit the second argument instead of being forced to pass undefined).
        new HandlerChainBuilder<RequestInfo, Response, RequestInit | undefined>(fetch)
            .map(createDebugHandler())
            // The 20-second per-request timeout is pretty arbitrary, it's just set to be longer than our API gateway
            // timeout (15s) and lower than the browsers own timeout (variable, Chrome's is 5m).
            .map(createTimeoutHandler({ timeout: 20 * 1000 }))
            .map(createNoCorsRetryingFetchHandler())
            .map(
                createRetryingHandler({
                    maxRetries: 3,
                    retryPredicate: (responseOrError) => {
                        // Don't retry successful Responses or Responses with a 4xx HTTP status code (indicating a
                        // client error). Do retry all 5xx HTTP status codes.
                        if (responseOrError instanceof Response) {
                            if (responseOrError.ok) return false;
                            if (responseOrError.status % 400 < 100) return false;
                        }
                        return true;
                    },
                })
            ).handler
    );
});
