import { cameraKitUserAgent } from "../common/cameraKitUserAgent";
import { fullLocale } from "../common/locale";
import { ExtensionRequestContext } from "../generated-proto/pb_schema/camera_kit/v3/export";

function getExtensionRequestContext(): ArrayBuffer {
    return ExtensionRequestContext.encode({
        userAgent: cameraKitUserAgent.userAgent,
        locale: fullLocale,
    }).finish();
}

/**
 * Extension request context.
 */
export const extensionRequestContext = getExtensionRequestContext();
