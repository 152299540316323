import { LaunchData } from "../generated-proto/pb_schema/lenses/launchdata";
import { isNumber, isRecord, isString } from "../common/typeguards";

const isNotValid = (value: unknown) => !(isString(value) || isValidNumber(value));

const isValidNumber = (value: unknown): value is number =>
    isNumber(value) && !Number.isNaN(value) && Number.isFinite(value);

/**
 * Various lenses may support the passing of certain parameters from the application to the lens when it is launched.
 *
 * @category Lenses
 */
export type LensLaunchParams = Record<string, string | number | string[] | number[]>;

export type LaunchDetails = {
    launchParams?: LensLaunchParams;
    persistentStore?: ArrayBuffer;
};

/**
 * @internal
 */
export function isLaunchParamsValid(launchParams: unknown): launchParams is LensLaunchParams {
    if (!isRecord(launchParams) || launchParams instanceof Date) {
        throw new Error("Expected an object.");
    }

    for (const [key, value] of Object.entries(launchParams)) {
        if (Array.isArray(value)) {
            if (!value.every(isString) && !value.every(isValidNumber)) {
                throw new Error(
                    `Field ${key} expects a value of type string, number, string array, or number array. ` +
                        `Received: ${JSON.stringify(value)}.`
                );
            }
        } else if (isNotValid(value)) {
            throw new Error(
                `Field ${key} expects a value of type string, number, string array, or number array. ` +
                    `Received: ${JSON.stringify(value)}.`
            );
        }
    }

    return true;
}

/**
 * @param launchDetails
 * @internal
 */
export const createLaunchData = ({ launchParams, persistentStore }: LaunchDetails) =>
    // finish() protobufjs method returns UInt8Array with shared ArrayBuffer
    // to avoid of detached buffer error when passing data to Lens Core
    // data should be copied using slice() method
    LaunchData.encode(
        LaunchData.fromPartial({
            ...(launchParams &&
                isLaunchParamsValid(launchParams) && {
                    launchParams: { data: new TextEncoder().encode(JSON.stringify(launchParams)) },
                }),
            ...(persistentStore && { persistentStore: { store: new Uint8Array(persistentStore) } }),
        })
    )
        .finish()
        .slice();
