import { RankingInfo } from "../generated-proto/pb_schema/camera_kit/v3/features/ranking_info";
import { RemoteApiInfo } from "../generated-proto/pb_schema/camera_kit/v3/features/remote_api_info";
import { Any } from "../generated-proto/pb_schema/google/protobuf/any";
import { StringValue } from "../generated-proto/pb_schema/google/protobuf/wrappers";

// There is a discrepancy in how the CameraKit backend and ts-proto serialize a protobuf message into JSON.
// The backend serialization follows the spec outlined here:
// https://protobuf.dev/reference/protobuf/google.protobuf/#json
// According to this specification, the actual message is represented as JSON
// with an additional @type discriminator field.
// However, this approach is not consistent with what the client-side expects for Any.
// It requires it to be in the format { typeUrl: string, value: UInt8Array }.
// Therefore, we need to map the JSON representation of Any to the actual Any message.
// This issue only applies to JSON-serialized protos returned by our backend.
// The JSON serialization of the ts-proto package that we use
// does not appear to be following the spec regarding Any.
// Even if it does, the deserialization part has to be handled manually.
// This issue does not apply to cases where the Lens object is deserialized from a binary proto message.
// Ideally, to fix this issue, we want to switch to gRPC web for our backend communication,
// similar to how we do it for COF. Ticket: https://jira.sc-corp.net/browse/CAMKIT-4668

export const knownAnyTypes = {
    rankingInfo: "type.googleapis.com/com.snap.camerakit.v3.features.RankingInfo",
    remoteApiInfo: "type.googleapis.com/com.snap.camerakit.v3.features.RemoteApiInfo",
    string: "type.googleapis.com/google.protobuf.StringValue",
};

/**
 * JSON-serialized any message according to spec: https://protobuf.dev/reference/protobuf/google.protobuf/#json
 */
export interface AnyJson extends Record<string, unknown> {
    "@type": string;
}

/**
 * Gets JSON-serialized any message and maps it to JS representation of Any type.
 * @param jsonAny JSON-serialized any message according to spec:
 * https://protobuf.dev/reference/protobuf/google.protobuf/#json
 * @returns JS representation of Any proto message.
 */
export function encodeKnownAnyJson(jsonAny: AnyJson): Any | void {
    const typeUrl = jsonAny["@type"];
    switch (typeUrl) {
        case knownAnyTypes.remoteApiInfo:
            return {
                typeUrl,
                // Safety: we know that spec ensures all message fields to exists
                value: RemoteApiInfo.encode(jsonAny as unknown as RemoteApiInfo).finish(),
            };
        case knownAnyTypes.rankingInfo:
            return {
                typeUrl,
                value: RankingInfo.encode(jsonAny as unknown as RankingInfo).finish(),
            };
        case knownAnyTypes.string:
            return {
                typeUrl,
                value: StringValue.encode(jsonAny as unknown as StringValue).finish(),
            };
        default:
            break;
    }
}
